<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/6188069bca27ab26ca8676b89f8c8f4b.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <p>
          <strong>积分来源</strong
          >：包括签到送积分、下单赠送积分、邀请好友赠送积分、系统后台赠送积分
        </p>
        <h3 id="h3--strong-1-strong-">
          <a name="1. 签到送积分" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>1. 签到送积分</strong>
        </h3>
        <p>
          设置位置：<strong>平台后台&gt;营销&gt;积分&gt;签到配置 </strong><br />
        </p>
        <p>
          <strong>签到规则：</strong
          ><br />第一次签到获得的是第一天签到相应积分，连续签到2天、3天、4天将获得第二天、第三天、第四天相应积分。<br />连续签到中断后再次签到，获得的是第一天签到积分。连续签到全部完成，签到积分重新从第一天签到积分算起。
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/20230915104129.png"
            alt=""
          />
        </p>
        <p>
          展示位置：移动端&gt;个人中心&gt;积分中心<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201805824.png"
            alt=""
            class="w-1/3"
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201805615.png"
            alt=""
            class="w-1/3"
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309201806090.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-2-strong-">
          <a name="2. 下单送积分" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>2. 下单送积分</strong>
        </h3>
        <p>
          设置位置：平台后台&gt;营销&gt;积分&gt;积分配置<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915104128_1.png"
            alt=""
          /><br />
          展示位置：移动端&gt;积分中心&gt;分值提升、订单支付成功后展示<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201806955.png"
            alt=""
            class="w-1/3"
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201807454.png"
            alt=""
            class="w-1/3"
          />
        </p>
        <h3 id="h3--strong-3-strong-">
          <a name="3. 邀请好友赠送积分" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>3. 邀请好友赠送积分</strong>
        </h3>
        <p>
          设置位置：平台后台&gt;营销&gt;积分&gt;积分配置<br />
          <img
            v-preview-image
            src="@/assets/downloads/120230915104127.png"
            alt=""
          /><br />
          展示位置：移动端&gt;积分中心&gt;分值提升<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309201807285.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-4-strong-">
          <a name="4. 系统后台赠送积分" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>4. 系统后台赠送积分</strong>
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/20230915104126.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915104126_1.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
