<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <p><strong>多商户脑图</strong></p>
      <p>
        <el-image
          :src="require('@/assets/downloads/202309261503451.png')"
          fit="fill"
          :lazy="true"
          :preview-src-list="srcList"
        ></el-image>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcList: ["@/assets/downloads/202309261503451.png"],
    };
  },
};
</script>

<style lang="scss" scoped></style>
