<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-252015-27-10-375.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--">
        <a name="一、功能介绍" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h3>
      <p>
        1.付费会员是指用户通过付费购买到的商城会员资格，付费会员权益都一样，只是会员期限不一样付费金额不一样。<br />
        2.万千实训系统多商户系统付费会员功能核心：平台设置付费会员类型、权益，商户可选择是否开启付费会员并设置商<br />
        品的付费会员价，商户承担付费会员价的成本，平台承担付费会员优惠券的成本。<br />3.会员类型根据业务可设置，包括免费试用（后台可设置天数）、周卡、月卡、季卡、年卡、永久。

        <br />4.会员权益包括会员专属价、签到返利、消费返利、会员专属客服、经验翻倍、会员优惠券。
      </p>
      <h3 id="h3--">
        <a name="二、操作流程" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h3>
      <p><strong>第一步：平台开启付费会员</strong></p>
      <p>
        位置：平台后台&gt;用户&gt;付费会员&gt;付费会员配置<br />说明：<br />
        1.付费会员开启：个人中心有付费会员中心入口；关闭则无；<br />
        2.全部用户可见付费会员价：
        是指当商城开启付费会员功能，设置了付费会员价后，付费和非付费会员均可看到设置的付费会员价；<br />
        3.仅会员可见付费会员价：是指当商城开启付费会员功能，设置了付费会员价后，仅付费会员可看到设置的付费会员价；<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915144317.png"
          alt=""
        />
      </p>

      <p><strong>第二步：平台设置付费会员类型</strong></p>
      <p>位置：平台后台&gt;用户&gt;付费会员&gt;付费会员类型</p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915144317_1.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915144316.png"
          alt=""
        />
      </p>
      <p><strong> 第三步：平台设置付费会员权益</strong></p>
      <p>
        位置：平台后台&gt;用户&gt;付费会员&gt;付费会员权益<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915144316_1.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915143813.png"
          alt=""
        />
      </p>
      <p>
        移动端展示效果<br />未开通样式<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309201827369.png"
          alt=""
        />
      </p>
      <p>
        已开通样式<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309201827591.png"
          alt=""
        />
      </p>
      <p>
        <strong>第四步：平台后台添加付费会员协议</strong
        ><br />位置：平台后台&gt;用户&gt;付费会员&gt;付费会员协议<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915144313.png"
          alt=""
        />
      </p>
      <p>
        移动端展示效果<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309201828778.png"
          alt=""
        />
      </p>
      <p><strong>第五步：查询付费会员支付记录</strong></p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915144759.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
