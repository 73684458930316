<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/fc8d5e5c6ef15c5814c9050320001fda.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3-u64CDu4F5Cu6B65u9AA4">
          <a name="操作步骤" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>操作步骤
        </h3>
        <p><strong>第一步：平台开启虚拟拼团</strong></p>
        <p>
          <strong>位置：平台后台&gt;营销&gt;拼团&gt;拼团设置</strong><br />
          确认是否开启虚拟拼团，如果开启，可在平台后台先开启虚拟拼团，并设置相应虚拟成团比例，平台开启并设置后，该设置比例适用于所有子商户。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201650703.png"
            alt=""
          />
        </p>
        <p><strong> 第二步：商户具体设置</strong></p>
        <p>
          <strong> 位置：商户后台&gt;营销&gt;拼团&gt;添加或编辑拼团商品</strong>
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309201650843.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
