<template>
  <div>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          卡密商品：是商品的一种类型，与普通商品相比，下单时无需填写收货地址，且下单后系统自动发货。
          <!-- v2.2
          版本更新的是固定卡密商品，主要适用于经营源码、网盘、PPT
          模板等电子资料业务模式。 -->
        </p>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <p>
          添加固定商品<br />位置：商户后台&gt;商品&gt;添加商品<br />注：<br />（1）请提前在平台后台和商户后台添加好卡密商品对应的分类，平台后台添加好品牌。<br />（2）添加商品时选择卡密商品类型<br /><img
            v-preview-image
            src="@/assets/downloads/202309011554935.png"
            alt=""
          />
        </p>
        <p>
          <img src="@/assets/downloads/202309011554523.png" alt="" />
        </p>
        <p>
          <img src="@/assets/downloads/202309011554516.png" alt="" />
        </p>
        <p>
          <img src="@/assets/downloads/202309011554082.png" alt="" />
        </p>
        <h3 id="h3--">
          <a name="三、功能说明" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>三、功能说明
        </h3>
        <p>
          1.卡密商品不可加入购物车；<br />2.卡密商品下单后自动发货；<br />3.卡密商品可使用优惠券；<br />4.卡密商品可参与分佣；<br />5.卡密商品可参与秒杀活动。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
