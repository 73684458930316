<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-212009-37-02-2413.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="社区话题" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>社区话题</strong>
      </h2>
      <h3 id="h3--strong-gt-gt-strong-">
        <a name="位置：平台后台>社区>社区话题" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>位置：平台后台&gt;社区&gt;社区话题</strong>
      </h3>
      <p>
        平台先添加分类信息，然后在这里添加话题，每个话题都必须归属一个分类。<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141714862.png"
          alt=""
        />
      </p>
      <h3 id="h3--strong-strong-">
        <a name="移动端展示位置" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>移动端展示位置</strong>
      </h3>
      <p>
        点这里进入<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141716181.webp"
          alt=""
        /><br />如果选择推荐就会在左边的推荐分类中显示<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309141714748.png"
          alt=""
        /><br />可点击话题筛选出这个话题下的所有图文信息（文章）
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
