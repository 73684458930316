<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-252015-05-16-544.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--strong-strong-">
        <a name="平台协议与规则" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>平台协议与规则</strong>
      </h3>
      <p>
        <strong>位置</strong>：平台后台&gt;设置&gt;协议与规则<br /><strong
          >说明</strong
        >：<br />
        1 . 此处设置的协议与规则，在移动端-设置页展示;<br />
        2 . 用户协议与隐私政策在移动端注册、 登录页同时展示。
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915160838.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915160844.png"
          alt=""
          class="w-1/3"
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915160852.png"
          alt=""
          class="w-1/3"
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309191111942.png"
          alt=""
          class="w-1/3"
        />
        <img
          v-preview-image
          src="@/assets/downloads/202309191112383.png"
          alt=""
          class="w-1/3"
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
