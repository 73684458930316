<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/48cfea0c0f5365a3d2e510d9ee1a108d.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="配置入驻申请协议" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>配置入驻申请协议</strong>
      </h2>
      <p>
        位置：平台后台&gt;设置&gt;协议与规则&gt;商户入驻申请协议<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141026999.png"
          alt=""
        />
      </p>
      <h2 id="h2--strong-strong-">
        <a name="商户入驻" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>商户入驻</strong>
      </h2>
      <p>
        位置：手机端&gt;我的&gt;个人中心&gt;商家入驻<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309141026894.png"
          alt=""
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309141027981.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
