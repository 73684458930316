<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam 2023-09-21 15-27-59-514.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          门店自提，是一种配送方式，用户到店核销，自提货物。万千实训系统多商户商城是每个商户对应一个自提点的模式。
        </p>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <p><strong>第一步：申请腾讯地图key</strong></p>
        <p>
          使用地图配置前，请前往<a href="https://lbs.qq.com"
            >https://lbs.qq.com</a
          >
          进行申请<br />
          注：申请地图key的时候域名白名单里面留空即可，不然会导致获取列表失败<br />
          腾讯地图测试key:5WNBZ-2JYR6-SPUSL-M3WGH-U4KDT-K2FYV
        </p>
        <p><strong>第二步：平台后台配置地图key</strong></p>
        <p>
          位置：平台后台&gt;设置&gt;第三方接口&gt;地图配置<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141642578.png"
            alt=""
          />
        </p>
        <p><strong>第三步：开启门店自提</strong></p>
        <p>
          位置：商户后台&gt;设置&gt;商户信息&gt;店铺信息<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201450387.png"
            alt=""
          />
        </p>
        <p><strong>第四步：移动端展示</strong></p>
        <p><strong>位置：提交订单界面&gt;配送方式选择&gt;门店自提</strong></p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309201508698.png"
            alt=""
            class="w-1/3"
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309201508988.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
