<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/e1c83953224d175789538303f868ee2b.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--strong-strong-">
        <a name="商城主题风格" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>商城主题风格</strong>
      </h3>
      <p>
        位置：平台后台&gt;装修&gt;主题风格<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915094437.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
