<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309211002780.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="添加客服" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>添加客服</strong>
        </h3>
        <p>
          添加商家客服前请先确保<strong>用户已关注商城公众号</strong>(公众号是您或您的用户将要运营的已认证公众号&lt;服务号&gt;
          )，<strong>并关注对应店铺</strong>，之后按如下流程添加客服即可。<br />
          <strong>说明</strong
          >：小程序客服使用的是万千实训系统自主开发的客服系统，绑定开放平台可以通过公众号推送消息，不绑定开放平台客服消息不提醒，客服可从移动端或PC管理后台查看消息并回复。<br />
          <strong>移动端客服入口位置</strong
          >：移动端&gt;个人中心&gt;商家管理&gt;客服记录<br /><strong>
            PC管理后台位置</strong
          >：商户后台&gt;客服管理&gt;进入工作台&gt;
        </p>
        <p>
          <strong>添加客服流程</strong>：比如 添加用户A 为 1号店铺的 客服，请让A
          用户先关注你已认证的公众号&lt;服务号&gt;
          ，再关注商城里的1号店铺，之后在1号店铺的商户后台 添加A用户为客服。
        </p>
        <p><strong>第一步：用户关注商城公众号</strong></p>
        <p><strong>第二步：用户在移动端关注店铺</strong></p>
        <p>
          <strong>位置：移动端&gt;店铺首页</strong><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309140950315.png"
            alt=""
          />
        </p>
        <p>
          <strong>第三步：管理员在商户后台设置添加客服</strong>
        </p>

        <!-- <h3 id="h3--strong-v2-0-strong-"><a
            name="v2.0及以后版本"
            class="reference-link"
          ></a><span class="header-link octicon octicon-link"></span>
          <strong>v2.0及以后版本</strong>
        
        </h3> -->
        <p>
          <strong
            >位置：商户后台&gt;客服&gt;客服列表&gt;添加客服<br />
            说明：</strong
          >可添加客服、点击“编辑”设置客服权限、点击”进入工作台”登录进入客服后台聊天界面。
        </p>
        <p>
          <strong>账号状态</strong
          >：开启：客服账号可用，关闭：客服账号不可用；<br /><strong
            >客服状态</strong
          >：开启：客服在线，关闭：客服不在线；<br />
          <strong>订单通知</strong
          >：开启：客服可以收到公众号订单模版消息通知；填写手机号后，配置短息提醒后，会收到订单短信提醒；关闭：则无消息通知；
          <br /><strong>订单管理</strong
          >：开启：客服可查看订单统计数据，可进行订单改价、发货处理；
          关闭：则无法在移动端查看统计数据及处理订单； <br /><strong
            >订单核销</strong
          >：开启：客服可以在移动端
          进行订单核销；关闭：则无法在移动端核销订单；<br /><strong
            >商品管理</strong
          >：开启：客服可在移动端添加商品、编辑商品信息；关闭：则无法在移动端添加、编辑商品信息。
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309140953745.png"
            alt=""
          /><em>**</em><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309140954296.png"
            alt=""
          />
        </p>
        <p>
          <strong>PC后台客服接待</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309140954312.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309140954712.png"
            alt=""
          />
        </p>
        <p>
          <strong>客服接待分配规则</strong
          >：当用户多时，随机分配给在线客服；如果之前已有交流记录的，优先分配给之前交流过的在线客服。
        </p>
        <p>
          <strong>一个客服可同时与多家店铺绑定</strong><br />可先选择店铺 -
          分别在各店铺处理客服消息、移动端订单管理、移动端订单核销、移动端商品管理<br />
        </p>
        <p>
          <!-- v2.0及以后版本 进入商家管理后台，先切换每个店铺，再进入每个功能
           -->
          <br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309140956385.png"
            alt=""
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309140956097.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
