<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <img
        src="@/assets/downloads/202309261504953.png"
        alt=""
        class="w-full"
        v-preview-image
      />
      <img
        src="@/assets/downloads/202309261504474.png"
        alt=""
        class="w-full"
        v-preview-image
      />
      <img
        src="@/assets/downloads/202309261504878.png"
        alt=""
        class="w-full"
        v-preview-image
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
