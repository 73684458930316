var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video',{staticClass:"aspect-video",attrs:{"poster":require("@/assets/downloads/202309210927719.png"),"src":require("@/assets/downloads/bandicam202023-09-212014-37-54-686.mp4"),"controls":""}}),_c('div',{attrs:{"id":"editormd"}},[_c('div',{staticClass:"right-body markdown-body editormd-html-preview"},[_vm._m(0),_c('p',[_vm._v(" 店铺街是多商户商城集中展示各店铺的功能，万千实训系统移动端商城首页品牌好店下方 点击 “更多店铺” 可进入店铺街浏览。 ")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309141359454.png"),"alt":""}})]),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',[_vm._v(" 备注：推荐是指平台在后台对店铺是否开启推荐，开启推荐就会最优先展示。 ")]),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309141635599.png"),"alt":""}})]),_vm._m(7),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309141636896.png"),"alt":""}})]),_vm._m(8),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309141636089.png"),"alt":""}})]),_vm._m(9),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309141637336.png"),"alt":""}})]),_vm._m(10),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309141637073.png"),"alt":""}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"一、功能介绍"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("一、功能介绍 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"二、操作流程"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("二、操作流程 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3-2-1-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"2.1 店铺街设置"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("2.1 店铺街设置 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("位置")]),_vm._v("：平台后台>装修>页面装修>店铺街"),_c('br'),_c('strong',[_vm._v("说明")]),_vm._v("："),_c('br'),_vm._v(" （1）店铺街共有3种不同样式可选择，同时可根据运营业务选择是否开启店铺街距离展示；"),_c('br'),_vm._v(" （2）店铺街背景图，需要在商户后台>设置>商户信息>店铺信息 页面中上传"),_c('br'),_vm._v(" （3）选择样式1或2时，店铺街背景图格式为：710 x 134px；选择样式3时，店铺街背景图格式为：710 x 460px；"),_c('br'),_vm._v(" 建议平台或商家提前准备图片素材，以保证商城展示效果。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3-2-2-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"2.2 移动端展示样式"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("2.2 移动端展示样式 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("位置")]),_vm._v("：移动端>首页>更多店铺"),_c('br'),_c('strong',[_vm._v("说明")]),_vm._v("：关闭：当关闭时，在店铺街、搜索店铺列表不展示店铺距离、店铺地图定位、用户定位；"),_c('br'),_vm._v(" 开启：当开启时，在店铺街、搜索店铺列表展示店铺距离、店铺地图定位、用户定位。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("店铺街排序说明")]),_c('br'),_vm._v(" 默认：按平台在后台给店铺的排序，默认排序最大的店铺在最前，默认菜单下店铺列表排序优先级：推荐>排序>销量；"),_c('br'),_vm._v(" 销量：指店铺所有商品销量之和，销量最高的店铺在前，销量菜单下店铺列表排序优先级：推荐>销量>排序；"),_c('br'),_vm._v(" 好评：指店铺好评分，好评分越高，排序在前，好评菜单下店铺列表排序优先级： 推荐>评分>排序"),_c('br'),_vm._v(" 距离：指店铺距用户当前定位距离越近，店铺展示位置越靠前。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("店铺街-距离关闭")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("店铺街-距离开启")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("店铺搜索-距离关闭")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("店铺搜索-距离开启")])])
}]

export { render, staticRenderFns }