<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/d7333f85a1cae92609316c29af8b7787.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2-u524Du7AEFu5C55u793A">
        <a name="前端展示" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>前端展示
      </h2>
      <h3 id="h3-u5E73u53F0u4E13u9898u5165u53E3">
        <a name="平台专题入口" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>平台专题入口
      </h3>
      <p>
        <img
          class="w-1/3"
          v-preview-image
          src="@/assets/downloads/20230915153325.png"
          alt=""
        />
      </p>
      <h3 id="h3-u5E73u53F0u4E13u9898u8BE6u60C5">
        <a name="平台专题详情" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>平台专题详情
      </h3>
      <p>
        <img
          class="w-1/3"
          v-preview-image
          src="@/assets/downloads/20230915153331.png"
          alt=""
        />
      </p>
      <h3 id="h3-u5546u6237u4E13u9898u5165u53E3">
        <a name="商户专题入口" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>商户专题入口
      </h3>
      <p>
        <img
          class="w-1/3"
          v-preview-image
          src="@/assets/downloads/20230915153343.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
