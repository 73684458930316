import Vue from 'vue';

Vue.directive('preview-image', {
    bind(el, binding) {
        // console.log('el==> ', el);
        const imageUrl = el.getAttribute('src');
        // 创建预览图元素并设置样式
        const previewElement = document.createElement('div');
        previewElement.classList.add('preview-image-container');
        previewElement.style.backgroundImage = `url(${imageUrl})`;

        // 在文档末尾插入预览图元素
        document.body.appendChild(previewElement);

        // 监听图片点击事件
        el.addEventListener('click', () => {
            previewElement.classList.add('visible');
        });

        // 监听预览图点击事件以关闭预览
        previewElement.addEventListener('click', () => {
            previewElement.classList.remove('visible');
        });

        // 监听键盘事件以关闭预览
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                previewElement.classList.remove('visible');
            }
        });
    },
});
