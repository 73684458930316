<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/c04a18ce2ee83ade11891c77db3e4e40.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-strong-strong-">
          <a name="开启助力活动" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong><strong>开启助力活动</strong></strong>
        </h2>
        <p>
          <strong>位置：商户后台&gt;营销&gt;助力</strong
          ><br />商户添加助力活动商品，平台后台审核，活动发布，用户发起助力活动，参与助力人数达到商城设定人数，即有资格以助力价购买到活动商品。
        </p>
        <h3 id="h3--strong-strong-">
          <a name="添加助力商品" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>添加助力商品</strong>
        </h3>
        <ol>
          <li>助力活动不参与分销返佣；不能使用优惠券；</li>
          <li>
            手动选择助力活动时间，包含助力开始的日期以及当天的助力时间（时间插件，具体到时分秒）；
          </li>
          <li>助力人数设置，设置参与人数，达到参与人数即助力活动成功；</li>
          <li>可设置相同ID的商品每个用户助力次数，至少为1；</li>
          <li>可设置助力商品限购件数，默认为1，至少为1；</li>
          <li>添加助力商品后必须要选择规格后才能上架；</li>
          <li>助力商品只可选择商品中的一个规格；</li>
          <li>
            可修改助力商品的售价和限购数量（ “限量”，与普通商品为共享库存）<br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915153714.png"
              alt=""
            /><br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915153719.png"
              alt=""
            /><br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915153726.png"
              alt=""
            /><br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915153731.png"
              alt=""
            />
          </li>
          <li>助力活动完成后，支付后减库存；</li>
          <li>助力发起人不能给自己助力；</li>
          <li>
            助力成功人数：指发起助力活动，并在活动时间内达到活动要求人数的发起人数；
          </li>
          <li>助力参与人数：指参与助力活动的所有人数，包括发起人和助力人；</li>
          <li>
            用户助力次数：指不同人发起的助力发给同一人 A 时，设置的A
            的助力次数；
          </li>
          <li>1个普通商品可参加多个助力活动。</li>
        </ol>
        <h3 id="h3--strong-strong-">
          <a name="审核/编辑助力活动" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>审核/编辑助力活动</strong>
        </h3>
        <p><strong>位置：平台后台&gt;营销&gt;助力</strong></p>
        <ol>
          <li>
            平台后台审核助力商品<br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915153743.png"
              alt=""
            />
          </li>
          <li>
            点击“编辑” - 设置助力活动在移动端助力列表优先展示顺序<br />优先展示顺序：星级&gt;排序&gt;销量<br />规则：<br />（1）
            5星&gt;4星&gt;3星&gt;2星&gt;1星<br />（2） 排序是星级级别内的排序<br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915153748.png"
              alt=""
            /><br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915153754.png"
              alt=""
            />
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
