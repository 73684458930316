<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--">
        <a name="一、功能介绍" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h2>
      <p>小票打印是在处理订单时打印小票凭据的功能，可以自动打印或手动打印。</p>
      <h2 id="h2--">
        <a name="二、操作流程" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h2>
      <h3 id="h3--strong-strong-">
        <a name="第一步：易联云开发者申请" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>第一步</strong>：易联云开发者申请
      </h3>
      <p>
        <a href="https://dev.10ss.net/">https://dev.10ss.net/</a><br />
        <img
          v-preview-image
          src="@/assets/downloads/bfa5df20226694ddd2756d47edfab0a4_2338x1906.png"
          alt=""
        />
      </p>
      <h3 id="h3--strong-strong-">
        <a
          name=" 第二步：在应用列表申请 自有型应用服务类型 应用"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span
        ><strong> 第二步</strong>：在应用列表申请 自有型应用服务类型 应用
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/19f16e0fa90e55a1ab973e0984281974_669x742.png"
          alt=""
        />
      </p>
      <h3 id="h3--strong-strong-">
        <a name="第三步：申请自有型应用" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>第三步</strong>：申请自有型应用
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/e5c381e1c0728dc38d53379eb91c9015_1239x704.png"
          alt=""
        />
      </p>
      <h3 id="h3--strong-strong-">
        <a name="第四步：添加应用" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>第四步：添加应用</strong>
      </h3>
      <p>
        <strong>说明</strong
        >：点击添加就会提示添加成功，点击应用列表查看”用户ID”、
        “应用ID”、”应用密钥”。
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/189134ffab7e6399760d136c26e27890_1062x546.png"
          alt=""
        />
      </p>
      <h3 id="h3--strong-k4-strong-">
        <a
          name=" 第五步：购买易联云K4无线版小票打印机"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span
        ><strong> 第五步：购买易联云K4无线版小票打印机</strong>
      </h3>
      <p>
        <strong> 说明：</strong
        >万千实训系统系统已内置小票打印系统，具体支持的打印机型号为:<strong>易联云K4无线版</strong>，设备购买可在淘宝或京东购买
      </p>
      <h3 id="h3--strong-strong-">
        <a name=" 第六步：添加小票打印机" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong> 第六步：添加小票打印机</strong>
      </h3>
      <p>
        位置：商户后台&gt;设置&gt;打印机配置<br />
        说明：打印机终端号在打印机的底部；一个店铺可添加多台打印机。
      </p>
      <p>
        <img
          v-preview-image
          src="/uploads/mer/mer2/20220525/9a137160c38dfd70547556405b8132dc.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="/uploads/mer/mer2/20220525/9fb8aeed5c25b94d5f14249190c4743b.png"
          alt=""
        />
      </p>
      <h3 id="h3--strong-strong-">
        <a name="  第七步：开启打印机" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong> 第七步：开启打印机</strong>
      </h3>
      <p>
        <strong>说明</strong>：<br />
        打印机状态：开启：小票打印机功能开启；关闭：小票打印机功能关闭<br />
        打印机自动打印：开启：订单支付后自动打印小票；关闭：订单支付后不自动打印小票，可手动在订单管理中进行打印。<br />
        <img
          v-preview-image
          src="/uploads/mer/mer2/20220525/d1006eedd33b45cd55b09948dec24bb7.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
