<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309061632411.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--">
        <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h3>
      <p>
        保障服务功能是多商户商城对平台产品、服务保障的基础工具，也是用户选择商品的部分依据。因此运用好保障服务功能也是商城运营的关键环节。
      </p>
      <h3 id="h3--">
        <a name="二、操作步骤" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>二、操作步骤
      </h3>
      <p>
        <strong>第一步：先在平台后台添加保障服务条款</strong><br />
        位置：平台后台&gt;商品&gt;保障服务&gt;添加保障服务
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309061622558.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309061622316.png"
          alt=""
        />
      </p>
      <p><strong>第二步：在商户后台编辑保障服务说明模板</strong></p>
      <p>位置：商户后台>商品>服务模板>添加服务说明模板</p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309061623616.png"
          alt=""
        />
      </p>
      <p><strong>第三步：添加商品时选择保障服务说明模板</strong></p>
      <p>位置：商户后台>商品>商品列表>添加商品>其他设置</p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309191718289.png"
          alt=""
        />
      </p>
      <!-- <p>
         <img
          v-preview-image
          src="@/assets/downloads/202309061625353.png"
          alt=""
        />
      </p> -->
      <p>
        <strong>第四步： 移动端展示样式</strong><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309191718489.png"
          alt=""
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309191718083.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
