import { render, staticRenderFns } from "./53.vue?vue&type=template&id=a80c038c&scoped=true"
import script from "./53.vue?vue&type=script&lang=js"
export * from "./53.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a80c038c",
  null
  
)

export default component.exports