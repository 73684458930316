<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/0c1c53480d1984ab1d74c449a3bfdd8a.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="优惠套餐" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>优惠套餐</strong>
      </h2>
      <p>
        <strong>位置：商户后台&gt;营销&gt;优惠套餐</strong
        ><br />优惠套餐不能分佣<br />添加/列表/编辑/删除/上下架<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915134201.png"
          alt=""
        />
      </p>
      <p>
        添加优惠套餐<br />只能选择额审核通过的普通商品，每个商品在同一个套餐不能重复，可以选择规格<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915134201_1.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
