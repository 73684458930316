<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/20230918155054.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          热销排行是近年来平台商城流行的运营工具之一，基于平台数据计算能力，在用户选择商品时多提供一个参考指标，在提高下单率方面有显著效果。<br />
        </p>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <p>
          <strong>第一步：开启热销排行</strong><br /><strong
            >位置：平台后台&gt;设置&gt;商城设置&gt;商城设置</strong
          ><br /><strong>说明：</strong>
        </p>
        <p>
          1.热销排行开关开启时：移动端首页模块、移动端和PC端商品详情、排行列表均显示；关闭：均不显示；
        </p>
        <p>
          2.选择热销商品分类级别：一级、二级、三级均可选择；此处设置几级，商城装修&gt;热卖排行链接显示几级分类，平台商品排行就按几级分类里的商品进行排名，排行榜单中就显示该分类下销量最高的前15个商品；总榜显示平台所有商品中销量最高的前15个商品；
        </p>
        <p>
          3.热销排行计算时间：指每隔多长时间系统自动计算一次商城排行，如设置1，即每隔1小时商城自动计算更新一次商品商城排行。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121525336.png"
            alt=""
          />
        </p>
        <p><strong>第二步：在商城装修添加热销排行组件</strong></p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309121526839.png"
            alt=""
          />
        </p>
        <p>
          <strong>第三步：查看设置效果</strong><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309191011982.jpg"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
