<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam24166.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-">
          <a name="社区分类" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>社区分类</strong>
        </h2>
        <p>
          <strong>位置：平台后台&gt;社区&gt;社区分类</strong
          ><br />平台先添加分类信息，这里的分类只有一级<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309191041052.png"
            alt=""
          />
        </p>
        <p>
          <strong>使用位置：移动端社区展示</strong><br />移动端展示位置1<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141703118.png"
            alt=""
          /><br />移动端展示位置2<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141703677.png"
            alt=""
          /><br />这里的推荐是单独的一个分类，不是在后台添加，而是移动端固定的一个分类，展示的话题是推荐话题。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
