<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="社区评论" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>社区评论</strong>
      </h2>
      <p>
        <strong>位置：平台后台&gt;社区&gt;社区评论</strong
        ><br />评论只有删除操作，可以筛选查看，也可以通过文章跳转过来

        <!-- ，评论1.7.2版本增加了审核功能，可以在社区配置中开启免审核 -->

        <br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141727459.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
