var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video',{staticClass:"aspect-video",attrs:{"poster":require("@/assets/downloads/202309210927719.png"),"src":require("@/assets/downloads/202309061702546.mp4"),"controls":""}}),_c('div',{attrs:{"id":"editormd"}},[_c('div',{staticClass:"right-body markdown-body editormd-html-preview"},[_vm._m(0),_c('p',[_vm._v(" 移动端商品管理主要是方便商家在移动端进行商品管理，比如快速上下架商品、或编辑商品信息。 ")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309061704139.png"),"alt":""}})]),_vm._m(4),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309061704289.png"),"alt":""}})]),_vm._m(5),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309061704870.png"),"alt":""}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"一、功能介绍","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("一、功能介绍 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"二、操作步骤","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("二、操作步骤 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第一步 在添加客服中开启商品管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 位置：商后后台>客服>客服列表>添加客服"),_c('br'),_vm._v("说明： 商户在客服管理中"),_c('strong',[_vm._v("开启手机端管理")]),_vm._v("即可允许客服管理商品 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第二步：开启后客服的个人中心会展示”商家管理”入口")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第三步： 如果一个客服关联了多个商户,可以在商品管理中切换店铺")])])
}]

export { render, staticRenderFns }