<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam 2023-09-21 14-14-00-062.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--">
        <a name="一、功能介绍" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h2>
      <p>
        开启店铺后，该店铺及店铺内商品才可正常展示在商城，可被用户搜索到。因此在添加好商品后，请务必在此处开启店铺。
      </p>
      <h2 id="h2--">
        <a name="二、操作流程" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h2>
      <p>
        <strong>位置：</strong
        >商户后台&gt;设置&gt;商户信息&gt;基本信息<br /><strong>说明：</strong
        ><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141139489.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
