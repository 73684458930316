<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309071530721.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--">
        <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h3>
      <p>
        商品参数是商品信息在商城展示的一种补充方式，
        多商户商品参数设置，可在平台后台设置，也可在商户后台设置，添加商品时选择商品参数，移动端商城和PC商城商品详情展示所设置的商品参数。
      </p>
      <h3 id="h3--">
        <a name="二、操作流程" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h3>
      <p><strong>第一步：平台后台设置商品参数</strong></p>
      <p>
        位置： 平台后台&gt;商品&gt;商品参数&gt;平台商品参数<br />说明：平台后台可添加平台参数模板；平台后台可查看商户后台参数模板，并复制商户参数模板到平台后台。
      </p>
      <p>平台添加商品参数模板</p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309071518818.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309071518531.png"
          alt=""
        />
      </p>
      <p>
        平台复制商户参数模板<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309071519308.png"
          alt=""
        />
      </p>
      <p><strong>第二步：商户后台设置商品参数</strong></p>
      <p>
        位置：商户后台&gt;商品&gt;商品参数<br />说明：商户后台的商品参数没有跟商户商品分类和平台商品分类关联<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309071519141.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309071519561.png"
          alt=""
        />
      </p>
      <p>
        <strong>第三步：商品添加商品参数</strong
        ><br />位置：商户后台&gt;商品&gt;商品列表&gt;添加或编辑商品
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309071519897.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309071519588.png"
          alt=""
        />
      </p>
      <p>
        PC商城展示效果<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309191803485.png"
          alt=""
        />
      </p>
      <p>移动端商城展示效果</p>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309200954671.png"
          alt=""
        /><br />
        <img
          class="w-1/3"
          v-preview-image
          src="@/assets/downloads/202309200954550.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
