var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video',{staticClass:"aspect-video",attrs:{"poster":require("@/assets/downloads/202309210927719.png"),"src":require("@/assets/downloads/202309071500520.mp4"),"controls":""}}),_c('div',{staticClass:"markdown-body editormd-html-preview",attrs:{"id":"editormd"}},[_vm._m(0),_c('p',[_vm._v(" 商品口令：开启此功能，用户在分享商品时可选择生成口令并复制，并发送给好友，好友复制口令后，打开商城时会自动解析看到该商品信息，是分享商品的一种方式。 ")]),_vm._m(1),_vm._m(2),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309071453700.png"),"alt":""}})]),_vm._m(3),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309071454081.png"),"alt":""}})]),_vm._m(4),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309191751930.png"),"alt":""}})]),_vm._m(5),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309071454056.png"),"alt":""}})]),_vm._m(6),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309071454788.png"),"alt":""}})]),_vm._m(7),_c('p',[_vm._v("该功能开启，适用于所有普通商品和活动商品。")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"一、功能介绍","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("一、功能介绍 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"二、使用方法","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("二、使用方法 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--gt-gt-gt-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"第一步：平台后台>设置>商城设置>商品设置","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("第一步：平台后台>设置>商城设置>商品设置 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--gt-gt-gt-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"第二步：移动端商城>商品详情>分享>生成口令","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("第二步：移动端商城>商品详情>分享>生成口令 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"第三步：复制口令","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("第三步：复制口令 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"第四步：复制成功","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("第四步：复制成功 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"第五步：发送给好友，好友打开商城自动解析商品","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("第五步：发送给好友，好友打开商城自动解析商品 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"三、功能说明","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("三、功能说明 ")])
}]

export { render, staticRenderFns }