<template>
  <div>

    <!-- <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-strong-v1-5-strong-"><a
            name=" 自动分账 v1.5及以后版本（开通微信电商收付通模式）"
            class="reference-link"
          ></a><span class="header-link octicon octicon-link"></span><strong> </strong>自动分账<strong> v1.5及以后版本（开通微信电商收付通模式）</strong></h2>
        <h3 id="h3--strong-strong-"><a
            name="一、开启自动分账"
            class="reference-link"
          ></a><span class="header-link octicon octicon-link"></span><strong>一、开启自动分账</strong></h3>
        <p><strong>第一步：请在微信支付商户后台开通电商收付通 ：<a href="https://pay.weixin.qq.com/">https://pay.weixin.qq.com/</a> </strong><br>在 产品中心&gt;合作工具箱 中开通电商收付通<br> <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626236300677.png"
            alt=""
          ><br>开通电商收付通需要上传备案许可证<br> <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626236363698.png"
            alt=""
          ><br><strong>第二步：请在平台后台做相应参数配置</strong><br> <img
          v-preview-image
            src="/uploads/mer/mer/20220407/2bbcda5d4b9aa4b12adf6406d2c81d2f.png"
            alt=""
          ><br>位置：平台后台&gt;设置&gt;支付配置&gt;微信服务商支付配置<br> <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626149236615.png"
            alt=""
          ></p>
        <p><strong>第三步：开启分账</strong><br>位置：平台后台&gt;财务&gt;转账设置<br> <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626148985013.png"
            alt=""
          ></p>
        <p><strong>第四步：子商户后台操作</strong><br>位置：商户后台&gt;财务&gt;申请分账商户&gt;提交资料&gt;审核完成<br>子商户在该页面上传资料、查看审核状态、审核结果。<br> <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626149469560.png"
            alt=""
          ><br> <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626149792111.png"
            alt=""
          ><br> <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626149884346.png"
            alt=""
          ></p>
        <p><strong>第五步：设置自动分账时间</strong><br>位置：平台后台&gt;设置&gt;商城设置<br> 此处设置售后时长，比如10天，指用户确认收货后10天内可以退货；超过10天就自动分账了，不能退货；如果此处没有设置或设置的是0，默认用户在确认收货后15天自动分账，15天自动分账后，就不能退货了。<br> <img
          v-preview-image
            src="/uploads/mer/mer/20220211/b9fdfd22680fbfef2c0c69e214ccaa23.png"
            alt=""
          ><br>备注：</p>
        <ol>
          <li>以上步骤全部完成后，用户通过微信所支付的金额，会根据设置时间自动分账到子商户号。</li>
          <li>通过余额支付、支付宝支付的金额请前往财务-账单管理查看，需子商户申请转账，平台审核并线下转账。</li>
          <li>自动分账、线下转账，每笔结算记录都在财务账单中可查看；自动分账的金额会直接到商户账户，不会显示在平台后台的商户余额。</li>
          <li>用户可根据自身业务及资质情况自由选择开启自动分账或不开启，如果不开启，则启用线下转账模式，此处以上流程可忽略，详情请见上节：“<strong>财务账单</strong>”</li>
        </ol>
        <h3 id="h3--strong-strong-"><a
            name="二、查看分账状态"
            class="reference-link"
          ></a><span class="header-link octicon octicon-link"></span><strong>二、查看分账状态</strong></h3>
        <p><strong>位置：平台后台&gt;财务&gt;分账管理</strong><br> <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626150968005.png"
            alt=""
          ><br><strong> 位置：商户后台&gt;财务&gt;分账管理</strong><br> <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626151036850.png"
            alt=""
          ></p>
        <h3 id="h3--strong-strong-"><a
            name="三、商户提现"
            class="reference-link"
          ></a><span class="header-link octicon octicon-link"></span><strong>三、商户提现</strong></h3>
        <p> 自动分账的金额到分账商户的商户号中，需登录微信后台提现到银行<br> 第一步：微信后台地址：<a href="https://pay.weixin.qq.com/">https://pay.weixin.qq.com/</a><br> 第二步：商户申请分账时设置的超级管理员扫码登陆<br>  <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626168291257.png"
            alt=""
          ><br> 第三步：确认账户信息，输入转账金额，提交转出<br>  <img
          v-preview-image
            src="/uploads/mer/images/screenshot_1626168392919.png"
            alt=""
          ></p>
      </div>

    </div> -->
  </div>
</template>
                      
                <script>
export default {};
</script>
                <style lang="scss" scoped></style>
                      