<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-obs-strong-">
        <a name="华为OBS创建及使用" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>华为OBS创建及使用</strong>
      </h2>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915155121.png"
          alt=""
        />
      </p>
      <hr />
      <h3 id="h3-1-https-www-huaweicloud-com-">
        <a
          name="1.注册，打开华为云   https://www.huaweicloud.com/"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >1.注册，打开华为云
        <a href="https://www.huaweicloud.com/">https://www.huaweicloud.com/</a>
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/680943fa05b3b325744831d9afe521cf.png"
          alt=""
        />
      </p>
      <h3 id="h3-2-">
        <a
          name="2.选择创建桶，创建完成后点桶的名称进入详情"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >2.选择创建桶，创建完成后点桶的名称进入详情
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/4d2b2296401431effb91da570ee4f011.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/f94692833bec8892222bdd91de954eb3.png"
          alt=""
        />
      </p>
      <p>
        这里有需要用到的三个信息<br />
        <img
          v-preview-image
          src="@/assets/downloads/8db3012d7790127b26695cdeb181df32.png"
          alt=""
        />
      </p>
      <h3 id="h3-3-">
        <a name="3.创建凭证" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>3.创建凭证
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/145665d202d5f141b05f4fa0ea841a88.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/8d1478895c4c5dc1264341874b360df9.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/69c43cbe427c6997fb32d2ae65e83f30.png"
          alt=""
        />
      </p>
      <p>
        创建完成后要下载，且只能下载一次，一定要保管好，<br />
        <img
          v-preview-image
          src="@/assets/downloads/1815903788210260c805a410cfae441e.png"
          alt=""
        /><br />
        打开下载的文件这个就是这secretKey<br />
        <img
          v-preview-image
          src="@/assets/downloads/64a71b241df9db5372c61c646d9f33cd.png"
          alt=""
        />
      </p>
      <p>按照需求填写保存即可</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
