<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/9604000bb68f17aea129a90ca1846272.mp4"
      controls
    ></video>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="添加直播商品" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>添加直播商品</strong>
        </h3>
        <p>
          <strong>位置：商户后台&gt;营销&gt;直播&gt;直播商品管理</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141613.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="审核直播商品" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>审核直播商品</strong>
        </h3>

        <p>
          直播商品有两种审核方式：<br />手动审核：平台后台需手动审核<br />自动审核：平台后台自动审核<br /><strong>
            切换审核方式</strong
          ><br /><strong> 商户后台&gt;商户管理&gt;商户列表&gt;编辑</strong>

          <br />开启：平台后台手动审核；关闭：自动审核<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141651.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141700.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="手动审核直播商品" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>手动审核直播商品</strong>
        </h3>
        <p>
          <strong>位置 ：平台后台&gt;营销&gt;直播&gt;直播商品管理</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141751.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141815.png"
            alt=""
          /><br />
          直播间审核通过后，可直接导入已审核通过的直播商品<br /><strong
            >位置：商户后台&gt;营销&gt;直播&gt;直播间管理</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141843.png"
            alt=""
          />
        </p>
        <p>
          选择已审核通过的直播商品-导入直播间<br />一个直播间可以导入多个商品<br />一个商品可以被导入到多个直播间<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141848.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="查看直播间的直播商品" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>查看直播间的直播商品</strong>
        </h3>
        <p>
          <strong>位置：商户后台&gt;营销&gt;直播&gt;直播间管理</strong
          ><br />点击直播列表-详情，可查看直播间导入的直播商品及销售数量<br />同一直播商品导入不同直播间，各直播间销售的该直播商品数量实时显示为各个直播间该商品销售数量总和。<br />如需统计一个直播间某商品销售数量，可只将该商品导入一个直播间；或各个直播间添加不同商品名称的直播商品。<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141853.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141858.png"
            alt=""
          />
        </p>
        <p>
          <strong>位置：平台后台&gt;营销&gt;直播&gt;直播间管理</strong
          ><br />点击直播列表-详情，可查看直播间导入的直播商品及销售数量<br />同一直播商品导入不同直播间，各直播间销售的该直播商品数量实时显示为各个直播间该商品销售数量总和。<br />如需统计一个直播间某商品销售数量，可只将该商品导入一个直播间；或各个直播间添加不同商品名称的直播商品。<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141903.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915141908.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
