<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam 2023-09-21 11-06-55-645.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <p>
          <strong>平台客服功能开启</strong>
          <!-- <br> 
          注：v2.0新增平台客服功能<br>
           -->
          <strong>管理后台开启位置</strong>：平台后台&gt;客服&gt;客服设置<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141005225.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="添加平台客服" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>添加平台客服</strong>
        </h3>
        <p>
          位置：平台后台&gt;客服&gt;客服列表<br /><strong>账号状态</strong
          >：开启：客服账号可用，关闭：客服账号不可用<br /><strong
            >客服状态</strong
          >：开启：客服在线，关闭：客服不在线<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141007983.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141007791.png"
            alt=""
          />
        </p>
        <p>
          <strong>移动端用户入口展示位置</strong
          >：移动端&gt;个人中心&gt;问题反馈<br />当后台开启平台客服，移动端页面如下，有咨询平台客服入口<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141008095.png"
            alt=""
          /><br />当后台关闭平台客服，移动端页面如下，无咨询平台客服入口<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141009112.webp"
            alt=""
          />
        </p>
        <p>
          <strong>PC商城展示位置</strong
          >：PC商城首页&gt;右侧悬浮条&gt;客服<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141010840.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141011305.png"
            alt=""
          /><br /><strong>PC商城首页平台电话客服设置位置</strong
          >：平台后台&gt;设置&gt;系统设置<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141011760.png"
            alt=""
          />
        </p>
        <p>
          <strong>移动端平台客服入口位置</strong
          >：移动端&gt;个人中心&gt;平台管理
        </p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141011840.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
