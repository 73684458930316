<template>
  <div>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--https-pay-weixin-qq-com-">
          <a
            name="一、登录微信商户后台   https://pay.weixin.qq.com/"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          >一、登录微信商户后台
          <a href="https://pay.weixin.qq.com/">https://pay.weixin.qq.com/</a>
        </h3>
        <p>选择 - 产品中心 - 商家转账到零钱</p>
        <p>
          开通此项服务<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121748684.png"
            alt=""
          />
        </p>
        <h3 id="h3--">
          <a name="二、 进入次功能" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、 进入次功能
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309121748107.webp"
            alt=""
          />
        </p>
        <h3 id="h3--">
          <a name="三、 添加应用场景" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>三、
          添加应用场景
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309121749107.png"
            alt=""
          />
        </p>
        <h3 id="h3--">
          <a
            name="四、 选择分销返佣，然后填写资料，审核通过即可"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span>四、
          选择分销返佣，然后填写资料，审核通过即可
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309121749273.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
