<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309061504707.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3-crmeb-">
        <a name="分类说明" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>分类说明
      </h3>
      <p>
        1.平台为三级分类，商户为二级分类，添加商品前需先添加对应的分类；<br /><img
          src="@/assets/downloads/202309191710921.png"
          alt=""
          v-preview-image
        />
      </p>
      <p>
        商品-&gt;商品分类-&gt;添加分类<br /><img
          src="@/assets/downloads/202309061442189.png"
          alt=""
          v-preview-image
        /><br />2.商品必须选择在二级分类下，且只能选择一个分类，否则前台不展示；<br />3.隐藏分类直接关闭显示按钮。<br />4.分类下有产品或者下级分类，则该分类无法直接删除，需先删除分类下的下级分类和产品；<br />5.只有三级分类图标才能在前端显示
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
