<template>
  <div>
    <div
      id="editormd"
      class="markdown-body editormd-html-preview"
    >

      <h2 id="h2--strong-strong-"><a
          name="佣金提现规则"
          class="reference-link"
        ></a><span class="header-link octicon octicon-link"></span><strong>佣金提现规则</strong></h2>
      <p>1.可提现的只能为推广佣金。（用户充值的金额、系统后台充值的金额等不可提现）。<br>2.用户发起提现后，系统将自动扣除用户相应佣金及余额。<br>3.用户发起提现后，需管理后台审核（财务→提现管理→通过/未通过）。<br>4.如管理后台选择拒绝用户提现，系统将自动返还提现金额至用户账户。<br>5.冻结的佣金不可提现，达到解冻时间后方可提现；冻结期从用户获得返佣(确认收货)时开始计算。</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
