<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309061546362.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <p>*注意事项：平台后台不能添加商品，只有商户后台能添加商品</p>
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <pre><code>   商品发布功能是商品模块最基本的功能，也是商城运营第一步。
   添加商品之前请先添加平台商品分类，平台品牌分类，商户后台商品分类，以上分类按要求添加完成后，才可顺利添加发布商品。
</code></pre>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <p>位置：商户后台&gt;商品 &gt; 商品列表 &gt; 添加商品</p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309061541956.png"
            alt=""
          />
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309061541200.png"
            alt=""
          />
        </p>
        <p><strong>2.1 添加商品前准备工作</strong></p>
        <p>
          1 . 商品添加为分步添加,带 * 为必填选项<br />2 . 运费模板提前设置<br />3
          .
          优惠券需提前设置，此处添加的优惠券是指购买该商品后会自动赠送的优惠券，所以需提前在【优惠券】下创建
          ”赠送优惠券”。<br />4 .
          商品规格可选择单规格与多规格，多规格需要先选择规格模板后才可编辑规格属性<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061541188.png"
            alt=""
          />
        </p>
        <p>
          <strong>选择分类注意事项</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061542970.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061542722.png"
            alt=""
          />
        </p>
        <hr />
        <p>
          <strong>2.2 商品规格</strong><br /><strong
            ><strong>1. 商品单规格</strong></strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061542992.png"
            alt=""
          /><br /><strong><strong>2. 商品多规格</strong></strong
          ><br />（1）首先需要选择规格模板，点击确认后生成相应规格<br />（2）用户可添加新规格，添加完成后点击立即生成，即可生成表格<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061542746.png"
            alt=""
          /><br /><strong>2.3商品主视频</strong
          ><br />请先配置视频上传地址，再在商品添加处上传视频<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061542730.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061542739.png"
            alt=""
          />
        </p>
        <p>
          <strong>2.4 商品详情</strong
          ><br />商品详情可添加文字、图片、视频<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061542244.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061542745.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061542242.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061543742.png"
            alt=""
          />
        </p>
        <p>
          <strong>2.5 商品其它设置</strong
          ><br />普通商品限购设置：限购具体规格，默认0 为不限购<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061543774.png"
            alt=""
          />
        </p>
        <p>
          <strong>2.6 提交前预览商品</strong
          ><br />商户后台商品编辑、添加页面及列表均可预览商品移动端效果<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061543739.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061543740.png"
            alt=""
          /><br />平台后台商品管理 列表页可预览商品移动端效果<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061543242.png"
            alt=""
          />
        </p>
        <h3 id="h3--">
          <a
            name="三、后台商品列表展示规则说明"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span
          >三、后台商品列表展示规则说明
        </h3>
        <p>
          位置：商户后台&gt;商品&gt;商品列表<br />
          平台后台&gt;商品&gt;商品管理<br />
          1上架或下架由商户后台控制，特殊情况，平台可通过强制下架，限制商户商品显示；强制下架后，商品进入审核未通过状态；<br />
          2显示或关闭由平台后台控制；<br />
          3初次添加的商品，平台默认关闭；之后的状态以平台操作为准；<br />
          4当商品下架后，无论平台开启或关闭，移动端均不展示该商品；当商品上架时，平台如果关闭，该商品不会展示在移动端；<br />
          5以上规则同样适用于秒杀、助力、全款预售、定金预售、拼团活动商品列表的显示。
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/a7f74bfd9f18335999ae5a3a9c2feca3.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
