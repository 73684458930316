<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-212014-31-22-603.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--">
        <a name="一、功能介绍" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h2>
      <p>
        店铺配置是在商户后台对店铺运营所需功能进行设置的地方，包括发票功能开启、警戒库存、PC端商户首页顶部图、退货联系信息、联系客服的方式。
      </p>
      <h2 id="h2--">
        <a name="二、操作流程" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h2>
      <p>
        位置：商户后台&gt;设置&gt;店铺配置<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141355918.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
