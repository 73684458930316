<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309061535297.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--">
        <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h3>
      <p>
        1.商品标签，主要用于灵活管理商品，比如筛选一批商品，准备做618、或双11活动，可通过商品标签提前标记筛选出来，方便高效管理。<br />2.平台后台和商户后台都可设置商品标签，平台商品标签和商户商品标签不互通，无关联。
      </p>
      <h3 id="h3--">
        <a name="二、操作步骤" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>二、操作步骤
      </h3>
      <p>
        <strong>第一步：添加商品标签</strong
        ><br />位置：平台后台&gt;商品&gt;商品标签<br />说明：商品标签名不能重复
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309061533756.png"
          alt=""
        />
      </p>
      <p><strong>第二步：使用标签</strong></p>
      <p>普通商品、秒杀、预售、拼团、助力、专题活动都可以设置商品标签</p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309061533213.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
