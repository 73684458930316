<template>
  <div>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-">
          <a name="社区评论" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>社区评论</strong>
        </h2>
        <p>
          <strong>位置：平台后台&gt;社区&gt;社区配置</strong><br /><strong
            >说明：</strong
          >
        </p>
        <p>
          1.社区开启： 开启，此功能才能正常使用，关闭则不能正常使用，默认开启；
        </p>
        <p>
          2.图文免审：审核，指用户发布的图文内容需要平台审核后才能展示在种草社区；免审核，指用户发布的图文不需要审核，直接展示在商城种草社区；
        </p>
        <p>
          3.短视频审核：审核，指用户发布的视频内容需要平台审核后才能展示在种草社区；免审核，指用户发布的图文不需要审核，直接展示在商城种草社区；
        </p>
        <p>
          4.移动端内容展示：图文展示：指仅展示图文内容，不展示视频内容；短视频展示：指仅展示视频内容，不展示图文内容；两个全选即图文和视频内容都展示，默认两个全部展示；
        </p>
        <p>
          5.允许发帖用户：全部用户，指允许全部用户发布内容；绑定手机号用户：指仅允许绑定手机号用户发布内容；
        </p>
        <p>
          6.社区评论开关：开启：
          指允许用户发表对内容的评论；关闭：不允许用户发表对内容的评论；
        </p>
        <p>
          7.评论用户：全部用户，指全部用户都可以评论；绑定手机号用户：指仅允许绑定手机号的用户发表评论；
        </p>
        <p>
          8.评论审核：免审核，指用户发表评论后不需要审核直接展示在内容区下方；
          审核，指用户发表的评论需要审核通过后才会展示在内容区下方。
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141729627.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
