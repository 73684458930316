<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/6a6a889488eeae1a13675ac881c88df9.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3-1-">
          <a name=" 店铺首页通栏" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span> 店铺首页通栏
        </h3>
        <p>
          <strong>PC端展示：</strong>
          <img
            v-preview-image
            src="@/assets/downloads/20230915092031.png"
            alt=""
          /><br /><strong>配置位置：商户后台&gt;设置&gt;店铺配置</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915092035.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
