var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"editormd"}},[_c('div',{staticClass:"right-body markdown-body editormd-html-preview"},[_vm._m(0),_c('p',[_vm._v(" 卡密商品：是商品的一种类型，与普通商品相比，下单时无需填写收货地址，且下单后系统自动发货。 ")]),_vm._m(1),_c('p',[_vm._v(" 添加固定商品"),_c('br'),_vm._v("位置：商户后台>商品>添加商品"),_c('br'),_vm._v("注："),_c('br'),_vm._v("（1）请提前在平台后台和商户后台添加好卡密商品对应的分类，平台后台添加好品牌。"),_c('br'),_vm._v("（2）添加商品时选择卡密商品类型"),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309011554935.png"),"alt":""}})]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"一、功能介绍","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("一、功能介绍 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"二、操作流程","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("二、操作流程 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/downloads/202309011554523.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/downloads/202309011554516.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/downloads/202309011554082.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"三、功能说明","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("三、功能说明 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 1.卡密商品不可加入购物车；"),_c('br'),_vm._v("2.卡密商品下单后自动发货；"),_c('br'),_vm._v("3.卡密商品可使用优惠券；"),_c('br'),_vm._v("4.卡密商品可参与分佣；"),_c('br'),_vm._v("5.卡密商品可参与秒杀活动。 ")])
}]

export { render, staticRenderFns }