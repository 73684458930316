<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309210933891.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-">
          <a name="用户协议与隐私政策" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>用户协议与隐私政策</strong>
        </h2>
        <h3 id="h3--strong-strong-">
          <a name="后台添加" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>后台添加</strong>
        </h3>
        <p>
          位置：平台后台&gt;用户&gt;用户协议<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131613584.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="移动端" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>移动端</strong>
        </h3>
        <p>
          位置：H5 用户注册/登录<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309191020280.png"
            alt=""
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309191020250.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
