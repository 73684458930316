import { render, staticRenderFns } from "./59.vue?vue&type=template&id=b12201fc&scoped=true"
import script from "./59.vue?vue&type=script&lang=js"
export * from "./59.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b12201fc",
  null
  
)

export default component.exports