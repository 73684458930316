<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309061453397.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--">
        <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h3>
      <p>
        虚拟商品：是商品的一种类型，与普通商品相比，无需物流发货。主要适用于经营虚拟币、源码、网盘、服务类等业务模式。
      </p>
      <h3 id="h3--">
        <a name="二、操作流程" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h3>
      <p>
        添加虚拟商品<br />位置：商户后台＞商品＞商品列表＞添加商品<br />注：提前在平台后台和商户后台添加好商品分类，平台后台添加好品牌。<br />说明：添加虚拟商品，先选择虚拟商品类型，后添加自定义表单，其它设置与普通商品一样。<br /><img
          src="@/assets/downloads/202309061439323.png"
          alt=""
          v-preview-image
        /><br /><img
          v-preview-image
          src="@/assets/downloads/202309061439490.png"
          alt=""
        />
      </p>
      <h3 id="h3--">
        <a name="三、功能说明" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>三、功能说明
      </h3>
      <p>
        <strong> 1. </strong>虚拟商品不可加入购物车；<br />
        <strong> 2.</strong> 虚拟商品无需物流发货；<br />
        <strong> 3.</strong>虚拟商品可使用优惠券；<br />
        <strong>4.</strong> 虚拟商品参与分佣。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  // ddd,
  methods: {},
};
</script>

<style lang="scss" scoped></style>
