var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"editormd"}},[_c('div',{staticClass:"right-body markdown-body editormd-html-preview"},[_vm._m(0),_c('p',[_vm._v(" 店铺活动页面仅展示该店铺创建的所有营销活动，包括秒杀活动、拼团活动、助力活动、预售活动等 ")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('p',[_c('strong',[_vm._v("位置")]),_vm._v("：移动端>店铺首页>活动"),_c('br'),_c('strong',[_vm._v("说明")]),_vm._v("："),_c('br'),_vm._v(" 1 . 店铺页底部 “专场”菜单，"),_c('br'),_vm._v(" 2 . “活动”页面会展示：秒杀、拼团、助力、预售等营销活动商品；"),_c('br'),_vm._v(" 3 . 活动商品展示顺序为：商户后台设置排序>创建时间"),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/20230915152552.png"),"alt":""}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"一、功能介绍"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("一、功能介绍 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"二、操作流程"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("二、操作流程 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("商户后台设置活动")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("注：商户后台添加各营销活动，平台后台审核通过后会展示在商城及店铺活动页。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"flex flex-col gap-3"},[_c('a',{attrs:{"href":"/76"}},[_vm._v(" 秒杀活动设置详情")]),_c('a',{attrs:{"href":"/88"}},[_vm._v(" 拼团活动设置详情")]),_c('a',{attrs:{"href":"/87"}},[_vm._v(" 助力活动设置详情")]),_c('a',{attrs:{"href":"/81"}},[_vm._v(" 全款预售")]),_c('a',{attrs:{"href":"/82"}},[_vm._v(" 定金预售")]),_c('a',{attrs:{"href":"/79"}},[_vm._v(" 直播设置详情")]),_c('a',{attrs:{"href":"/85"}},[_vm._v(" 专题活动设置详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("移动端活动展示位置")])])
}]

export { render, staticRenderFns }