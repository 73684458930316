<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="主播实名认证" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>主播实名认证</strong>
      </h2>
      <p>
        <strong
          >位置：<a href="https://live.weixin.qq.com/"
            >https://live.weixin.qq.com/</a
          ></strong
        ><br /><strong>方法：</strong>
      </p>
      <h3 id="h3--">
        <a
          name="第一步：点击上面链接扫码进入小程序直播后台"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >第一步：点击上面链接扫码进入小程序直播后台
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/screenshot_1614137637651.webp"
          alt=""
        />
      </p>
      <h3 id="h3--">
        <a name="第二步：添加直播成员" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >第二步：添加直播成员
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/screenshot_1614137100427.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/screenshot_1614137176633.webp"
          alt=""
        />
      </p>
      <h3 id="h3--">
        <a name="第三步：实名认证" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>第三步：实名认证
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/screenshot_1614137398222.webp"
          alt=""
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/screenshot_1614137445434.png"
          alt=""
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/screenshot_1614137465889.webp"
          alt=""
        /><br />
        <!-- <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/screenshot_1614137490316.webp"
          alt=""
        /><br /> -->
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/screenshot_1614137525131.webp"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
