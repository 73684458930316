<template>
  <div class="container mx-auto">
    <div class="common-layout h-screen">
      <el-container class="h-screen">
        <el-header
          class="flex justify-between items-center border-b backdrop-blur-md bg-transparent transition-colors"
        >
          <div class="">
            <svg
              width="24"
              height="24"
              class="hidden max-lg:block"
              @click="show = true"
            >
              <path
                d="M5 6h14M5 12h14M5 18h14"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
            </svg>
          </div>
          <div class="font-bold text-2xl max-lg:text-lg text-black">
            <span class="0">万千商城实训云操作手册</span>
            <span class="text-base ml-2">v3.01</span>
          </div>
          <div class=""></div>
        </el-header>

        <div
          :class="show ? 'block' : 'hidden '"
          @click.self="show = false"
          class="fixed h-screen w-full overflow-y-auto z-[999] bg-black/20 backdrop-blur-sm"
        >
          <nav class="bg-white h-full w-3/5 p-2 overflow-y-scroll">
            <div class="" v-if="0">
              <el-input
                size="small"
                placeholder="输入关键字进行过滤"
                v-model="filterText"
              >
              </el-input>
            </div>
            <el-tree
              class="filter-tree !text-black !text-xl w-full"
              :data="data"
              ref="tree"
              :props="defaultProps"
              @node-click="handleNodeClick"
              :filter-node-method="filterNode"
              highlight-current
              accordion
            />
          </nav>
        </div>

        <el-container class="" style="height: calc(100vh - 60px)">
          <el-aside
            width="300px"
            class="overflow-y-scroll max-lg:hidden p-2 relative"
          >
            <!--   default-expand-all -->
            <div class="p-3 sticky top-0">
              <el-input
                size="medium"
                placeholder="输入关键字进行过滤"
                v-model="filterText"
              >
              </el-input>
            </div>
            <!-- default-expand-all -->
            <!-- accordion -->
            <el-tree
              class="filter-tree !text-black !text-xl"
              :data="data"
              ref="tree"
              :props="defaultProps"
              @node-click="handleNodeClick"
              :filter-node-method="filterNode"
              highlight-current
              node-key="url"
          /></el-aside>
          <el-main v-if="1" class="">
            <router-view> </router-view>
          </el-main>
        </el-container>
      </el-container>
    </div>

    <!-- <div class="el-backtop" style="right: 40px; bottom: 40px">
      <i class="el-icon-caret-top"></i>
    </div> -->
  </div>
</template>

<script>
// import moduleName from "@/";
export default {
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
      },
      showScrollToTop: false,
      show: false,
      filterText: "",
      data: [
        {
          label: "序言",
          url: "/index",
        },
        {
          label: "安卓下载",
          url: "/app",
        },
        {
          label: "系统介绍",
          children: [
            {
              label: "前端脑图",
              url: "/1",
            },
            // {
            //   label: "项目介绍",
            // },
            {
              label: "功能清单",
              url: "/2",
            },
          ],
        },

        {
          label: "商品管理",
          children: [
            {
              label: "虚拟商品",
              url: "/3",
            },
            // {
            //   label: "卡密商品",
            //   url: "/4",
            // },
            {
              label: "商品分类",
              children: [
                {
                  label: "平台商品分类",
                  url: "/5",
                },
                {
                  label: "商户商品分类",
                  url: "/6",
                },
              ],
            },
            {
              label: "品牌分类",
              url: "/7",
            },
            {
              label: "商品标签",
              url: "/8",
            },
            {
              label: "发布商品",
              url: "/9",
            },
            {
              label: "商品规格",
              url: "/10",
            },
            // {
            //   label: "商品采集",
            //   url: "/11",
            // },
            {
              label: "保障服务",
              url: "/12",
            },
            {
              label: "移动端商品管理",
              children: [
                {
                  label: "开启移动端商品管理",
                  url: "/13",
                },
                {
                  label: "功能说明",
                  url: "/14",
                },
              ],
            },
            {
              label: "商品推荐",
              url: "/15",
            },
            {
              label: "商品口令",
              url: "/16",
            },
            {
              label: "商品参数",
              url: "/17",
            },
            {
              label: "价格说明",
              url: "/18",
            },
          ],
        },
        {
          label: "热销排行",
          url: "/19",
        },
        {
          label: "分销管理",
          children: [
            {
              label: "分销配置",
              url: "/20",
            },
            {
              label: "分销员管理",
              url: "/21",
            },
            {
              label: "分销规则说明",
              url: "/22",
            },
            {
              label: "分销员等级",
              url: "/23",
            },
            {
              label: "分销礼包",
              url: "/24",
            },
            {
              label: "佣金说明",
              url: "/25",
            },
            {
              label: "提现",
              children: [
                {
                  label: "提现规则",
                  url: "/26",
                },
                {
                  label: "提现步骤",
                  url: "/27",
                },
              ],
            },
            {
              label: "商家到零钱",
              url: "/28",
            },
          ],
        },
        {
          label: "用户管理",
          children: [
            {
              label: "用户反馈",
              url: "/29",
            },
            {
              label: "商户用户管理",
              url: "/30",
            },
            {
              label: "平台用户管理",
              url: "/31",
            },
            {
              label: "用户协议",
              url: "/32",
            },
            {
              label: "用户搜索记录",
              url: "/33",
            },
            {
              label: "账户管理",
              children: [
                {
                  label: "账号信息",
                  url: "/34",
                },
                {
                  label: "账户资金",
                  url: "/35",
                },
              ],
            },
          ],
        },
        {
          label: "订单管理",
          children: [
            {
              label: "后台订单",
              url: "/36",
            },
            {
              label: "移动端订单",
              url: "/37",
            },
            {
              label: "订单退款",
              url: "/38",
            },
            // {
            //   label: "订单打印",
            //   url:"/39"
            // },
            // {
            //   label: "电子面单v2",
            //   url:"/40"
            // },
          ],
        },
        {
          label: "客服管理",
          url: "/40",
          children: [
            {
              label: "商家客服",
              children: [
                {
                  label: "添加客服",
                  url: "/41",
                },
                {
                  label: "自动回复",
                  url: "/42",
                },
              ],
            },
            {
              label: "平台客服",
              children: [
                {
                  label: "开启客服",
                  url: "/43",
                },
                {
                  label: "自动回复",
                  url: "/44",
                },
              ],
            },
          ],
        },
        {
          label: "商户管理",
          url: "/45",
          children: [
            {
              label: "商户入驻",
              url: "/46",
            },
            {
              label: "商户列表",
              url: "/47",
            },
            // {
            //   label: "商户菜单管理",
            //   url: "/48",
            // },
          ],
        },
        {
          label: "财务管理",
          children: [
            {
              label: "财务对账",
              url: "/49",
            },
            {
              label: "发票管理",
              url: "/50",
            },
            // {
            //   label: "自动分账",
            //   url:"/51",
            // },
          ],
        },
        {
          label: "店铺管理",
          children: [
            {
              label: "开启店铺",
              url: "/52",
            },
            {
              label: "店铺信息",
              url: "/53",
            },
            {
              label: "店铺类型",
              url: "/54",
            },
            // {
            //   label: "店铺保证金",
            //   url:"/55",
            // },
            {
              label: "店铺配置",
              url: "/56",
            },
            {
              label: "店铺街",
              url: "/57",
            },
            {
              label: "店浦活动",
              url: "/58",
            },
            {
              label: "门店自提",
              children: [
                {
                  label: "开启门店自提",
                  url: "/59",
                },
                {
                  label: "设置核销权限",
                  url: "/60",
                },
                {
                  label: "订单核销说明",
                  url: "/61",
                },
              ],
            },
            {
              label: "店铺装修",
              url: "/62",
            },
          ],
        },
        // {
        //   label: "一号通",
        //   children: [
        //     // {
        //     //   label: "一号通注册",
        //     //   url:"/63",
        //     // },
        //     {
        //       label: "电子面单",
        //       url:"/64",
        //     },
        //     {
        //       label: "短信提醒",
        //       url:"/65",
        //     },
        //     {
        //       label: "商品采集",
        //       url:"/66",
        //     },
        //     {
        //       label: "物流查询",
        //       url:"/67",
        //     },
        //   ],
        // },
        {
          label: "社区功能",
          children: [
            {
              label: "社区分类",
              url: "/63",
            },
            {
              label: "社区话题",
              url: "/64",
            },
            {
              label: "社区内容",
              url: "/65",
            },
            {
              label: "社区评论",
              url: "/66",
            },
            {
              label: "社区配置",
              url: "/67",
            },
          ],
        },
        // {
        //   label: "小程序配置",
        //   children: [
        //     {
        //       label: "小程序下载并提交南核",
        //     },
        //     {
        //       label: "确认发布",
        //     },
        //     {
        //       label: "小程序直稻配置",
        //     },
        //   ],
        // },
        {
          label: "商城装修",
          children: [
            {
              label: "PC端首页",
              children: [
                {
                  label: "PC端开启",
                  url: "/68",
                },
                {
                  label: "PC端平台页面配置",
                  url: "/69",
                },
                {
                  label: "PC端店辅页面配置",
                  url: "/70",
                },
              ],
            },
            {
              label: "商城装修",
              url: "/71",
            },
            {
              label: "主题风格",
              url: "/72",
            },
            {
              label: "素材管理",
              url: "/73",
            },
            // {
            //   label: "页面设置",
            //   url:"/73",
            // },
            // {
            //   label: "自定义链接",
            //   url:"/74",
            // },
          ],
        },
        // {
        //   label: "同城配送",
        //   url:"/75",
        //   children: [
        //     {
        //       label: "账号注册",
        //       children: [
        //         {
        //           label: "达达",
        //         },
        //         {
        //           label: "UU",
        //         },
        //       ],
        //     },
        //     {
        //       label: "同城配送",
        //     },
        //   ],
        // },
        {
          label: "消息管理",
          children: [
            // {
            //   label: "模板消息",
            //   children: [
            //     {
            //       label: "公众号模板消息",
            //     },
            //     {
            //       label: "小程序订阅消息",
            //     },
            //   ],
            // },
            // {
            //   label: "消息管理",
            // },
            {
              label: "公告管理",
              url: "/74",
            },
            // {
            //   label: "阿里云短信",
            // },
            {
              label: "后台消息",
              url: "/75",
            },
          ],
        },
        {
          label: "营销活动",
          children: [
            {
              label: "秒杀",
              children: [
                {
                  label: "秒杀规则",
                  url: "/76",
                },
                {
                  label: "开启秒杀活动",
                  url: "/77",
                },
              ],
            },
            {
              label: "直播",
              children: [
                {
                  label: "主播实名认证",
                  url: "/78",
                },
                {
                  label: "开启直播",
                  url: "/79",
                },
                {
                  label: "添加直播商品",
                  url: "/80",
                },
              ],
            },
            {
              label: "预售",
              children: [
                {
                  label: "开启全款预售",
                  url: "/81",
                },
                {
                  label: "开启定金预售",
                  url: "/82",
                },
                {
                  label: "预售协议",
                  url: "/83",
                },
              ],
            },
            {
              label: "专题",

              children: [
                {
                  label: "专题活动",
                  url: "/84",
                },
                {
                  label: "后台配置",
                  url: "/85",
                },
                {
                  label: "前端展示",
                  url: "/851",
                },
              ],
            },
            {
              label: "助力",
              children: [
                {
                  label: "开启助力",
                  url: "/86",
                },
                {
                  label: "查看助力活动",
                  url: "/87",
                },
              ],
            },
            {
              label: "拼团",
              children: [
                {
                  label: "虚拟拼团设置",
                  url: "/88",
                },
                {
                  label: "开启拼团",
                  url: "/89",
                },
                {
                  label: "查看拼团活动",
                  url: "/90",
                },
              ],
            },
            {
              label: "积分",
              children: [
                {
                  label: "积分设置",
                  url: "/91",
                },
                {
                  label: "积分来源",
                  url: "/92",
                },
                // {
                //   label: "积分使用",
                //   url: "/93",
                // },
              ],
            },
            {
              label: "优惠券",
              children: [
                {
                  label: "商户优患券",
                  url: "/94",
                },
                {
                  label: "平台优惠券",
                  url: "/95",
                },
              ],
            },
            {
              label: "优惠套餐",

              children: [
                // {
                //   label: "优惠套餐",
                //   url: "/96",
                // },
                {
                  label: "商户后台",
                  url: "/97",
                },
                {
                  label: "平台后台",
                  url: "/98",
                },
              ],
            },
            {
              label: "活动氛围图",
              url: "/99",
            },
            {
              label: "活动边框图",
              url: "/100",
            },
          ],
        },
        {
          label: "商城设置",
          url: "/101",
          children: [
            {
              label: "储存管理",
              children: [
                {
                  label: "七牛云",
                  url: "/102",
                },
                {
                  label: "阿里云",
                  url: "/103",
                },
                {
                  label: "腾讯云",
                  url: "/104",
                },
                {
                  label: "华为云",
                  url: "/105",
                },
                {
                  label: "UC存储",
                  url: "/106",
                },
              ],
            },
            {
              label: "运费设置",

              children: [
                {
                  label: "运费设置",
                  url: "/107",
                },
                {
                  label: "运费组成",
                  url: "/108",
                },
                {
                  label: "运费模板 | 物流配置",
                  url: "/109",
                },
                // {
                //   label: "物流配置",
                //   url: "/110",
                // },
              ],
            },
            // {
            //   label: "小票打印",
            //   url:"/111",
            // },
            {
              label: "协议与规则",
              url: "/112",
            },
          ],
        },
        {
          label: "会员管理",
          children: [
            {
              label: "等级用户",
              children: [
                {
                  label: "等级管理",
                  url: "/120",
                },
                // {
                //   label: "等级权益",
                //   url:"/121",
                // },
                {
                  label: "等级配置",
                  url: "/121",
                },
              ],
            },
            {
              label: "付费会员",
              children: [
                {
                  label: "平台后台设置",
                  url: "/122",
                },
                {
                  label: "商户后台设置",
                  url: "/123",
                },
              ],
            },
          ],
        },
        // {
        //   label: "积分商城",
        //   children: [
        //     {
        //       label: "积分商城设置",
        //     },
        //     {
        //       label: "积分商品",
        //     },
        //     {
        //       label: "积分订单",
        //     },
        //   ],
        // },
      ],
    };
  },
  watch: {
    filterText(val) {
      // console.log("val==> ", val);
      this.$refs.tree.filter(val);
    },
  },
  mounted() {},

  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleNodeClick(e) {
      console.log("e==> ", e);
      if (!e.url) return;

      this.show = false;
      const currentPath = this.$route.path;
      if (currentPath !== e.url) {
        // 只有当目标路径与当前路径不同时，进行导航
        this.$router.push(this.addLeadingSlash(e.url));
      }
    },
    addLeadingSlash(url) {
      if (!url.startsWith("/")) {
        url = "/" + url;
      }
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-tree-node__label {
  font-size: 14px;
}
/deep/
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  @apply text-[#106DEE] bg-[#106DEE]/10   font-bold rounded-md;
}

/deep/ .el-tree-node__content:hover,
.el-upload-list__item:hover {
  @apply bg-[#106DEE]/5 rounded-md;
}

/deep/.el-main {
  @apply max-lg:p-1;
}
/deep/ .right-body {
  @apply max-lg:p-2;
}

/deep/ .el-tree-node__content {
  height: 40px;
}
</style>
