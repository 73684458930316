<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/20230918161744.mp4"
      controls
    ></video>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-">
          <a name="分销礼包" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>分销礼包</strong>
        </h2>
        <p>分销礼包是让普通用户购买后具有分销资格的商品。</p>
        <h3 id="h3-1-">
          <a
            name="1. 某商品设置分销礼包时在商户后台新添加商品时选择是否成为分销礼包；不能在编辑老商品时修改为分销礼包；"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span>1.
          某商品设置分销礼包时在商户后台新添加商品时选择是否成为分销礼包；不能在编辑老商品时修改为分销礼包；
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309121732574.png"
            alt=""
          />
        </p>
        <h3 id="h3-2-">
          <a
            name="2. 分销礼包设置后，移动端非分销员用户才会看到分销礼包商品，已成为分销员的用户看不到分销礼包商品；"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span>2.
          分销礼包设置后，移动端非分销员用户才会看到分销礼包商品，已成为分销员的用户看不到分销礼包商品；
        </h3>
        <h3 id="h3-3-">
          <a
            name="3. 每个商户可添加的分销礼包商品数量在平台后台设置；"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span>3.
          每个商户可添加的分销礼包商品数量在平台后台设置；
        </h3>
        <p>
          位置：平台后台&gt;分销&gt;分销配置<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121732446.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
