<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-252015-33-43-003.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="等级会员配置" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>等级会员配置</strong>
      </h2>
      <p><strong>位置：平台后台&gt;用户&gt;用户等级&gt;等级配置</strong></p>
      <h3
        id="h3-u914Du7F6Eu4F1Au5458u529Fu80FDu5F00u542Fu72B6u6001u53CAu83B7u5F97u6210u957Fu503Cu914Du7F6E"
      >
        <a
          name="配置会员功能开启状态及获得成长值配置"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >配置会员功能开启状态及获得成长值配置
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915144037.png"
          alt=""
        />
      </p>
      <h3 id="h3-u79FBu52A8u7AEFu5C55u793A">
        <a name="移动端展示" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>移动端展示
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309210942817.png"
          alt=""
        /><br />这里的数值为后台配置的数值
      </p>
      <h3 id="h3--strong-strong-">
        <a name="移动端入口" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>移动端入口</strong>
      </h3>
      <p>
        <strong>位置：平台后台&gt;维护&gt;组合数据</strong
        ><br />在组合数据中找到个人中心菜单，进入<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309251615664.png"
          alt=""
        /><br />这里可以编辑重新改为自己需要的名称<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309251616079.png"
          alt=""
        /><br />在移动端展示为<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309191116175.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
