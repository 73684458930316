<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/585c72540841484291ffd906595ace9f.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3-u540Eu53F0u914Du7F6Eu4E13u9898u6D3Bu52A8">
          <a name="后台配置专题活动" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          >后台配置专题活动
        </h3>
        <p>
          1.平台和商户都可以分别设置专题活动；<br />2.平台专题和商户专题各自独立，平台专题展示在商城首页专题区域；商户专题在店铺详情页右下角【专场】入口
        </p>
        <h3 id="h3-u6DFBu52A0u4E13u9898u6D3Bu52A8u65B9u6CD5">
          <a name="添加专题活动方法" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          >添加专题活动方法
        </h3>
        <p>
          平台后台<br />第一步：先创建平台商品标签<br />位置：平台后台&gt;
          商品&gt;商品标签<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915152948.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915152953.png"
            alt=""
          />
        </p>
        <p>
          第二步：给商品编辑第一步创建的标签<br />位置：平台后台&gt;商品&gt;商品列表<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915152959.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915153004.png"
            alt=""
          />
        </p>
        <p>
          第三步：创建专题，选择第一步创建好的标签。<br />位置：平台后台&gt;营销&gt;专题列表<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915153009.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915153014.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
