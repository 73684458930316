<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/f4af5a37ebd8da11d69fec1368b56160.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="开启全款预售" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>开启全款预售</strong>
        </h3>
        <p>
          <strong>全款预售</strong
          >：商户添加预售活动商品，平台后台审核，活动发布，用户在预售活动期间支付全款，商家按规定时间发货。<br /><strong
            >位置：商户后台&gt;营销&gt;预售&gt;添加预售商品</strong
          ><br />1.当该商品被设置为预售商品时，普通商品自动在移动端不显示，后台普通商品管理中也不显示，仅在预售商品列表中显示，当该预售商品预售活动结束或预售商品删除后，自动恢复为普通商品，商品默认显示在仓库中，状态处于下架状态，可手动修改为上架；
        </p>
        <ol>
          <li>
            在普通商品设置为预售商品时，已经购买该普通商品的订单不受影响；
          </li>
          <li>全款预售商品可以退款，走正常退货退款流程；</li>
          <li>
            设置商品预售活动时间：时间选择插件（时间插件，具体到年月日
            时分秒），预售时间必须大于当前时间；
          </li>
          <li>
            <p>
              设置发货时间：可选择设置支付结束后多少天/
              预售结束后多少天，单位：天，最小值1，最大值不限，大于等于1；支付结束：指全款支付完成后，以客户付款时间为依据发货；预售结束：以预售活动结束时间为依据统一安排发货；
            </p>
            <p>
              <img
                v-preview-image
                src="@/assets/downloads/20230915143127.png"
                alt=""
              /><br />
              <img
                v-preview-image
                src="@/assets/downloads/20230915143133.png"
                alt=""
              /><br />
              <img
                v-preview-image
                src="@/assets/downloads/20230915143138.png"
                alt=""
              />
            </p>
          </li>
          <li>
            <p>
              一个商品多种规格可分别设置预售价格；可设置单个规格预售，也可设置多个规格预售；
            </p>
          </li>
          <li>
            一个商品有多种规格，但只选1个规格或部分规格预售时，其他未选的规格也会在移动端界面显示，但不能购买；
          </li>
          <li>全款预售，可以到店自提；</li>
          <li>全款预售商品可参与分销返佣， 分佣设置与普通商品一样；</li>
          <li>预售一次可购买多个数量的预售商品，退款时全部退款；</li>
          <li>
            添加预售商品时可设置限量库存、价格等，预售未开始的可以进行修改，修改后需重新提交平台审核，预售进行中的商品不可以修改；
          </li>
          <li>预售活动可以使用优惠券；</li>
        </ol>
        <h3 id="h3--strong-strong-em-em-">
          <a name=" 审核/编辑全款预售" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong> </strong>审核/编辑全款预售
        </h3>
        <p><strong>位置：平台后台&gt;营销&gt;预售&gt;全款预售</strong></p>
        <ol>
          <li>
            平台后台审核全款预售商品<br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915143158.png"
              alt=""
            /><br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915143203.png"
              alt=""
            />
          </li>
          <li>
            点击“编辑” - 设置全款预售活动在移动端预售列表优先展示顺序<br />优先展示顺序：星级&gt;排序&gt;销量<br />规则：<br />（1）
            5星&gt;4星&gt;3星&gt;2星&gt;1星<br />（2） 排序是星级级别内的排序<br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915143209.png"
              alt=""
            /><br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915143255.png"
              alt=""
            />
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
