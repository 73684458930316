<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/c7bce125b7652f67968d8b24e818be62.mp4"
      controls
    ></video>

    <div id="editormd">
      <div id="editormd">
        <div class="right-body markdown-body editormd-html-preview">
          <h4 id="h4-u7D20u6750u7BA1u7406">
            <a name="素材管理" class="reference-link"></a
            ><span class="header-link octicon octicon-link"></span>素材管理
          </h4>
          <p>
            常用的图片可以上传至素材管理，除了数据保存预览功能，还可对附件进行管理，如附件的上传、改名、在线预览等功能
          </p>
          <p>位置：平台后台&gt;装修&gt;素材管理</p>
          <p>位置：商户后台&gt;设置&gt;素材管理</p>

          <p>
            <img
              v-preview-image
              src="@/assets/downloads/20230915095652.png"
              alt=""
            />
          </p>
          <h4 id="h4-u4E0Au4F20u56FEu7247">
            <a name="上传图片" class="reference-link"></a
            ><span class="header-link octicon octicon-link"></span>上传图片
          </h4>
          <h5 id="h5-1-">
            <a name="1. 本地上传" class="reference-link"></a
            ><span class="header-link octicon octicon-link"></span>1. 本地上传
          </h5>
          <p>
            <img
              v-preview-image
              src="@/assets/downloads/20230915095657.png"
              alt=""
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
