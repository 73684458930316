<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/20230918155423.mp4"
      controls
    ></video>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="分销员管理" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>分销员管理</strong>
        </h3>
        <hr />
        <h3 id="h3--strong-1-strong-gt-gt-">
          <a
            name="1.  位置：平台后台>分销 > 分销员列表"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>1. 位置</strong>：平台后台&gt;分销 &gt; 分销员列表
        </h3>
        <h3 id="h3--strong-2-2-strong-">
          <a name="2. 成为分销员2种方式" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>2. 成为分销员2种方式</strong>
        </h3>
        <p>
          （1）后台开启<br />
          位置： 平台后台>用户>用户列表>编辑<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121531479.png"
            alt=""
          /><br />
          （2）用户在移动端购买分销礼包商品，成为分销员（分销礼包商品设置见分销礼包或发布商品）
        </p>
        <h3 id="h3--strong-3-strong-">
          <a name="3.  分销员管理：" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>3. 分销员管理</strong>：
        </h3>

        <!-- ** 1.5.1 以前版本** -->

        <p>
          （1）查看分销员的推广金额<br />
          （2）查看推广订单<br />
          （3）查看分销员的推广人<br />
          （4）清除分销员的上级推广人<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121532774.png"
            alt=""
          />
        </p>
        <!-- <p><strong> 1.5.1 及以后版本</strong></p> -->
        <pre><code>  分销员人数：指已成为分销员的所有人数
  推广人数：指所有分销员推广的用户数
  推广订单：指所有通过分销绑定关系支付成功的订单数
  推广金额：指所有通过分销绑定关系支付成功的订单金额
  已提现金额：指用户产生的佣金，已提现的金额
  未提现金额：指用户产生的佣金，还未提现的金额

</code></pre>
        <h3 id="h3--strong-4-strong-">
          <a name="4. 手动修改分销关系绑定" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>4. 手动修改分销关系绑定</strong>
        </h3>
        <p>
          位置：平台后台&gt;用户&gt;用户列表&gt;更多<br />
          规则：用户A 先绑定用户 B , A创建订单 -》修改A 上级用户为 C -》用户 A
          收货 结果：用户B 的佣金返给 C<br /><strong> 修改推荐人 </strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121532151.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121533869.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121533584.png"
            alt=""
          /><br /><strong> 查看推荐人绑定记录</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121539406.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121535086.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
