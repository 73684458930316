<template>
  <div>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          商品采集，是通过第三方接口，在各综合商城平台采集商品信息，是快速添加商品的一种方式。
        </p>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <h4 id="h4-2-1-key">
          <a
            name="2.1 获取采集接口Key"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span>2.1
          获取采集接口Key
        </h4>
        <p>获取采集商品接口Key 有两种方式</p>
        <h4 id="h4--">
          <a
            name="第一种 直接使用 一号通"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span>第一种 直接使用
          一号通
        </h4>
        <p>
          第一步：注册一号通并购买
          <!-- <a href="https://doc.crmeb.com/mer/mer2/4399" one-link-mark="yes"
            >https://doc.crmeb.com/mer/mer2/4399</a
          > -->
        </p>
        <h4 id="h4--99api-99api-">
          <a
            name="第二种 自行注册99api接口 第二种 自行注册99api接口"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span>第二种
          自行注册99api接口 第二种 自行注册99api接口
        </h4>
        <p><strong> 付费采集密钥申请地址（注册即送10元）</strong></p>
        <p>
          <a
            href="https://www.99api.com/Login?log=5&amp;referee=1925"
            one-link-mark="yes"
            >https://www.99api.com/</a
          >
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/e0a25cf85e2a736c01e03ca42bc6107a_1920x969.png"
            alt=""
          />
        </p>
        <p><strong>注册完成点击—》我的数据接口</strong></p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/8f0222a09671164bd924ecaad2005a13_1920x969.png"
            alt=""
          />
        </p>
        <h3 id="h3-u6DFBu52A0u8FD9u4E94u4E2Au63A5u53E3">
          <a
            name="添加这五个接口"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span>添加这五个接口
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/ab47bf206d150926403221a6c0bec00e_1560x462.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-key-strong-">
          <a
            name=" 将key配置在后台"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong> 将key配置在后台</strong>
        </h3>
        <p>
          位置：平台后台&gt;设置&gt;第三方接口&gt;采集商品配置<br />注意：选择一号通方式，此处复制商品密钥不填写；如果选择自行注册99api,下图位置填写99API密钥。<br />
          <img
            v-preview-image
            src="@/assets/downloads/screenshot_1608173173317.png"
            alt=""
          />
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/90b9337cf13b8ee8756609d8a89e0f52.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a
            name="给商户添加采集条数"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>给商户添加采集条数</strong>
        </h3>
        <!-- <p>
          <img
            v-preview-image
            src="@/assets/downloads/20220524/9261105f79046c999d1bcc89e2a2a567.png"
            alt=""
          />
        </p> -->
        <h3 id="h3-2-2-">
          <a name="2.2、商品采集" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>2.2、商品采集
        </h3>
        <p>
          复制商品链接地址粘贴并点击确定<br />
          <img
            v-preview-image
            src="@/assets/downloads/55714bc82588e9f539f71f45fd1618a2.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/57fb13ba17980d8e230baaf8465f0390.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
