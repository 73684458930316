<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="客服订单提醒及订单处理：" class="reference-link"> </a
        ><span class="header-link octicon octicon-link"></span>
        <strong>客服订单提醒及订单处理：</strong>
      </h2>
      <h4 id="h4--">
        <a name="说明：" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>说明：
      </h4>
      <h4 id="h4--">
        <a
          name="小程序客服使用的是腾讯官方小程序客服系统，仅提供客服咨询"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >小程序客服使用的是腾讯官方小程序客服系统，仅提供客服咨询
      </h4>
      <p>
        <strong>1、客服权限管理</strong
        ><br />客服&gt;客服列表&gt;编辑：编辑客服权限<br /><strong
          >统计管理</strong
        >：<br />客服开启统计管理权限后，可以通过客服的个人中心统计管理查看订单统计，可以通过公众号个人中心进行订单改价、发货处理。<br /><strong
          >订单通知</strong
        >：<br />开启订单通知后，客服可以收到公众号订单模版消息通知，配置短息提醒后，会收到订单短信提醒。<br /><strong
          >客服状态</strong
        >：<br />开启、关闭客服状态<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309140936395.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
