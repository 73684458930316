<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/beffe17470ac1aac8737c45afcef7e11.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2-u5546u6237u540Eu53F0u67E5u770Bu62FCu56E2u6D3Bu52A8u60C5u51B5">
        <a name="商户后台查看拼团活动情况" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >商户后台查看拼团活动情况
      </h2>
      <p>
        <strong>位置：商户后台&gt;营销&gt;拼团&gt;拼团列表</strong><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309201802167.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309201802305.png"
          alt=""
        />
      </p>
      <h2 id="h2-u5E73u53F0u540Eu53F0u67E5u770Bu62FCu56E2u6D3Bu52A8u60C5u51B5">
        <a name="平台后台查看拼团活动情况" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >平台后台查看拼团活动情况
      </h2>
      <p>
        <strong>位置：平台后台&gt;营销&gt;拼团活动</strong><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309201802167.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309201802736.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
