<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309061702546.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          移动端商品管理主要是方便商家在移动端进行商品管理，比如快速上下架商品、或编辑商品信息。
        </p>
        <h3 id="h3--">
          <a name="二、操作步骤" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>二、操作步骤
        </h3>
        <p><strong>第一步 在添加客服中开启商品管理</strong></p>
        <p>
          位置：商后后台&gt;客服&gt;客服列表&gt;添加客服<br />说明：
          商户在客服管理中<strong>开启手机端管理</strong>即可允许客服管理商品
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309061704139.png"
            alt=""
          />
        </p>
        <p><strong>第二步：开启后客服的个人中心会展示”商家管理”入口</strong></p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309061704289.png"
            alt=""
          />
        </p>
        <p>
          <strong
            >第三步：
            如果一个客服关联了多个商户,可以在商品管理中切换店铺</strong
          >
        </p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309061704870.png"
            alt=""
          />
        </p>
        <!-- <p><strong>第四步：管理商品</strong></p> -->
        <!-- <p>
          详见功能说明：<a
            href="https://doc.crmeb.com/mer/mer2/4412"
            one-link-mark="yes"
            >https://doc.crmeb.com/mer/mer2/4412</a
          >
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
