<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-uclod-strong-">
        <a name="Uclod创建及使用" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>Uclod创建及使用</strong>
      </h2>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915155142.png"
          alt=""
        />
      </p>
      <hr />
      <h3 id="h3-1-uc-">
        <a name="1.注册UC    注册链接" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>1.注册UC
        <a
          href="https://passport.ucloud.cn/?invitation_code=C1x10179C78FE43"
          title="注册链接"
          >注册链接</a
        >
      </h3>
      <h3 id="h3-2-ucloud-">
        <a name="2.ucloud存储创建桶" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >2.ucloud存储创建桶
      </h3>
      <h4 id="h4--100-">
        <a
          name="注 ：需要账户余额大于100，才有创建的权限"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span>注
        ：需要账户余额大于100，才有创建的权限
      </h4>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/1507c3ee1214ba683174649176d3078b.webp"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/7289541d9c55d509b5fab87ae206f1b4.webp"
          alt=""
        />
      </p>
      <h3 id="h3-3-">
        <a name="3.创建完成后" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>3.创建完成后
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/b9337f7c19e5dea9d76c55f1c388248c.webp"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/7354d25a8418bd9d1fb4852439cff165.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/d81ec9c0c80350510ace0da1b83f2390.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
