import { render, staticRenderFns } from "./42.vue?vue&type=template&id=2959c1da&scoped=true"
import script from "./42.vue?vue&type=script&lang=js"
export * from "./42.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2959c1da",
  null
  
)

export default component.exports