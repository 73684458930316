<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/c1b7402e249a12d08af3d54d4490b9b8.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--strong-strong-">
        <a name="一、功能介绍" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>一、功能介绍</strong>
      </h3>
      <p>
        商城装修分为商城首页、个人中心、店铺街、商户首页，商城装修有两种方式，一种是普通装修，商城banner没有背景，
        DIY商城首页即可；
        一种是精装修，商城banner有背景，并且可自定义设置搜索框下的菜单和跳转内容，需设置微页面。
        <br />
        <!-- （备注：精装修功能是v2.0.3更新功能，v2.0.2及以前版本暂无此功能） -->
      </p>
      <h3 id="h3--">
        <a name="二、操作流程" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h3>
      <p>
        <strong
          ><em
            >操作前必读：默认模板可以先复制，复制后进行编辑，编辑后设为首页，才会在移动商城生效可见。</em
          ></strong
        >
      </p>
      <h4 id="h4-u9996u9875u88C5u4FEE">
        <a name="首页装修" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>首页装修
      </h4>
      <h5 id="h5-1-">
        <a name="1. 普通装修" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>1. 普通装修
      </h5>
      <p>
        位置：平台后台&gt;装修&gt;页面装修<br />
        说明：商城首页可进行DIY设计，自由搭建首页模块及样式；可创建多个页面保存为模板，方便更换。
      </p>
      <p>
        操作方法<br />
        ① 添加组件<br />
        点击左侧组件库内模块，即可自动添加相应模块至页面最底部<br />
        拖动左侧组件库内模块，至中间预览页，即可在相应位置添加模块<br />
        ② 编辑模块<br />
        点击中间预览页模块，最右侧则会弹出相应编辑内容说明，可进行颜色、间距、数值、等相应的编辑调整<br />
        ③ 提交保存
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915093329.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915093337.png"
          alt=""
        />
      </p>
      <h5 id="h5-2-">
        <a name="2. 精装修" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>2. 精装修
      </h5>
      <p>
        <strong>第一步：添加微页面，设置微页面名称</strong
        ><br />位置：平台后台&gt;装修&gt;微页面
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915093351.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915093357.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915093405.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915093413.png"
          alt=""
        />
      </p>
      <p>
        <strong
          >第二步：在页面装修，通过组合组件，添加banner，关联微页面</strong
        >
      </p>
      <p>位置：平台后台&gt;装修</p>
      <p><strong>选择组合组件</strong></p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915093441.png"
          alt=""
        />
      </p>
      <p><strong>设置首页logo 与搜索热词</strong></p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915093447.png"
          alt=""
        />
      </p>
      <p>
        <strong>自定义菜单名称、添加切换banner、通过链接关联微页面</strong
        ><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915093453.png"
          alt=""
        />
      </p>
      <p>
        <strong>设置首页banner</strong><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915093500.png"
          alt=""
        />
      </p>
      <p><strong>第三步：保存模板并设置为首页</strong></p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915093842.png"
          alt=""
        />
      </p>
      <p><strong>第四步：移动端效果预览</strong></p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915093524.png"
          alt=""
          class="w-1/3"
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/20230915093529.png"
          alt=""
        />
      </p>
      <h4 id="h4--strong-strong-">
        <a name="店铺街" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>店铺街</strong>
      </h4>
      <p>
        位置：平台后台&gt;装修&gt;店铺街<br />说明：<br />
        （1）店铺街共有3种不同样式可选择，同时可根据运营业务选择是否开启店铺街距离展示；<br />
        （2）店铺街背景图，需要在商户后台&gt;设置&gt;商户信息&gt;店铺信息
        页面中上传<br />
        （2）选择样式1或2时，店铺街背景图格式为：710 x
        134px；选择样式3时，店铺街背景图格式为：710 x 460px；<br />
        建议平台或商家提前准备图片素材，以保证商城展示效果。
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915093612.png"
          alt=""
        />
      </p>
      <h4 id="h4--strong-strong-">
        <a name="个人中心" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>个人中心</strong>
      </h4>
      <p>
        位置：平台后台&gt;装修&gt;个人中心<br />说明：<br />
        （1）可在此处编辑 个人中心
        “”banner“”，选中该模块后，可更换图片，添加或修改图片的链接；<br />
        （2）可在此处编辑个人中心“我的服务”中的菜单名称、图表、链接、菜单排列顺序。<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915093625.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
