<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-212015-37-26-925.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--">
        <a name="一、功能介绍" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h3>
      <p>
        店铺装修是为满足商城各入驻子商户按照自己经营品类的特点，装修搭建不同风格的店铺首页，突显店铺个性，从而使商城总体更丰富，增加用户购买欲望。
      </p>
      <h3 id="h3--">
        <a name="二、操作流程" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h3>
      <p>
        <strong>位置</strong>：商户后台&gt;页面装修<br /><strong>说明：</strong
        ><br />
        1.店铺【首页】默认展示 “精选”菜单，需要商家按如下步骤自行装修；“新品”
        按创建时间，最新创建的商品展示在最上面；‘活动“默认展示该店铺创建的所有活动商品；<br />
        底部【商品】菜单默认展示店铺所有商品列表；<br />
        底部【分类】默认展示商户商品分类；<br />
        底部【专场】默认展示店铺设置的专场活动。<br />
        2.当默认模板，没有编辑按钮时，请先点击【复制】，在复制的模板上点击【编辑】，设置想要的店铺首页；<br />
        3.拖动组件至编辑区域进行设置
      </p>
      <p>
        <strong>步骤</strong>：点击【编辑】—
        在可编辑区域进行图片、名称、链接、样式调整及设置 — 点击【保存】—
        设为首页
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309201531698.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309141657894.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309141657350.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309141657134.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309141657111.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309141657181.png"
          alt=""
        />
      </p>
      <p><strong>移动端店铺首页展示效果</strong></p>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309201544101.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
