<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--strong-strong-">
        <a name="单品运费模板" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>单品运费模板</strong>
      </h3>
      <hr />
      <ol>
        <li>
          计费方式分为：按件数计费、按重量计费、按体积计费【添加商品需设置商品的体积或重量】
        </li>
        <li>指定区域配送：添加区域后需填写首件商品运费及续件商品的运费</li>
        <li>指定条件包邮：设置指定地点，满足（X件/kg/m³+金额）即可包邮</li>
      </ol>
      <h3 id="h3--strong-strong-">
        <a name="运费计算方式：" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>运费计算方式：</strong>
      </h3>
      <hr />
      <h4 id="h4--strong-1-strong-">
        <a name="1、订单中的商品，使用同一运费模板" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>1、订单中的商品，使用同一运费模板</strong>
      </h4>
      <p>
        <strong>计算逻辑：</strong>
        订单中同一运费模板的商品，第二件商品按照续件计算运费；不同的商品若运费模板相同，也统一计算运费<br /><strong
          >举例：</strong
        ><br />商品A和商品B使用同一运费模板O（首件10元，续件3件，续件5元）<br />商品A（2件），<br />商品B（1件），<br />运费计算方式为：10
        +[（ 2 + 1 - 1）/ 3]x 5 = 10 +（2/3）x 5= 10 + 1 x 5= 15元
      </p>
      <h4 id="h4--strong-2-strong-">
        <a
          name="2、订单中不同的商品，使用不同的运费模板"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>2、订单中不同的商品，使用不同的运费模板</strong>
      </h4>
      <p>
        <strong>计算逻辑：</strong>
        订单中首件/首重/首体积商品，使用所有模板中金额最高的运费模板；其他商品采用各自的运费模板计算续件/续重/续体积<br /><strong
          >举例：</strong
        ><br />商品A（1件）使用模板O（首件10元，续件1件，续件5元），<br />商品B（2kg）使用模板P（首重2kg，9元，续重2kg，4元），<br />商品C（2m³）使用模板Q（首体积2m³，8元，续体积2m³，3元）；<br />订单中有商品A一件，商品B两件，商品C两件，则运费计算方式为：<br />10
        +（4 x 2）+（3 x 2）= 24元<br />当不同运费模板的首件/重/体积的费用相同时，这几种运费模板均计算之后取运费的最大值
      </p>
      <h4 id="h4--strong-3-strong-">
        <a name="3、指定条件包邮" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>3、指定条件包邮</strong>
      </h4>
      <p>
        <strong>计算逻辑：</strong>
        满足指定地点并且满足指定条件后，同一运费模板的商品免邮；其他运费模板的商品继续依据上面1、2的规则计算运费<br /><strong
          >举例：</strong
        ><br />商品A（1件，单价100元）使用模板O（首件10元，续件1件，续件5元），<br />商品B（2件，单价50元）也使用模板O，<br />商品C（2kg，单价30元）使用模板P（首重2kg，9元，续重2kg，4元），<br />模板O设置了指定条件包邮，商品指定地点为浙江，商品数量大过2个，商品金额大于150元即可包邮；<br />此商品的订单地址为浙江，商品A和商品B使用同一模板O，商品数量之和为3&gt;2，商品金额为（100
        x 1）+（50 x 2）=
        200&gt;150元，满足指定包邮条件，则商品A和商品B包邮；<br />商品C单独计算运费，运费金额为：9
        x 1 = 9元；<br />订单运费金额为：0 + （0x2）+（9 x 1）= 9元。
      </p>
      <h4 id="h4--strong-4-strong-">
        <a name="4、商品如何按重量计费" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>4、商品如何按重量计费</strong>
      </h4>
      <p>
        <strong>计算逻辑：</strong>
        商品的运费不再以件数为单位计费，而是以kg为单位计费<br /><strong
          >举例：</strong
        ><br />商品A（2kg，4件）使用模板P（首重2kg，9元，续重3kg，4元），<br />商品B（3kg，5件）使用模板P，<br />运费计算：9
        + {[（2x4）+（3x5）- 2 ]/ 3 x 4} = 9 +(21/3 x 4) = 9+28 = 37元<br />说明：商品重量不足续重的规格时，按照续重规格计算运费
      </p>
      <h4 id="h4--strong-5-strong-">
        <a name="5、指定不配送" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>5、指定不配送</strong>
      </h4>
      <p>
        <strong>计算逻辑：</strong>
        使用此运费模板的商品，在指定不配送的地区不能下单；此功能为最高优先级，指定不配送
        &gt; 指定包邮 &gt; 指定区域配送
      </p>
      <h4 id="h4--strong-strong-">
        <a name="商品价格定义" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>商品价格定义</strong>
      </h4>
      <p>商品价格为用户下单时商品的价格</p>
      <h3 id="h3--strong-strong-">
        <a name="默认模版" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>默认模版</strong>
      </h3>
      <hr />
      <p>
        商城会默认一个基础模板，该模板不可删除，可修改；当商品的运费模板被删除时，会自动使用此模板
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
