<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="商户菜单管理" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>商户菜单管理</strong>
      </h2>
      <p>
        <strong>位置：平台后台&gt;商户&gt;商户菜单管理</strong><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141043780.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141043772.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
