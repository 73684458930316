<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/20230918154419.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--">
        <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h3>
      <p>
        价格说明是平台型商城系统为规范价格展示，在商品详情展示的向用户说明价格相关规定的信息。价格说明不仅可以用来展示价格说明，也可以关联商品分类设置不同的服务承诺。
      </p>
      <h3 id="h3--">
        <a name="二、操作流程" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h3>
      <p>
        位置：平台后台&gt;商品&gt;价格说明<br />说明：<br />（1）每设置一个新的价格说明，选择显示，同一使用分类范围会覆盖之前设置，在排序一致的情况下以创建时间为先后顺序的依据；<br />（2）不同使用分类范围都显示的情况下，各显示各的范围；<br />（3）A价格说明分类范围包括B价格说明分类范围时，A先创建，B后创建，在排序一致并且都显示的情况下，B价格说明只展示在B分类下的所有商品；其它分类商品展示A价格说明。
      </p>
      <p>平台后台添加价格说明</p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309121514499.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309121515408.png"
          alt=""
        />
      </p>
      <p>
        PC端商城展示价格说明<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309121516408.png"
          alt=""
        />
      </p>
      <p>
        移动端商城展示价格说明<br />
        <img
          class="w-1/3"
          v-preview-image
          src="@/assets/downloads/202309121516320.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
