<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309210933089.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="用户搜索记录" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>用户搜索记录</strong>
      </h2>
      <p>
        位置：平台后台&gt;用户&gt;搜索记录<br />说明：平台后台用户搜索记录可以导出<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309131617389.png"
          alt=""
        /><br />位置：商户后台&gt;用户&gt;搜索记录<br />说明：商户后台用户搜索记录不能导出<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309131617909.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
