<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/837b41b37cbbe7c1a75cd361c0ad5268.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--">
        <a name="一、功能介绍" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h2>
      <p>
        <strong>1 . 系统设置</strong><br />
        系统设置是商城运营需要配置的一些基础信息，在平台后台设置；包括系统信息、后台设置、移动端设置。
      </p>
      <p>
        <strong>系统信息</strong
        >包括：网站开启、网站域名、网站名称、平台客服电话、备案号展示开关；
      </p>
      <p>
        <strong>后台设置</strong
        >包括：后台顶部菜单logo、后台左侧缩进小logo、后台登录页logo、后台登录页左侧轮播图、后台登录页标题、后台页面底部版本号展示开关；
      </p>
      <p>
        <strong>移动端设置</strong
        >包括：商城logo、商城分享标题、商城分享简介、商城分享图片、移动端登录logo、强制用户绑定手机号登录开关、用户修改头像和昵称开关。
      </p>
      <p><strong>2 . 商城设置</strong></p>
      <p>
        商城设置是在平台后台设置与商城整体运营相关的信息，包括商品设置、交易设置、商户设置、商城设置。
      </p>
      <p>
        <strong>商品设置</strong
        >包括：商品复制口令自动解析开关、到货通知开关、商品评论开关、自动好评开关；
      </p>
      <p>
        <strong>交易设置</strong
        >包括：订单自动关闭时间、订单自动收货时间、订单售后时间、商户自动处理退款订单期限、退货期限；
      </p>
      <p><strong>商户设置</strong>包括：商户入驻开关、商户入驻资质是否必传；</p>
      <p>
        <strong>商城设置</strong
        >包括：是否展示店铺、商城首页商品推荐方式、直播免审核开关、直播商品免审核开关。
      </p>
      <h2 id="h2--">
        <a name="二、操作流程" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h2>
      <p>
        <strong>1 . 系统设置</strong
        ><br />位置：平台后台&gt;设置&gt;系统设置<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915095228.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915095231.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915095234.png"
          alt=""
        />
      </p>
      <p><strong>2 . 商城设置</strong></p>
      <p>
        位置：平台后台&gt;设置&gt;商城设置<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915095238.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915095241.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915095245.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915095248.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
