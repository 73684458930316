<template>
  <div>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          店铺保证金，是平台型综合商城必不可少的基础功能，包括店铺保证金的缴纳、扣费、申请退回、操作记录等，万千实训系统多商户商城系统的保证金
          还包括保证金不足额时的提醒、提醒时长的设置；包括线上线下分别均可支付及退回保证金。可以灵活适用多种场景的应用。
        </p>
        <h3 id="h3--">
          <a name="二、操作步骤" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作步骤
        </h3>
        <p><strong>第一步：店铺保证金的设置</strong></p>
        <p>
          位置：平台后台&gt;商户&gt;店铺管理&gt;店铺类型<br />说明：店铺保证金是根据店铺类型不同设置不同额度的<br />具体设置方法详见：<a
            href="https://doc.crmeb.com/mer/mer2/4369"
            >https://doc.crmeb.com/mer/mer2/4369</a
          >
        </p>

        <!-- <p>
            <strong> 第二步：店铺保证金补缴提醒设置</strong></p>
        <p>说明：该功能v2.2 开始有<br> <img
          v-preview-image
            src="/uploads/mer/mer2/20230610/daafd55fc4b440ffedee57b506784fd5.png"
            alt=""
          ></p>
        <p>
            <strong>第三步：店铺保证金线下付款</strong></p> -->

        <!-- <h3 id="h3--v2-2-"><a
            name="三、功能说明（v2.2）"
            class="reference-link"
          ></a><span class="header-link octicon octicon-link"></span>
          三、功能说明（v2.2）</h3>
        <p>1.店铺保证金补缴提醒关闭：保证金不足时不提醒；<br>2.店铺保证金补缴提醒开启，并设置天数，比如:填写30天，即自保证金不足日开始计算连续提醒商户补缴保证金30天，如果期间商户还未补足保证金，30天满后自动关闭店铺。<br>3.设置为0 天，保证金不足当天提醒，即当天关闭店铺； 默认显示为1天。<br>4.商户后台提醒补足保证金，首次登录有补缴提醒及弹窗（并可通过弹窗去缴费界面），进入后顶部有警示提醒。<br>5.店铺保证金包括3个列表：待缴保证金列表、已缴保证金列表、退回保证金列表<br> （1）待缴保证金列表：包括需全额支付保证金的店铺、待补缴保证金的店铺；<br> （2）已缴保证金列表：包括已全额支付保证金的店铺、已补缴至足额保证金的店铺、已全额支付后被扣为不足额保证金的店铺；<br> （3）退回保证金列表：包括申请退回通过的店铺、申请退回未通过的店铺；<br>6.增加保证金线下付款：点击【线下付款】，选择已缴后，该信息状态从待缴变成已缴，自动到已缴保证金列表，备注信息也自动同步到缴存保证金列表<br>7.保证金扣费记录更新为：操作记录：包括保证金扣费记录、补缴记录、申请退回通过、申请退回审核未通过及原因；<br>8.保证金退回，包括三种情况：全部线上支付的退回、 线上线下混合支付的退回、全部线下支付的退回；<br> （1）全部线上支付的 全部线上退回<br> （2）线上线下混合支付的，商家发起退回保证金申请后，分出线上和线下两个退回申请，线上的通过线上退回，线下的通过线下审核确认退回；<br> （3）全部线下支付的， 全部线下退回</p>
      --></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
