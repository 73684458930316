<template>
  <div id="editormd">
    <div class="right-body markdown-body editormd-html-preview">
      <h3 id="h3--strong-crmeb-strong-">
        <a name="万千实训系统分销说明" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>万千实训系统分销说明</strong>
      </h3>
      <hr />
      <h3 id="h3--strong-1-crmeb-strong-">
        <a name="1、万千实训系统分销模式" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>1、万千实训系统分销模式</strong>
      </h3>
      <p><strong>分销模式：</strong> 指定分销</p>
      <p>
        <strong>指定分销：</strong>
        用户默认无分销权限，需要后台开通分销权限或在商城购买分销礼包商品后，才可以通过推广下级获得返佣；
      </p>
      <h3 id="h3--strong-2-strong-">
        <a name="2、分销等级" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>2、分销等级</strong>
      </h3>
      <p>
        万千实训系统分销为二级分销；分销固定两级，后台不能自己设置分销等级。
      </p>
      <hr />
      <h3 id="h3--strong-3-strong-">
        <a name="3、返佣规则" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>3、返佣规则</strong>
      </h3>
      <p>
        A推广B，B推广了C；<br />A购买商品后，自己没有返佣；<br />B购买商品后，A获得一级返佣；<br />C购买了商品，B获得一级返佣，A获得二级返佣<br />
        <img
          v-preview-image
          src="@/assets/downloads/636ee80a5e1ffd4798410ed603e660d2_546x540.png"
          alt=""
        /><br /><strong>注：不返佣金的情况</strong
        ><br />（1）用户没有推广员身份（指定分销情况下）<br />（2）产品售价为0时，不返佣<br />（3）下级用户购买拼团、砍价、秒杀商品时，没有返佣<br />（4）下级用户购买商品后，没有确认收货时，暂不返佣；确认收货之后才会返佣
      </p>
      <hr />
      <h3 id="h3--strong-4-strong-">
        <a name="4、商品返佣" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>4、商品返佣</strong>
      </h3>
      <ul>
        <li>商品返佣分为 “默认返佣“ 和 “商品单独返佣“</li>
        <li>
          <strong>默认返佣</strong
          ><br />商城统一设置商品的返佣比例<br />一级佣金 = 实付金额 *
          一级返佣比例<br />二级佣金 = 实付金额 * 二级返佣比例
        </li>
        <li>
          <strong>商品单独返佣</strong
          ><br />（1）每个商品可选择单独设置返佣<br />（2）每个商品规格设置一二级返佣<br />（3）单独设置后，商品的返佣以固定佣金为准，不受其他因素影响<br />（4）即使商品售价为0，用户购买该商品上级仍会收到固定金额的返佣
        </li>
      </ul>
      <hr />
      <h3 id="h3--strong-5-strong-">
        <a name="5、佣金冻结" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>5、佣金冻结</strong>
      </h3>
      <ul>
        <li>
          佣金新增冻结期限，后台可设置冻结期，冻结的佣金不可提现，达到解冻时间后方可提现
        </li>
        <li>冻结期从用户获得返佣时（确认收货）开始计算</li>
        <li>冻结期设置为0时，则为无冻结期；冻结时间单位为 “天“</li>
      </ul>
      <hr />
      <h3 id="h3--strong-6-strong-">
        <a name="6、绑定推广关系" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>6、绑定推广关系</strong>
      </h3>
      <ul>
        <li>
          公众号、小程序：商品详情页（分享链接方式），产品海报（二维码分享），分销推广海报（二维码分享）；
        </li>
        <li>H5商城：产品海报，分销推广海报；</li>
        <li>
          说明：用户通过这些方式进入商城后，必须授权或者注册（H5）之后才会绑定关系
        </li>
        <li>
          当用户已有上级、用户进入商城未授权或注册、用户已经是推广员（指定分销模式），不能绑定推广关系
        </li>
        <li>用户之前访问过商城，但没有上级时，可以绑定推广关系</li>
      </ul>
      <hr />
      <h3 id="h3--strong-7-strong-">
        <a name="7、解除推广关系" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>7、解除推广关系</strong>
      </h3>
      <p>
        后台分销员管理可解除用户的上级推广员，推广关系解除之后，推广员之前记录的推广订单依然保留，之后下级消费的订单因推广关系已解除所以不再记录
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
