<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/dc71dffb5f0ec42d413e24047e0ecc6a.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--strong-strong-">
        <a name="平台向商户发布公告" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>平台向商户发布公告</strong>
      </h3>
      <p>
        <strong>位置：平台后台&gt;设置&gt;消息管理&gt;公告管理</strong
        ><br />平台向商户发布通知公告，填写通知公告标题，填写通知公告内容（限500字）；<br />可根据商户名称、商户类别（自营与非自营）、商户分类（比如
        数码电器类/服饰类）选择不同的发送对象。<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915100523.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915100528.png"
          alt=""
        />
      </p>
      <h3 id="h3--strong-strong-">
        <a name="商户后台收到通知" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>商户后台收到通知</strong>
      </h3>
      <p>
        <strong>位置：商户后台&gt;公告列表</strong><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915100550.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915100555.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
