<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-pc-strong-">
        <a name=" PC端开启" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong> PC端开启</strong>
      </h2>
      <p>
        <strong>位置：平台后台&gt;设置&gt;PC端配置&gt;基础配置</strong><br />
        关闭：指不开启PC端；<br />
        自适应：指根据用户打开方式，自动匹配显示<br />
        强制开启：指开启PC端后，只能在PC端打开<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141739894.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
