<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/0669d158b737ebe7ac20e5ad535153c3.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--">
        <a name="一、功能介绍" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h2>
      <p>
        活动氛围图是添加在商品详情主图上的一种渲染活动气氛与效果的图，是运营的好工具，在平台后台设置，移动端和PC端商品详情页商品主图展示效果。<br />

        <!-- （注：此功能为v2.0.3功能，v2.0.2及之前版本无此功能） -->
      </p>
      <h2 id="h2--">
        <a name="二、操作流程" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h2>
      <p>
        第一步：添加活动氛围图<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915120050.png"
          alt=""
        />
      </p>
      <p>
        第二步：设置活动名称、氛围图展示时间、添加氛围图<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915120050_1.png"
          alt=""
        />
      </p>
      <p>
        第三步：选择需要展示氛围图的商品<br />说明：可选择全部商品、可指定选择某个商品、可按商品分类选择、可选择指定商户参与
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915120050_2.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
