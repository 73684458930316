<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam 2023-09-21 14-18-57-235.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          店铺类型是多商户商城系统特有的功能，万千实训系统
          多商户商城是一个商户对应一个店铺的模式，设置店铺类型，并根据不同店铺类型，设置店铺保证金与店铺权限，方便平台针对不同类店铺多样化运营。
        </p>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <p>
          <strong>第一步</strong
          >：平台后台设置店铺类型、店铺保证金、店铺权限<br />位置:
          平台后台&gt;商户&gt;店铺管理&gt;店铺类型
        </p>
        <p>
          <strong
            >重要提醒！！！ ——
            添加店铺类型时，店铺权限必须勾选，否则登录子商户会提醒无权限访问</strong
          >
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141144641.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141144759.png"
            alt=""
          />
        </p>

        <p>
          <strong>第二步</strong
          >：商户后台支付保证金并开启店铺<br />位置：商户后台&gt;设置&gt;商户信息<br />店铺类型如果是有保证金的情况，商户要开启店铺，必须先支付保证金<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141145056.png"
            alt=""
          /><br /><strong><br />第三步</strong
          >：平台后台查看缴存记录、扣费记录<br />位置：平台后台&gt;商户&gt;店铺管理&gt;店铺保证金&gt;缴存保证金<br />说明：可查看保证金支付状态、执行保证金扣费、查看保证金扣费记录<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141349402.png"
            alt=""
          />
        </p>
        <p>
          <strong>第四步</strong
          >：商户后台申请退回保证金<br />位置：商户后台&gt;设置&gt;商户信息<br />说明：可申请保证金退回，可查看保证金扣费记录；申请退回后，可退保证金金额=原支付保证金-扣费保证金<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141349391.png"
            alt=""
          />
        </p>
        <p>
          <strong>第五步</strong
          >：平台后台审核退回保证金<br />位置：平台后台&gt;商户&gt;店铺管理&gt;店铺保证金&gt;退回保证金<br />说明：<br />(1)
          可审核保证金的退回申请，通过：短信提醒，保证金余额自动原路返回；未通过：短信提醒，查看退回原因，商户可再次申请。<br />(2）保证金退回，店铺自动关闭，商城移动端不展示店铺及店铺商品。
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141349525.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141349397.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="三、功能说明" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>三、功能说明</strong>
        </h3>
        <p>
          <strong>1. </strong
          >平台后台可自定义店铺类型：比如旗舰店、工厂店、直营店、专卖店等；<br /><strong
            >2.
          </strong>
          可根据店铺类型设置店铺保证金有或无，及保证金金额；也可编辑修改店铺保证金金额；修改后，之后入驻的店铺类型及保证金金额按新修改的展示；<br /><strong
            >3. </strong
          >可根据店铺类型设置店铺权限；也可修改店铺权限，修改后，该类型的店铺比如普通店，仅设置基础菜单权限；旗舰店，可设置全部菜单权限；<br /><strong
            >（重要提醒：店铺权限必须勾选，如不勾选会导致子商户登录提醒无权限访问）</strong
          ><br /><strong>4</strong>
          店铺类型标签在移动端展示规则：<br />（1）当商户选择了店铺类型并同时开启自营店铺时，在移动端店铺和商品列表优先展示
          店铺类型标签；当商户开启自营没有选择店铺类型时，默认展示自营标签；当商户没有选择店铺类型，也没有开启自营时，默认不显示任何标签。<br />（2）商品列表标签展示顺序：优先显示活动标签&gt;店铺类型&gt;自营
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
