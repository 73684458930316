var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video',{staticClass:"aspect-video",attrs:{"poster":require("@/assets/downloads/202309210927719.png"),"src":require("@/assets/downloads/837b41b37cbbe7c1a75cd361c0ad5268.mp4"),"controls":""}}),_c('div',{staticClass:"markdown-body editormd-html-preview",attrs:{"id":"editormd"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('p',[_vm._v(" 商城设置是在平台后台设置与商城整体运营相关的信息，包括商品设置、交易设置、商户设置、商城设置。 ")]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_c('p',[_c('strong',[_vm._v("1 . 系统设置")]),_c('br'),_vm._v("位置：平台后台>设置>系统设置"),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/20230915095228.png"),"alt":""}})]),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/20230915095231.png"),"alt":""}})]),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/20230915095234.png"),"alt":""}})]),_vm._m(11),_c('p',[_vm._v(" 位置：平台后台>设置>商城设置"),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/20230915095238.png"),"alt":""}})]),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/20230915095241.png"),"alt":""}})]),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/20230915095245.png"),"alt":""}})]),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/20230915095248.png"),"alt":""}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"一、功能介绍"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("一、功能介绍 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("1 . 系统设置")]),_c('br'),_vm._v(" 系统设置是商城运营需要配置的一些基础信息，在平台后台设置；包括系统信息、后台设置、移动端设置。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("系统信息")]),_vm._v("包括：网站开启、网站域名、网站名称、平台客服电话、备案号展示开关； ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("后台设置")]),_vm._v("包括：后台顶部菜单logo、后台左侧缩进小logo、后台登录页logo、后台登录页左侧轮播图、后台登录页标题、后台页面底部版本号展示开关； ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("移动端设置")]),_vm._v("包括：商城logo、商城分享标题、商城分享简介、商城分享图片、移动端登录logo、强制用户绑定手机号登录开关、用户修改头像和昵称开关。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("2 . 商城设置")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("商品设置")]),_vm._v("包括：商品复制口令自动解析开关、到货通知开关、商品评论开关、自动好评开关； ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("交易设置")]),_vm._v("包括：订单自动关闭时间、订单自动收货时间、订单售后时间、商户自动处理退款订单期限、退货期限； ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("商户设置")]),_vm._v("包括：商户入驻开关、商户入驻资质是否必传；")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("商城设置")]),_vm._v("包括：是否展示店铺、商城首页商品推荐方式、直播免审核开关、直播商品免审核开关。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"二、操作流程"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("二、操作流程 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("2 . 商城设置")])])
}]

export { render, staticRenderFns }