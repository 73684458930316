<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam 2023-09-25 15-12-05-437.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--strong-strong-">
        <a name="物流公司修改" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>物流公司修改</strong>
      </h3>
      <hr />
      <p>
        平台后台&gt;设置 &gt; 配送配置&gt;物流公司<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915160721.png"
          alt=""
        />
      </p>
      <h3 id="h3--strong-strong-">
        <a name="运费模板" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>运费模板</strong>
      </h3>
      <hr />
      <p>
        商户后台&gt;设置 &gt; 运费模板<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915160726.png"
          alt=""
        />
      </p>
      <h4 id="h4--strong-strong-">
        <a name="添加运费模板" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>添加运费模板</strong>
      </h4>
      <ul>
        <li>填写运费说明：此处描述会展示在移动端</li>
        <li>可按 件数/重量/体积添加模板</li>
        <li>
          配送区域默认全国，可单独添加配送区域<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915160731.png"
            alt=""
          /><br />移动端展示样式<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/20230915160735.png"
            alt=""
          /><br />指定包邮：指定包邮需单独添加配送区域<br />同时满足包邮件数和包邮金额，才能触发指定包邮<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915160743.png"
            alt=""
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
