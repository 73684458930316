<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/1c5b3233828b0e5ea90ae324dc950642.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="根据时间段开启秒杀" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>根据时间段开启秒杀</strong>
        </h3>
        <p>
          1.正在秒杀时间段内的活动，可以看到秒杀活动剩余时间倒计时及库存余量；过了秒杀时间段或库存为0，则该商品停止秒杀。<br />2.未到秒杀时间的活动商品可以看到秒杀价及库存量，活动时间开始后可以参与秒杀。<br />3.平台后台设置秒杀时间规则;商户后台添加秒杀商品同时调取平台后台的时间规则。<br />4.同一商品可重叠参加秒杀活动,
          价格可以不同。<br />5.秒杀时间段不可重叠,
          整点时间开启,整点时间结束。<br />6.预设置好的秒杀活动,上线前,如需修改,先下架,再编辑,重新发布。
        </p>
        <h3 id="h3--strong-strong-">
          <a name="添加秒杀时间" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>添加秒杀时间</strong>
        </h3>
        <p><strong>位置:平台后台&gt;营销&gt;秒杀&gt;秒杀配置</strong></p>
        <p>
          秒杀时间段配置：<br />1.秒杀时间是以自然天(24小时)为一个周期，也就是设置的时间不能超过24小时；<br />2.秒杀配置时间段不可重叠,
          秒杀活动时间不能超过24:00, 比如16:00是开始时间,结束时间必须在
          24:00之前；<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915101824.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915101829.png"
            alt=""
          /><br />3.秒杀时间段banner尺寸：710 * 300px.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
