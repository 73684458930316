var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video',{staticClass:"aspect-video",attrs:{"poster":require("@/assets/downloads/202309210927719.png"),"src":require("@/assets/downloads/202309061506676.mp4"),"controls":""}}),_c('div',{staticClass:"markdown-body editormd-html-preview",attrs:{"id":"editormd"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309061444003.png"),"alt":""}})]),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"后台点击“商品”—“商品分类”添加商品分类","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("后台点击“商品”—“商品分类”添加商品分类 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("添加一级分类：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("打开“商品分类”页，点击左上角“添加商品分类”")]),_c('li',[_vm._v("在弹框中，父级选择"),_c('strong',[_vm._v("顶级菜单")])]),_c('li',[_vm._v("输入分类名称、图片、排序、状态（前端显示或隐藏）")]),_c('li',[_vm._v("提交保存")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("添加二级子分类：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("点击左上角“添加商品分类”")]),_c('li',[_vm._v("在弹框中，父级选择"),_c('strong',[_vm._v("对应的一级分类")])]),_c('li',[_vm._v("输入分类名称、图片、排序、状态（前端显示或隐藏）")]),_c('li',[_vm._v("提交保存")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("添加三级子分类：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("点击左上角“添加商品分类”")]),_c('li',[_vm._v("在弹框中，父级选择"),_c('strong',[_vm._v("对应的二级分类")])]),_c('li',[_vm._v("输入分类名称、图片、排序、状态（前端显示或隐藏）")]),_c('li',[_vm._v("提交保存")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"h2--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"注：","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("注： ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('p',[_vm._v(" 删除一级分类，需要先删除该一级下的所有二级分类；删除二级分类，需要先删除该二级下的所有三级分类；删除三级分类需要先删除该分类下的所有产品。 ")])]),_c('li',[_c('p',[_vm._v(" 目前系统只支持三级分类，只添加一级或者添加超过三级，都无法正常使用。 ")])]),_c('li',[_c('p',[_vm._v("分类图标建议上传180*180，不宜过大，否则造成系统加载速度过慢。")])])])
}]

export { render, staticRenderFns }