<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309071418822.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--">
        <a
          name="移动端首页-为你推荐"
          class="reference-link"
          one-link-mark="yes"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >移动端首页-为你推荐
      </h3>
      <h3 id="h3--strong-strong-">
        <a name="一、功能说明" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>一、功能说明</strong>
      </h3>
      <pre
        class="prettyprint linenums prettyprinted"
        style=""
      ><ol class="linenums"><li class="L0"><code><span class="pun">可在平台后台设置是否在移动端商城首页展示【为你推荐】</span></code></li><li class="L1"><code><span class="pun">默认推荐：指根据用户访问浏览的商品，系统自动推荐相关商品；</span></code></li><li class="L2"><code><span class="pun">星级推荐：指平台后台，在商品管理列表，为每个商品设置的星级，</span><span class="lit">5</span><span class="pun">星为最高推荐级别，</span><span class="lit">1</span><span class="pun">星为最低推荐级别，商品展示顺序为：</span><span class="lit">5</span><span class="pun">星&gt;</span><span class="lit">4</span><span class="pun">星&gt;</span><span class="lit">3</span><span class="pun">星&gt;</span><span class="lit">2</span><span class="pun">星&gt;</span><span class="lit">1</span><span class="pun">星&gt;无星；同样的商品排序顺序，星级高的商品展示在前；同样的推荐星级，商品排序大的商品展示在前。</span></code></li><li class="L3"><code><span class="pun">创建时间：指根据商品创建先后时间展示，最新创建的商品展示在最前面。</span></code></li></ol></pre>
      <h3 id="h3--">
        <a name="二、设置方法" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>二、设置方法
      </h3>
      <p>设置位置：平台后台&gt;设置&gt;商城设置</p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309071433206.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309071439601.png"
          alt=""
        />
      </p>
      <h3 id="h3-u5546u54C1u661Fu7EA7u8BBEu7F6E">
        <a name="商品星级设置" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>商品星级设置
      </h3>
      <p>
        位置：平台后台&gt;商品管理&gt;编辑<br />
        商品展示顺序：星级&gt;排序&gt;销量<br />
        （1） 5星&gt;4星&gt;3星&gt;2星&gt;1星<br />
        （2） 排序是星级级别内的排序
      </p>
      <p><strong>第一步</strong>：点击编辑</p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309071433031.png"
          alt=""
        />
      </p>
      <p>
        <strong>第二步</strong
        >：点击星级，鼠标放上去，星级自动变灰或变橙色，设置后提交
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309071433027.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/deep/.pun {
  color: #333 !important;
}
</style>
