


<template>
  <div>
    <div
      id="editormd"
      class="markdown-body editormd-html-preview"
    >

      <h3 id="h3--strong-strong-"><a
          name="邮费组成"
          class="reference-link"
        ></a><span class="header-link octicon octicon-link"></span><strong>邮费组成</strong></h3>
      <hr>
      <p>当前商品的邮费由运费模板设置(运费模板里可设置指定包邮，和不配送区域，可按重量、体积、件数设置对应的运费)</p>
    </div>
  </div>
</template>
                              
                        <script>
export default {};
</script>
                        <style lang="scss" scoped></style>
                              