<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/20536b0600b318e36e31556e6275d331.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>一、功能介绍</strong>
        </h3>
        <p>
          平台优惠券是平台营销功能之一，优惠券类型包括：通用券、品类券、跨店券
        </p>
        <h3 id="h3--strong-strong-">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>二、操作流程</strong>
        </h3>
        <p>
          <strong>1. 添加优惠券</strong
          ><br />位置：平台后台&gt;营销&gt;平台优惠券&gt;优惠券列表<br />说明：可添加不同类型优惠券，设置优惠券面值、使用门槛、有效期、是否限时限量、优惠券获取方式、是否开启。<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915113831.png"
            alt=""
          />
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/20230915113852.png"
            alt=""
          />
        </p>
        <p>
          <strong>2. 发送优惠券</strong
          ><br />位置：平台后台&gt;用户&gt;用户列表<br />说明：先选中用户-再点击发送优惠券-选择需要发送的优惠券<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915113951.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915113957.png"
            alt=""
          />
        </p>
        <p>
          <strong> 3. 移动端展示优惠券</strong
          ><br />位置：商城首页&gt;更多优惠券&gt;领券中心<br />说明：<br />
          （1）优惠券分类包括：全部、通用券、品类券、跨店券、店铺券<br />
          （2）通用券、品类券、跨店券分别为平台创建的优惠券；店铺券是商家创建的优惠券，店铺券包括：店铺的通用券和商品券<br />
          （3）领券中心优惠券展示顺序按创建时间展示，最新创建的在最上面展示。<br />
          （4）品类券、跨店券、店铺券中优惠商品展示的优先顺序为：<br />
          平台推荐星级&gt;平台排序最大到小&gt;销量从高到低&gt;创建时间最新优先
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309201810543.png"
            alt=""
            class="w-1/3"
          />
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309201811604.png"
            alt=""
            class="w-1/3"
          />
        </p>
        <h3 id="h3--">
          <a name="三、功能说明" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>三、功能说明
        </h3>
        <p><strong> 1.优惠券类型</strong></p>
        <p>
          （1）通用券：指适用于平台所有店铺、所有商品、所有用户；<br />
          （2）品类券：指适用于某一品类的优惠券；选择商品分类，分类可多选，也可单选；可选择一级分类，也可选择到二级、或三级分类。<br />
          （3）跨店券：指适用于某部分店铺的优惠券；可按条件筛选：包括通过商户分类、店铺类型、商户类别进行选择；可指定店铺，通过搜索添加指定店铺使用优惠券。
        </p>
        <p><strong>2. 优惠券获取方式</strong></p>
        <p>
          (1) 手动领取：指以用户领取方式获得的优惠券；<br />
          (2) 新人券：指用户注册平台后第一次进入商城首页立即自动获得的优惠券。
        </p>
        <p><strong>3. 优惠券的使用</strong></p>
        <p>
          （1）平台优惠券的成本由平台承担；商户对账单详情里
          收入项有平台优惠券补贴；平台对账单详情里 支出项有平台优惠券支出。<br />（2）平台优惠券与商户优惠券可叠加使用，但平台优惠券只能使用1张，不分优惠券类型；（目前商户优惠券的店铺券和商品券可叠加使用），即一个商品最多可同时使用3张优惠券，1张店铺券、1张商品券，1张平台优惠券。<br />（3）优惠券领券中心移动端展示样式以带有商品的样式为主，所以当设置的优惠券下面没有添加商品时，该优惠券不会展示在领券中心，因此需及时在商户后台添加商品，商品审核并上架后，相关优惠券会自动展示在领券中心。<br />（4）使用优惠券的订单全部退款时，所使用优惠券返还；使用优惠券的订单部分退款时，所使用优惠券不返还。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
