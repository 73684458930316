<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/06bf1e881d38dd338ecd1cb6a7abf812.mp4"
      controls
    ></video>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="创建直播间" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>创建直播间</strong>
        </h3>
        <p>
          <strong>位置：商户后台&gt;营销&gt;直播</strong
          ><br />商户后台-创建直播间<br />选择直播时间，填写其他相关信息。<br />主播需提前通过小程序直播实名认证，否则创建直播间失败；主播昵称必须为长于等于4个字符的内容。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309221731540.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309221731863.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="审核直播间" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>审核直播间</strong>
        </h3>
        <p>
          <strong>直播间有两种审核方式：</strong
          ><br />手动审核：平台后台需手动审核；<br />自动审核：平台后台自动审核；<br /><strong
            >切换审核方式</strong
          ><br /><strong
            >位置：平台后台&gt;商户&gt;商户管理&gt;商户列表&gt;编辑</strong
          ><br />开启：平台后台手动审核；关闭：自动审核<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309221732335.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309221732198.png"
            alt=""
          />
        </p>
        <p>
          <strong>手动审核直播间</strong><br /><strong
            >位置：平台后台&gt;营销&gt;直播&gt;直播间管理</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309221733894.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309221733711.png"
            alt=""
          />
        </p>

        <h3 id="h3--strong-strong-">
          <a name="移动端直播间展示顺序设置" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>移动端直播间展示顺序设置</strong>
        </h3>
        <p>
          <strong> 位置：平台后台&gt;营销&gt;直播&gt;直播间管理</strong
          ><br />点击“编辑” - 设置直播间在移动端直播列表优先展示顺序<br />优先展示顺序：星级&gt;排序&gt;直播状态（直播状态顺序:正在进行&gt;未开始&gt;回放）<br />规则：<br />（1）
          5星&gt;4星&gt;3星&gt;2星&gt;1星<br />（2） 排序是星级级别内的排序<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309221734234.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309221734723.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="后台直播间列表状态显示规则" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>后台直播间列表状态显示规则</strong>
        </h3>
        <p>
          商户后台和平台后台 直播间列表状态展示规则<br />位置：商户后台&gt;营销&gt;直播&gt;直播间管理&gt;直播间列表<br />
          平台后台&gt;营销&gt;直播&gt;直播间管理&gt;直播间列表<br />
          <img
            v-preview-image
            src="@/assets/downloads/screenshot_1615263993595.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
