<template>
  <div>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h2>
        <p>
          店铺活动页面仅展示该店铺创建的所有营销活动，包括秒杀活动、拼团活动、助力活动、预售活动等
        </p>
        <h2 id="h2--">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h2>
        <p><strong>商户后台设置活动</strong></p>
        <p>
          <strong
            >注：商户后台添加各营销活动，平台后台审核通过后会展示在商城及店铺活动页。</strong
          >
        </p>
        <p class="flex flex-col gap-3">
          <a href="/76"> 秒杀活动设置详情</a>
          <a href="/88"> 拼团活动设置详情</a>
          <a href="/87"> 助力活动设置详情</a>
          <a href="/81"> 全款预售</a>
          <a href="/82"> 定金预售</a>
          <a href="/79"> 直播设置详情</a>
          <a href="/85"> 专题活动设置详情</a>
        </p>
        <p><strong>移动端活动展示位置</strong></p>
        <p>
          <strong>位置</strong>：移动端&gt;店铺首页&gt;活动<br /><strong
            >说明</strong
          >：<br />
          1 . 店铺页底部 “专场”菜单，<br />
          2 . “活动”页面会展示：秒杀、拼团、助力、预售等营销活动商品；<br />
          3 . 活动商品展示顺序为：商户后台设置排序&gt;创建时间<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/20230915152552.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
