<template>
  <div>
    <video  class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/20230918155311.mp4"
      controls
    ></video>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="默认分销配置" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>默认分销配置</strong>
        </h3>
        <p><strong>位置：平台后台&gt;分销 &gt; 分销配置</strong></p>
        <p>
          <strong>开启分销</strong>：选择开启按钮，即开启分销<br /><strong
            >默认返佣设置</strong
          >：设置一二级返佣百分比，例如：一级佣金设置为
          “20“，则一级返佣比例为20%<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121528087.png"
            alt=""
          /><br /><strong>分销内购：</strong
          >
         <p> 开启：指分销员自己购买商品，享受一级返佣，上级享受二级返佣；关闭：分销员自己购买商品没有返佣。</p>
          
           <strong
            >分销限时绑定：
            </strong
          ><br />
          开启：根据设置的分销绑定时段建立或解绑分销关系；关闭：默认永久绑定分销关系；<br />
          
          <p>（1）【开启】—【关闭】
          之间切换时，也就是
          时段分销与永久分销互相切换时，分销时间计算以当时操作时间为依据计算；<br />（2）分销绑定时间设置后，中途修改绑定时间，可缩短分销时间，也可延长分销时间；缩短或延长分销时间，以各分销员建立绑定关系时的时间为计算依据；<br /></p>
          <strong
            >限时绑定天数</strong
          ><br />
          <p>单位为天数，大于等于1，指自绑定关系成功至自动解绑之间的天数，自动解绑后产生的订单，返佣按新绑定关系结算；如果没有新的绑定关系，即没有返佣。在绑定关系时产生的订单，还未确认收货分佣时，修改了绑定时间，导致
          解绑或绑定了新关系，到分佣时分给新绑定关系（解绑的则没有分佣）<br /></p>
          <strong>分销时段延长的情况及举例：</strong><br /><strong>
            原分销绑定时段15天，现在设定为30天后有以下情况</strong
          >
          
          <p><br />
          【1】如果绑定关系还未结束，各自延长15（=30天-15天）天；<br />
          【2】如果已经解绑的，不处理；<br />
          【3】如果没有绑定关系，将按自绑定的时间计算30天。<br />比如, A与B
          在3月1日绑定了分销关系，在3月5日时，系统设定分销绑定时间
          由原来的15天，变更为现在的 30天，原本A与B 3
          月15日解除绑定关系，现在A与B 3月30日 解除绑定关系；<br /></p><strong>
            分销时段缩短的情况及举例：</strong
          ><br /><strong
            >原分销绑定时段为30天，现在设定为15天后有以下情况：</strong
          >
          
       
       <strong>其它相关设置</strong></p>
        <p>
          提现最低金额：满足提现金额后才可提现
          
            提现银行卡：填写用户申请佣金时可选择的银行，按回车键可继续填写
          
          
            冻结时间：可设置返佣后的佣金冻结期，冻结的佣金不可提现，达到解冻时间后方可提现；冻结期从用户获得返佣时开始计算
          
          
            商户设置的最大礼包数：指商户可以设置分销礼包的最大数量<br />
            <img
              v-preview-image
              src="@/assets/downloads/202309121528199.png"
              alt=""
            />
          
        
        </p>
        <h3 id="h3--strong-strong-">
          <a name="商品单独设置返佣" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>商品单独设置返佣</strong>
        </h3>
        <hr />
        <p>
           位置：商户后台 > 商品> 商品列表 > 添加商品 > 规格设置 > 佣金设置
        </p>
        <ol>
          <li>默认设置：使用商城的统一返佣配置</li>
          <li>
            单独设置：可单独设置商品的一二级佣金，每个商品规格都可单独设置佣金<br />
            <img
              v-preview-image
              src="@/assets/downloads/202309121528935.png"
              alt=""
            />
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
