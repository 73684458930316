<template>
  <div>
    <div
      class="flex justify-between p-5 border-b duration-300 max-md:flex-col max-md:items-start"
      v-for="(i, index) in data"
      :key="index"
    >
      <h1 class="text-sm text-gray-900 whitespace-nowrap">{{ i.name }}</h1>
      <div class="text-left">
        <a :href="i.url" class="text-sm overflow-x-clip">{{ i.url }} </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          name: "共青学院",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__BF9866C_20240123140441.apk",
        },
        {
          name: "南昌影视学院",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230717101041.apk",
        },
        {
          name: "重庆数字产业职业学院",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230717141249.apk  ",
        },
        {
          name: "西安思源学院",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230717142559.apk",
        },
        {
          name: "银川能源学院",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230717143242.apk",
        },
        {
          name: "邢台学院",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230718095525.apk",
        },
        {
          name: "贵州应用",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230718150530.apk ",
        },
        {
          name: "创业学院",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230718153514.apk ",
        },
        {
          name: "白银希望",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230718162617.apk",
        },
        {
          name: "科技学院 ",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230718163536.apk ",
        },
        {
          name: "苏州托普",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230721103200.apk ",
        },
        {
          name: "南京金肯",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/__UNI__6FDF832__20230721111814.apk ",
        },
        {
          name: "西南交通大学希望学院",
          url: "https://mp-64c084cc-4dec-4b27-a048-c4c13d3b9c84.cdn.bspapp.com/cloudstorage/f0ec432d-2f4c-4c2f-88bf-316ed873d331.apk",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
