<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="七牛云储存创建及使用" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>七牛云储存创建及使用</strong>
      </h2>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915154614.png"
          alt=""
        />
      </p>
      <h3 id="h3-1-accesskey-secretkey-https-portal-qiniu-com-">
        <a
          name="1.获取七牛云AccessKey/SecretKey  地址：   https://portal.qiniu.com/"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >1.获取七牛云AccessKey/SecretKey 地址：
        <a href="https://portal.qiniu.com/">https://portal.qiniu.com/</a>
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/e05c0c34eaea779f45071f4b9394d477_3030x1188.png"
          alt=""
        />
      </p>
      <h3 id="h3-2-">
        <a name="2.打开七牛云存储" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>2.打开七牛云存储
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/173b919168435985a430087115d1ed12_3030x1564.png"
          alt=""
        />
      </p>
      <h3 id="h3-3-">
        <a name="3.创建存储空间" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>3.创建存储空间
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/81db4e6c7d4c3531dd936830b8ca28b7_2964x1212.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/d448cf970c2bf8b7d556bd1d97da3a3c_3018x1626.png"
          alt=""
        />
      </p>
      <h3 id="h3-4-">
        <a name="4.绑定访问域名" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>4.绑定访问域名
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/287899552786005addcfaf0c8e6bea98_2888x868.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/519af6bba2b0a5d66c145e23fe518970_2032x1272.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/472f3cf8a6e2545e6670bac09c540573_2014x1196.png"
          alt=""
        />
      </p>
      <h3 id="h3-5-">
        <a name="5.添加记录" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>5.添加记录
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/c2a56fed201ce52b2ba737f8facb58e5_2100x1140.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/21e5edba2138f674062fcf9e27a29359_2046x1148.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
