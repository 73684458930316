var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"editormd"}},[_c('div',{staticClass:"right-body markdown-body editormd-html-preview"},[_vm._m(0),_c('hr'),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('p',[_vm._v(" 万千实训系统分销为二级分销；分销固定两级，后台不能自己设置分销等级。 ")]),_c('hr'),_vm._m(5),_c('p',[_vm._v(" A推广B，B推广了C；"),_c('br'),_vm._v("A购买商品后，自己没有返佣；"),_c('br'),_vm._v("B购买商品后，A获得一级返佣；"),_c('br'),_vm._v("C购买了商品，B获得一级返佣，A获得二级返佣"),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/636ee80a5e1ffd4798410ed603e660d2_546x540.png"),"alt":""}}),_c('br'),_c('strong',[_vm._v("注：不返佣金的情况")]),_c('br'),_vm._v("（1）用户没有推广员身份（指定分销情况下）"),_c('br'),_vm._v("（2）产品售价为0时，不返佣"),_c('br'),_vm._v("（3）下级用户购买拼团、砍价、秒杀商品时，没有返佣"),_c('br'),_vm._v("（4）下级用户购买商品后，没有确认收货时，暂不返佣；确认收货之后才会返佣 ")]),_c('hr'),_vm._m(6),_vm._m(7),_c('hr'),_vm._m(8),_vm._m(9),_c('hr'),_vm._m(10),_vm._m(11),_c('hr'),_vm._m(12),_c('p',[_vm._v(" 后台分销员管理可解除用户的上级推广员，推广关系解除之后，推广员之前记录的推广订单依然保留，之后下级消费的订单因推广关系已解除所以不再记录 ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--strong-crmeb-strong-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"万千实训系统分销说明"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_c('strong',[_vm._v("万千实训系统分销说明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--strong-1-crmeb-strong-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"1、万千实训系统分销模式"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_c('strong',[_vm._v("1、万千实训系统分销模式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("分销模式：")]),_vm._v(" 指定分销")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("指定分销：")]),_vm._v(" 用户默认无分销权限，需要后台开通分销权限或在商城购买分销礼包商品后，才可以通过推广下级获得返佣； ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--strong-2-strong-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"2、分销等级"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_c('strong',[_vm._v("2、分销等级")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--strong-3-strong-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"3、返佣规则"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_c('strong',[_vm._v("3、返佣规则")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--strong-4-strong-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"4、商品返佣"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_c('strong',[_vm._v("4、商品返佣")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("商品返佣分为 “默认返佣“ 和 “商品单独返佣“")]),_c('li',[_c('strong',[_vm._v("默认返佣")]),_c('br'),_vm._v("商城统一设置商品的返佣比例"),_c('br'),_vm._v("一级佣金 = 实付金额 * 一级返佣比例"),_c('br'),_vm._v("二级佣金 = 实付金额 * 二级返佣比例 ")]),_c('li',[_c('strong',[_vm._v("商品单独返佣")]),_c('br'),_vm._v("（1）每个商品可选择单独设置返佣"),_c('br'),_vm._v("（2）每个商品规格设置一二级返佣"),_c('br'),_vm._v("（3）单独设置后，商品的返佣以固定佣金为准，不受其他因素影响"),_c('br'),_vm._v("（4）即使商品售价为0，用户购买该商品上级仍会收到固定金额的返佣 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--strong-5-strong-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"5、佣金冻结"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_c('strong',[_vm._v("5、佣金冻结")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" 佣金新增冻结期限，后台可设置冻结期，冻结的佣金不可提现，达到解冻时间后方可提现 ")]),_c('li',[_vm._v("冻结期从用户获得返佣时（确认收货）开始计算")]),_c('li',[_vm._v("冻结期设置为0时，则为无冻结期；冻结时间单位为 “天“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--strong-6-strong-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"6、绑定推广关系"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_c('strong',[_vm._v("6、绑定推广关系")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" 公众号、小程序：商品详情页（分享链接方式），产品海报（二维码分享），分销推广海报（二维码分享）； ")]),_c('li',[_vm._v("H5商城：产品海报，分销推广海报；")]),_c('li',[_vm._v(" 说明：用户通过这些方式进入商城后，必须授权或者注册（H5）之后才会绑定关系 ")]),_c('li',[_vm._v(" 当用户已有上级、用户进入商城未授权或注册、用户已经是推广员（指定分销模式），不能绑定推广关系 ")]),_c('li',[_vm._v("用户之前访问过商城，但没有上级时，可以绑定推广关系")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--strong-7-strong-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"7、解除推广关系"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_c('strong',[_vm._v("7、解除推广关系")])])
}]

export { render, staticRenderFns }