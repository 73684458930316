<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2-u5546u54C1u6807u7B7E">
        <a name="商品标签" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>商品标签
      </h2>
      <p>
        平台和商户都可以在<strong>商品</strong>&gt;<strong
          >商品标签下设置自己的商品标签</strong
        >
      </p>
      <h3 id="h3-u4F18u60E0u5957u9910">
        <a name="优惠套餐" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>优惠套餐
      </h3>
      <p>
        营销/优惠套餐<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915120051.png"
          alt=""
        />
      </p>
      <p>
        添加优惠套餐只能选择平台已审核通过的普通商品，且每个商品在同一个套餐活动中不能重复，限量是指整个套餐活动的限量，不影响普通商品的购买<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915120050.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
