<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/c600941a273ac2f4078a34c57a720478.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="积分设置" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>积分设置</strong>
        </h3>
        <p>
          平台设置积分总规则，各子商户后台设置积分抵扣比例，或针对单个商品单独设置抵扣比例。<br /><strong
            >位置：平台后台&gt;营销&gt;积分&gt;积分配置</strong
          ><br />
          (1)
          <strong>积分开启/关闭</strong
          >：指平台积分功能的开启或关闭，开启：积分功能正常启用；关闭：积分功能不可使用，且前端不展示，不影响布局。
        </p>
        <p>
          （2）<strong>积分抵用金额</strong>：1积分可抵扣金额，单位：元；<br />
          例：设置为2，则1积分可抵扣2元；
        </p>
        <p>
          （3）<strong>下单赠送积分比例</strong>：指实际支付1元赠送多少积分，单位：分；默认为0，则下单不赠送积分，下单支付成功后立即赠送积分。<br />
          例：设置为5，实际支付10元，则用户支付成功后立即 赠送5 x 10 = 50积分
        </p>
        <p>
          （4）
          <strong>下单赠送积分冻结期**</strong
          >：**指用户获得下单赠送积分后，进入冻结期，多久后可使用该积分，单位：天；
          积分过了冻结期后，该赠送积分的订单退款，所赠送积分不退。<br />
          例：设置为15，则因下单获得赠送积分在15天后才可使用；默认设置 为
          15天，如设置为0 ，则没有冻结期，立即可以使用。
        </p>
        <p>
          （5）<strong>积分清除时间**</strong>：**指累计积分清楚时间，默认0 为
          永久有效，单位：月；<br />例：设置为6，指每隔6个月清楚前6个月的积分，比如6月30日，自动清除上一年7月1日-12月31日的积分。<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915103634.png"
            alt=""
          />
        </p>
        <p>
          （6）<strong>每个用户积分的累计数值精确到个位</strong>，（小数点后全部舍弃，不进行四舍五入）<br />例：订单金额
          109元，如果设置的下单金额100元可获得10个积分，即为10分。
        </p>
        <p>
          （7）<strong>邀请好友赠送积分</strong>：指邀请好友成功，赠送给邀请人的积分数；单位：分；邀请好友成功是指，分享商品详情链接或商品海报给好友，好友(新人)通过链接或海报二维码成功登录商城，同时绑定关系；给邀请人赠送积分。
        </p>
        <p>
          （8）<strong>赠送积分</strong>：普通商品支付时赠送积分，所有活动商品均不赠送积分。
        </p>
        <p>
          （9）<strong>积分说明</strong>：平台可将积分使用规则描述写于此，展示在移动端。<br />
          展示位置：移动端&gt;个人中心&gt;积分中心<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201804128.png"
            class="w-1/3"
            alt=""
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309251630858.png"
            alt=""
          />
        </p>
        <p>（10）<strong>积分不可抵扣运费</strong>：积分不能用于抵用运费。</p>
        <p><strong>位置：商户后台&gt;营销&gt;积分&gt;积分配置</strong></p>
        <h3 id="h3--strong-strong-">
          <a
            name="积分开启：开启：指商户开启商品使用积分抵扣，所产生的成本由商户承担；关闭：指商户的商品不使用积分抵扣。"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>积分开启</strong
          >：开启：指商户开启商品使用积分抵扣，所产生的成本由商户承担；关闭：指商户的商品不使用积分抵扣。
        </h3>
        <h3 id="h3--strong-strong-">
          <a
            name="默认抵扣比例：指积分抵扣金额占单个商品销售价的比例，指商户设置所有商品统一的积分最高抵扣比例，所产生的成本商户承担。"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>默认抵扣比例</strong
          >：指积分抵扣金额占单个商品销售价的比例，指商户设置所有商品统一的积分最高抵扣比例，所产生的成本商户承担。
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/20230915103633.png"
            alt=""
          />
        </p>
        <p>
          <strong> 位置：商户后台&gt;商品&gt;商品列表</strong><br />
          单个商品设置积分抵扣比例：在商品编辑中，可针对每个商品单独设置抵扣比例，如商户已经统一设置了积分抵扣比例，优先使用单独商品设置的积分比例。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309251504435.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
