<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/7f371e1e25d924ca3d64f0dbf236c691.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2-u5546u6237u540Eu53F0u67E5u770Bu52A9u529Bu6D3Bu52A8u60C5u51B5">
        <a name="商户后台查看助力活动情况" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >商户后台查看助力活动情况
      </h2>
      <p>
        <strong>位置：商户后台&gt;营销&gt;助力活动</strong><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915154254.png"
          alt=""
        />
      </p>
      <h2 id="h2-u5E73u53F0u540Eu53F0u67E5u770Bu52A9u529Bu6D3Bu52A8u60C5u51B5">
        <a name="平台后台查看助力活动情况" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >平台后台查看助力活动情况
      </h2>
      <p>
        <strong>位置：平台后台&gt;营销&gt;助力活动</strong><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915154259.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
