import { render, staticRenderFns } from "./17.vue?vue&type=template&id=c3c28590&scoped=true"
import script from "./17.vue?vue&type=script&lang=js"
export * from "./17.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3c28590",
  null
  
)

export default component.exports