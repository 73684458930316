<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/20230918161658.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-crmeb-strong-">
          <a name="万千实训系统分销等级说明" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>万千实训系统分销等级说明</strong>
        </h3>
        <hr />
        <!-- <p> v1.5.1以上版本有分销员等级功能</p> -->
        <h3 id="h3--strong-1-strong-">
          <a name="1、分销员等级说明" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>1、分销员等级说明</strong>
        </h3>
        <p>
          分销员等级是指，平台可根据分销员推广人数、个人消费金额、个人消费订单数量、推广订单数、推广订单金额、五个维度设置分销员等级任务；分销员必须多个任务同时完成，才能升级到更高分销员等级。<br />分销员等级不同，推广获取的一级返佣、二级返佣比例也不同；平台根据不同分销员等级，设置在原默认一级返佣比例、二级返佣比例基础上的上浮比例。<br />（1）平台后台添加分销员等级，及对应的等级任务；<br />（2）设置佣金上浮比例；设置不同分销员等级对应在原默认一级返佣比例、二级返佣比例基础上的上浮比例。
        </p>
        <blockquote>
          <p>
            <strong>举例</strong
            >：V1等级分销员返佣比例设置：在默认一级返佣比例上浮10%，在默认二级返佣比例上浮10%（假如默认一级佣金比例
            10%，售价 100元产品，佣金 100*10% =
            10元，V1等级分销员设置上浮10%，那最终V1等级分销员一级返佣金额就是10
            + 10 * 10% = 11；)<br />V2等级分销员返佣比例设置：在默认一级返佣比例上浮20%，在默认二级返佣比例上浮20%（同上）
          </p>
        </blockquote>
        <h3 id="h3--strong-2-strong-">
          <a name="2、新增分销员等级" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>2、新增分销员等级</strong>
        </h3>
        <p>
          <strong>第一步</strong>：添加分销员等级；<br /><strong>位置</strong
          >：平台后台&gt;分销&gt;分销等级&gt;分销员等级；<br />点击添加数据，添加分销员等级。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121729470.png"
            alt=""
          /><br /><strong>第二步</strong>：设置等级名称、等级任务；
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309121729702.png"
            alt=""
          /><br />设置等级名称：例如：黄铜分销员、白银分销员、黄金分销员、钻石分销员等；<br />设置等级任务：从邀请好友成为下线人数、个人消费金额、个人消费订单数量、推广订单数、推广订单金额、五个维度设置分销员等级任务；<strong>至少选择填一项任务</strong>；<br /><strong
            >邀请好友成为下线</strong
          >：成功邀请一定数量用户成为下线；<br /><strong>自身消费金额</strong
          >：分销员个人通过平台消费金额；<br /><strong>自身消费订单数</strong
          >：分销员通过平台下单数量；<br /><strong>下级消费金额</strong
          >：推广员直接推广的下线用户下单金额；<br /><strong
            >下级消费订单数</strong
          >：推广员直接推广下线用户订单数；<br /><strong>注</strong
          >：<br />1、五项任务中可选择设置，至少填写一项任务提交；<br />2、分销等级任务设置多个任务时，用户必须同时完成多项等级任务，才能升至更高分销员等级；<br />3、用户分销员等级升级中，每个等级之间的任务不累计；
        </p>
        <blockquote>
          <p>
            <strong>举例</strong
            >：初级分销员任务需要完成推广100个下线，中级分销员任务需要完成推广500个下线，中级任务推广的
            500个下线 应为新增的500人下线，不包含初级的100人；
          </p>
        </blockquote>

        <p>
          4、设置等级任务为推广订单数、推广订单金额，在任务完成后，又出现退款情况的，分销员等级不变；<br />
          <blockquote>
            <strong>举例</strong
          >：设置任务完成100个订单可达到中级分销员，当该分销员第100个订单支付时，该分销员自动升级为中级分销员，第101个订单按中级分销员的规则进行分佣；以前100个订单中有出现退款等情况，分销员等级不变；
          </blockquote>
        
        </p>
        <p>
          5、设置等级任务为推广人数，在任务完成后，又出现新推广人
          取关等情况，分销员等级不变。<br /><strong>第三步</strong
          >：设置分销等级、等级图标；<br /><strong>第四步</strong
          >：设置等级返佣上浮比例；<br />设置佣金上浮比例是指，当前等级的分销员分佣比例在默认分销员分佣比例基础上的上浮比例；一级佣金上浮比例指在默认级别的分销员一级分佣基础上的上浮比例；二级佣金上浮比例指在最初级别的分销员二级分佣基础上的上浮比**
        </p>
        <blockquote>
          <p>
            举例<em
              >*；<br />例如：默认一级佣金比例 10%，售价
              100元的产品，默认分销员佣金 100\</em
            >10% =
            10元，如果当前等级设置上浮10%，那最终的当前等级分销员一级返佣金额就是10
            + 10 * 10% = 11；
          </p>
          <p>
            默认二级佣金比例5%，售价100元产品，默认分销员佣金100*5%=5元，如果当前设置上浮5%，那最终的当前等级分销员二级返佣金额就是5+5*5%
            =5.25元
          </p>
        </blockquote>
        <h3 id="h3--strong-3-strong-">
          <a name="3、设置等级规则说明" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>3、设置等级规则说明</strong>
        </h3>
        <p>
          <strong>位置</strong
          >：平台后台&gt;分销&gt;分销等级&gt;等级规则；<br />通过分销等级说明编辑器，编辑分销员等级说明<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121730606.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
