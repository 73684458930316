import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store'
import ElementUI from 'element-ui';
import './assets/index.css';
import './assets/edit.css';
import 'element-ui/lib/theme-chalk/index.css';

import '@/utils/globalDirectives'; // 引入全局指令
import VueCoreVideoPlayer from 'vue-core-video-player'

Vue.use(VueCoreVideoPlayer)

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
