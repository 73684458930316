<template>
  <div>
    <div class="flex justify-center">
      <video
        class="aspect-video"
        poster="@/assets/downloads/202309210927719.png"
        src="@/assets/downloads/202309061729213.mp4"
        controls
      ></video>
    </div>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2-u79FBu52A8u7AEFu5546u54C1u7BA1u7406u529Fu80FDu8BF4u660E">
        <a
          name="移动端商品管理功能说明"
          class="reference-link"
          one-link-mark="yes"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >移动端商品管理功能说明
      </h2>
      <h3 id="h3-u6309u94AEu8BF4u660E">
        <a name="按钮说明" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>按钮说明
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061711822.png"
          alt=""
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061711945.png"
          alt=""
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309261505653.png"
          alt=""
        />
      </p>
      <h3 id="h3-u5546u54C1u7BA1u7406">
        <a name="商品管理" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>商品管理
      </h3>
      <p>
        输入商品信息,商品价格,选择商品分类,品牌等信息<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061712379.png"
          alt=""
        />
      </p>
      <p>
        设置商品详情,视频,限购等信息<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061712048.png"
          alt=""
        />
      </p>
      <h3 id="h3-u5546u54C1u5206u7C7Bu7BA1u7406">
        <a name="商品分类管理" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>商品分类管理
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061712465.png"
          alt=""
        /><br />编辑分类<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061712463.png"
          alt=""
        />
      </p>
      <h3 id="h3-u5546u54C1u89C4u683Cu7BA1u7406">
        <a name="商品规格管理" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>商品规格管理
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061712459.png"
          alt=""
        />
      </p>
      <p>
        编辑规格<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061712505.png"
          alt=""
        />
      </p>
      <h3 id="h3-u8FD0u8D39u6A21u677Fu7BA1u7406">
        <a name="运费模板管理" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>运费模板管理
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061712548.png"
          alt=""
        />
      </p>
      <h3 id="h3-u7F16u8F91u8FD0u8D39u6A21u677F">
        <a name="编辑运费模板" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>编辑运费模板
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061712349.png"
          alt=""
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309061712746.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
