<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/7b7d1b442c055251ebfb9def17c4bfc7.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>一、功能介绍</strong>
        </h3>
        <p>
          商户优惠券是店铺运营的基本工具，店铺优惠券类型包括：店铺券、商品券
        </p>
        <h3 id="h3--strong-strong-">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>二、操作流程</strong>
        </h3>
        <p>
          <strong>1. 添加优惠券</strong
          ><br />位置：商户后台&gt;营销&gt;优惠券&gt;优惠券列表<br />说明：可添加不同类型优惠券，设置优惠券面值、使用门槛、有效期、是否限时限量、优惠券获取方式、是否开启。<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915112957.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915113006.png"
            alt=""
          />
        </p>
        <p>
          <strong>2. 发送优惠券</strong
          ><br />位置：商户后台&gt;用户&gt;用户管理<br />说明：商家主动给用户发放优惠券，先选中用户-再点击发送优惠券-选择需要发送的优惠券<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915113012.png"
            alt=""
          />
        </p>
        <p>
          <strong
            >第一步： 先选择发送对象，可单选、可本页全选、可全部全选</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915113020.png"
            alt=""
          />
        </p>
        <p>
          <strong>第二步：选择需发送的优惠券</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915113029.png"
            alt=""
          />
        </p>
        <p>
          <strong>第三步：点击发送，确认发送</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915113043.png"
            alt=""
          />
        </p>
        <p>
          <strong>3. 查看优惠券发放记录</strong
          ><br />位置：商户后台&gt;营销&gt;优惠券&gt;优惠券发放记录<br />功能：<br />
          （1）.可查看优惠券名称、类型、发放时间、有效期、发放数量、发放对象及使用记录；<br />
          （2）.当筛选条件为“无”时，表示商家单独选择用户作为发放对象，具体用户昵称可通过使用记录查看；<br />
          当筛选条件为用户标签等信息时，表示商家将优惠券发给了这类标签或特点的用户，具体用户昵称可通过使用记录查看；<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915113050.png"
            alt=""
          />
        </p>
        <p>
          <strong> 4. 移动端优惠券展示位置</strong
          ><br />位置1：商城首页&gt;更多优惠券&gt;领券中心&gt;店铺优惠券（仅展示店铺券，不展示商品券）<br />位置2：店铺首页&gt;领券（仅展示店铺券，不展示商品券）<br />位置3：商品详情&gt;优惠券（店铺券和商品券均展示）<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201808503.png"
            alt=""
            class="w-1/3"
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201808376.png"
            alt=""
            class="w-1/3"
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201809914.png"
            alt=""
            class="w-1/3"
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201809488.png"
            alt=""
            class="w-1/3"
          />
        </p>
        <h3 id="h3--">
          <a name="三、功能说明" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>三、功能说明
        </h3>
        <p><strong> 1.优惠券类型</strong></p>
        <p>
          （1）店铺券：指适用于店铺所有商品的店铺优惠券；<br />
          （2）商品券：指适用于店铺某一分类的商品或指定的某个商品的优惠券；
        </p>
        <p><strong>2. 优惠券获取方式</strong></p>
        <p>
          （1）手动领取：指以用户领取方式获得的优惠券；<br />
          （2）新人券：指用户注册平台后第一次进入商城首页会自动获得的优惠券；<br />
          （3）赠送券：指可以在添加商品时选择赠送，用户购买该商品后自动获得优惠券的方式。
        </p>
        <p>
          <strong>3. 优惠券的使用</strong
          ><br />（1）用户购买商品下单时，选择优惠券直接抵扣支付金额；<br />（2）使用优惠券的订单全部退款时，所使用优惠券返还；使用优惠券的订单部分退款时，所使用优惠券不返还。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
