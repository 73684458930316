<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="设置核销权限" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>设置核销权限</strong>
      </h2>
      <p>
        <strong>位置：客服 &gt; 客服列表&gt; 添加客服&gt;开启核销权限</strong
        ><br /><strong>核销员说明：</strong
        ><br />1.核销员有核销订单以及查看核销订单详情的权限<br />2.移动端的商城个人中心有进入核销的按钮<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141643744.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
