var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video',{staticClass:"aspect-video",attrs:{"poster":require("@/assets/downloads/202309210927719.png"),"src":require("@/assets/downloads/bandicam 2023-09-21 15-27-59-514.mp4"),"controls":""}}),_c('div',{attrs:{"id":"editormd"}},[_c('div',{staticClass:"right-body markdown-body editormd-html-preview"},[_vm._m(0),_c('p',[_vm._v(" 门店自提，是一种配送方式，用户到店核销，自提货物。万千实训系统多商户商城是每个商户对应一个自提点的模式。 ")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('p',[_vm._v(" 位置：平台后台>设置>第三方接口>地图配置"),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309141642578.png"),"alt":""}})]),_vm._m(5),_c('p',[_vm._v(" 位置：商户后台>设置>商户信息>店铺信息"),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309201450387.png"),"alt":""}})]),_vm._m(6),_vm._m(7),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309201508698.png"),"alt":""}}),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309201508988.png"),"alt":""}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"一、功能介绍"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("一、功能介绍 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"二、操作流程"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("二、操作流程 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第一步：申请腾讯地图key")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 使用地图配置前，请前往"),_c('a',{attrs:{"href":"https://lbs.qq.com"}},[_vm._v("https://lbs.qq.com")]),_vm._v(" 进行申请"),_c('br'),_vm._v(" 注：申请地图key的时候域名白名单里面留空即可，不然会导致获取列表失败"),_c('br'),_vm._v(" 腾讯地图测试key:5WNBZ-2JYR6-SPUSL-M3WGH-U4KDT-K2FYV ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第二步：平台后台配置地图key")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第三步：开启门店自提")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第四步：移动端展示")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("位置：提交订单界面>配送方式选择>门店自提")])])
}]

export { render, staticRenderFns }