<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="积分使用" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>积分使用</strong>
      </h2>
      <p>
        <strong>说明</strong>：<br />
        1.购买商品可使用积分抵扣；<br />
        2.订单退款后，所使用积分会退回，因支付赠送积分会扣除。
      </p>
      <p>
        <strong>位置</strong>：移动端&gt;商品详情<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/20230915104626.png"
          alt=""
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/20230915104626_1.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
