<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309210932770.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-">
          <a name="平台用户管理" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>平台用户管理</strong>
        </h2>
        <h3 id="h3--strong-strong-h5-">
          <a
            name="用户列表：平台所有用户信息的展示与查看，通过公众号、小程序、H5不同类型进来的用户均展示在此。"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>用户列表</strong
          >：平台所有用户信息的展示与查看，通过公众号、小程序、H5不同类型进来的用户均展示在此。
        </h3>
        <p>
          （1）编辑用户信息<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131607167.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131607160.png"
            alt=""
          /><br />（2）查看或设置用户信息<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131607359.png"
            alt=""
          /><br />（3）手动修改推荐人绑定关系<br />
          位置：平台后台&gt;用户&gt;用户列表&gt;更多<br />
          规则：用户A 先绑定用户 B , A创建订单 -》修改A 上级用户为 C -》用户 A
          收货 结果：用户B 的佣金返给 C<br />
          修改推荐人<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131608551.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131608951.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131608615.png"
            alt=""
          /><br />
          查看推荐人绑定记录<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131608070.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131608220.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a
            name="用户分组：将用户按特征、特点分为小组，方便管理和运营，一个用户只能被分到一个组"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>用户分组</strong
          >：将用户按特征、特点分为小组，方便管理和运营，一个用户只能被分到一个组
        </h3>
        <p>
          添加用户分组：<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131608030.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131609111.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a
            name="用户标签将用户按特征、特点添加标签，方便管理和运营，一个用户可以被打上不同的标签"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>用户标签</strong
          >将用户按特征、特点添加标签，方便管理和运营，一个用户可以被打上不同的标签
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309131609783.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131609126.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="用户反馈" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>用户反馈</strong>
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309131609598.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131610846.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
