<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/a63379337dc2f21fbd859272b05da68d.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="优惠套餐" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>优惠套餐</strong>
      </h2>
      <p>
        <strong>位置：平台后台&gt;营销&gt;优惠套餐</strong
        ><br />优惠套餐不能分佣<br />列表/上下架<br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915120051.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
