<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-cos-strong-">
        <a name="腾讯COS创建及使用" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>腾讯COS创建及使用</strong>
      </h2>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915155102.png"
          alt=""
        />
      </p>
      <hr />
      <h3 id="h3-1-">
        <a name="1.打开腾讯云的对象存储" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >1.打开腾讯云的对象存储
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/c20e1937b2628a87b88e35398c488c4f_2164x628.png"
          alt=""
        />
      </p>
      <h3 id="h3-2-">
        <a
          name="2.创建存储空间  【访问权限设置为公有读写】"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span>2.创建存储空间
        【访问权限设置为公有读写】
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/2fcdcf040489bc2759e91568f5a59b9e_2162x844.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/6a5b76943c763f176163678aba75cb22_2152x1564.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/a8888082d446e740c128b98514ce26f2_2158x430.png"
          alt=""
        />
      </p>
      <h3 id="h3-3-accesskey-secretkey">
        <a name="3.获取 accessKey和secretKey" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>3.获取
        accessKey和secretKey
      </h3>
      <p>
        <img
          v-preview-image
          src="https://img.kancloud.cn/88/21/8821aaff13040661edc937df08ad4953_2160x1034.png"
          alt=""
        />
      </p>
      <h3 id="h3-4-js-">
        <a name="4.配置js跨域上传" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>4.配置js跨域上传
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/screenshot_1609051868576.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
