import { render, staticRenderFns } from "./36.vue?vue&type=template&id=516b1bed&scoped=true"
import script from "./36.vue?vue&type=script&lang=js"
export * from "./36.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516b1bed",
  null
  
)

export default component.exports