<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-212014-00-17-935.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="财务对账流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>财务对账流程</strong>
        </h3>
        <ol>
          <li>
            商户后台和平台后台按每笔订单结算，各后台分别显示相应日账单、月账单，并可分别查看账单详情，下载账单明细；
          </li>
          <li>
            商户有余额，商户设置自己收款方式，并可申请转账，平台审核通过后，线下转账；商户和平台分别通过转账记录查看转账申请、进度及是否已转账。
          </li>
        </ol>
        <h3 id="h3--strong-strong-">
          <a name="一、平台后台查看账单及转账设置" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>一、平台后台查看账单及转账设置</strong>
        </h3>
        <p>
          <strong>位置：平台后台&gt;财务&gt;账单管理</strong><br />
          账期内订单收入总额：指结算周期内所有的订单支付收入、退回优惠券补贴、退回会员优惠券补贴；<br />
          账期内支出总额：指结算周期内所有的平台手续费退款、佣金支出、平台需付给商户的商户应入账金额之和；<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141046635.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141047852.png"
            alt=""
          /><br />
          <strong>日账单详情</strong><br />
          订单收入总额=当日平台收入的订单金额之和+退回优惠券补贴+退回会员优惠券补贴<br />
          充值金额=当日平台用户的充值金额总和<br />
          支出总金额=当日应付商户金额+佣金+返还手续费金额<br />
          平台手续费收入总金额=当日平台总进账收入金额-当日平台总支出金额<br />
          每笔订单手续费 =（订单总金额-订单佣金）*手续费率<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141047980.png"
            alt=""
          />
        </p>
        <p>
          <strong> 月账单详情</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141047332.png"
            alt=""
          /><br />
          订单收入总额=当月平台收入的订单金额之和+退回优惠券补贴+退回会员优惠券补贴<br />
          充值金额=当月平台用户的充值金额总和<br />
          支出总金额=当月应付商户金额+佣金+返还手续费金额<br />
          平台手续费收入总金额=当月平台总进账收入金额-当月平台总支出金额
        </p>
        <p>
          <strong>位置：平台后台&gt;财务&gt;转账设置</strong
          ><br />【转账设置】规则说明：<br />（1）
          商户最低提现金额、商户每笔最小提现额度、商户每笔最高提现金额
          均为转账申请提现的设置条件，如果设置了相关条件，需首先满足商户账户最低提现金额（即提现时商户余额不能少于的金额），其次需满足每笔最小提现额度，最后满足单笔最大提现额度，才可提现。<br />（2）设置时，不可以设置最小额度大于最高额度，比如：最小额度设置为1000元，最高额度设置为10元。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141047326.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a
            name="二、商户后台查看账单、收款方式设置"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>二、商户后台查看账单、收款方式设置</strong>
        </h3>
        <p>
          <strong>位置：商户后台&gt;财务&gt;账单管理</strong><br />
          （1）商户账期内收入：指结算周期内所有的订单支付收入、平台优惠券补贴、平台会员优惠券补贴；<br />
          （2）商户账期内支出：指结算周期内所有的订单退款、佣金、商户需付给平台的手续费支出之和；<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141049261.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141049863.png"
            alt=""
          /><br />
          <strong>日账单详情</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141049749.png"
            alt=""
          /><br />
          订单收入总额=当日商户收入的订单金额之和+平台优惠券补贴+平台会员优惠券补贴<br />
          支出总金额=当日应付平台手续费+佣金+退款（应付平台手续费和佣金笔数
          包括支付笔数和退款后返回笔数）<br />
          商户应入账金额=当日商户收入总金额-当日商户支出总金额<br />
          <strong>月账单详情</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141049316.png"
            alt=""
          /><br />
          订单收入总额=当月商户收入的订单金额之和+平台优惠券补贴+平台会员优惠券补贴<br />
          支出总金额=当月应付平台手续费+佣金+退款（应付平台手续费和佣金笔数
          包括支付笔数和退款后返回笔数）<br />
          商户应入账金额=当月商户收入总金额-当月商户支出总金额<br />
          <strong>位置：商户后台&gt;财务&gt;收款方式</strong><br />
          银行收款方式<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141049372.png"
            alt=""
          /><br />
          微信收款方式<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141049605.png"
            alt=""
          /><br />
          支付宝收款方式<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141049325.png"
            alt=""
          />
        </p>
        <p>
          <strong>三、申请转账流程</strong><br /><strong
            >第一步：商户申请</strong
          ><br /><strong>位置：商户后台&gt;财务&gt;转账记录</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141115273.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141115153.png"
            alt=""
          /><br /><strong>第二步：平台审核</strong><br /><strong
            >位置：平台后台&gt;财务&gt;转账记录&gt;待审核</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141117459.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141117691.png"
            alt=""
          /><br /><strong>第三步：线下转账并上传转账凭证</strong><br /><strong
            >位置：平台后台&gt;财务&gt;转账记录&gt;已审核</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141122459.png"
            alt=""
          /><br /><strong>第四步：商户查看</strong><br /><strong
            >位置：商户后台&gt;财务&gt;转账记录&gt;已审核</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141126137.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141126587.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
