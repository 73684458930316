var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video',{staticClass:"aspect-video",attrs:{"poster":require("@/assets/downloads/202309210927719.png"),"src":require("@/assets/downloads/202309071418822.mp4"),"controls":""}}),_c('div',{staticClass:"markdown-body editormd-html-preview",attrs:{"id":"editormd"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',[_vm._v("设置位置：平台后台>设置>商城设置")]),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309071433206.png"),"alt":""}})]),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309071439601.png"),"alt":""}})]),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309071433031.png"),"alt":""}})]),_vm._m(7),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309071433027.png"),"alt":""}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"移动端首页-为你推荐","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("移动端首页-为你推荐 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--strong-strong-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"一、功能说明","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_c('strong',[_vm._v("一、功能说明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',{staticClass:"prettyprint linenums prettyprinted"},[_c('ol',{staticClass:"linenums"},[_c('li',{staticClass:"L0"},[_c('code',[_c('span',{staticClass:"pun"},[_vm._v("可在平台后台设置是否在移动端商城首页展示【为你推荐】")])])]),_c('li',{staticClass:"L1"},[_c('code',[_c('span',{staticClass:"pun"},[_vm._v("默认推荐：指根据用户访问浏览的商品，系统自动推荐相关商品；")])])]),_c('li',{staticClass:"L2"},[_c('code',[_c('span',{staticClass:"pun"},[_vm._v("星级推荐：指平台后台，在商品管理列表，为每个商品设置的星级，")]),_c('span',{staticClass:"lit"},[_vm._v("5")]),_c('span',{staticClass:"pun"},[_vm._v("星为最高推荐级别，")]),_c('span',{staticClass:"lit"},[_vm._v("1")]),_c('span',{staticClass:"pun"},[_vm._v("星为最低推荐级别，商品展示顺序为：")]),_c('span',{staticClass:"lit"},[_vm._v("5")]),_c('span',{staticClass:"pun"},[_vm._v("星>")]),_c('span',{staticClass:"lit"},[_vm._v("4")]),_c('span',{staticClass:"pun"},[_vm._v("星>")]),_c('span',{staticClass:"lit"},[_vm._v("3")]),_c('span',{staticClass:"pun"},[_vm._v("星>")]),_c('span',{staticClass:"lit"},[_vm._v("2")]),_c('span',{staticClass:"pun"},[_vm._v("星>")]),_c('span',{staticClass:"lit"},[_vm._v("1")]),_c('span',{staticClass:"pun"},[_vm._v("星>无星；同样的商品排序顺序，星级高的商品展示在前；同样的推荐星级，商品排序大的商品展示在前。")])])]),_c('li',{staticClass:"L3"},[_c('code',[_c('span',{staticClass:"pun"},[_vm._v("创建时间：指根据商品创建先后时间展示，最新创建的商品展示在最前面。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"二、设置方法","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("二、设置方法 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3-u5546u54C1u661Fu7EA7u8BBEu7F6E"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"商品星级设置","one-link-mark":"yes"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("商品星级设置 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 位置：平台后台>商品管理>编辑"),_c('br'),_vm._v(" 商品展示顺序：星级>排序>销量"),_c('br'),_vm._v(" （1） 5星>4星>3星>2星>1星"),_c('br'),_vm._v(" （2） 排序是星级级别内的排序 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第一步")]),_vm._v("：点击编辑")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第二步")]),_vm._v("：点击星级，鼠标放上去，星级自动变灰或变橙色，设置后提交 ")])
}]

export { render, staticRenderFns }