<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/a1a06700586f982516eb1b4c597fdb97.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-">
          <a name="开启秒杀活动" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>开启秒杀活动</strong>
        </h2>
        <p><strong>位置：商户后台&gt;营销 &gt; 秒杀</strong></p>
        <h3 id="h3--strong-strong-">
          <a name="添加秒杀商品" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>添加秒杀商品</strong>
        </h3>
        <p>
          商户后台添加秒杀商品<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915102915.png"
            alt=""
          /><br />
          1.第一步需要选择商品，选择之后会自动填充商品信息，默认使用该普通商品的运费模板，也可以修改运费模板；<br />
          2.需手动选择秒杀活动日期，以及秒杀时间段，时间段选择为单选；<br />
          3.活动日期内最多购买次数设置：默认设置为1,不可小于等于0；<br />
          4.活动时间段内最多购买次数设置：默认设置为1,不可小于等于0；<br />
          5.活动日期内最多购买次数必须大于等于活动时间段内最多购买次数；<br />
          6.默认每次购买商品数量为1件；<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915102920.png"
            alt=""
          /><br />
          7..添加秒杀商品后必须要选择规格后才能上架;<br />
          8..秒杀商品支持添加多规格;<br />
          9.可修改秒杀商品的售价；<br />
          10.限量为整个秒杀活动的限量，例如设置限量20个，时间为3天，那意思就是该活动在3天内，可卖的总数为20个（不是每场，是总数)
        </p>
        <h3 id="h3--strong-strong-">
          <a name="审核/编辑秒杀商品" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>审核/编辑秒杀商品</strong>
        </h3>
        <p><strong>位置：平台后台&gt;营销&gt;秒杀&gt;秒杀管理</strong></p>
        <ol>
          <li>
            <p>
              平台后台审核秒杀商品<br />
              <img
                v-preview-image
                src="@/assets/downloads/20230915102925.png"
                alt=""
              /><br />
              <img
                v-preview-image
                src="@/assets/downloads/20230915102933.png"
                alt=""
              />
            </p>
          </li>
          <li>
            <p>
              点击“编辑” - 设置秒杀活动在移动端秒杀列表优先展示顺序<br />优先展示顺序：星级&gt;排序&gt;销量<br />规则：<br />（1）
              5星&gt;4星&gt;3星&gt;2星&gt;1星<br />（2）
              排序是星级级别内的排序<br />
              <img
                v-preview-image
                src="@/assets/downloads/20230915102944.png"
                alt=""
              /><br />
              <img
                v-preview-image
                src="@/assets/downloads/20230915102948.png"
                alt=""
              />
            </p>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
