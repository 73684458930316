<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--strong-strong-">
        <a name="手动打印订单" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>手动打印订单</strong>
      </h3>
      <p>
        位置：商户后台&gt;订单&gt;订单管理<br />后台可手动打印订单，未支付、待核销订单不能打印
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309140930645.png"
          alt=""
        />
      </p>
      <h3 id="h3--strong-strong-">
        <a name="自动打印订单" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>自动打印订单</strong>
      </h3>
      <p>
        位置：商户后台&gt;设置&gt;店铺配置<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309140930403.png"
          alt=""
        />
      </p>
      <h3 id="h3--strong-strong-">
        <a name="小票打印样图" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>小票打印样图</strong>
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309140931779.png"
          alt=""
        />
      </p>
      <!-- <h3 id="h3-u5C0Fu7968u6253u5370u673Au914Du7F6E">
        <a name="小票打印机配置" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>小票打印机配置
      </h3>
      <p>小票打印机 请选用 【易联云K4无线版 】</p>
      <p>
        配置详情：<a href="https://doc.crmeb.com/mer/mer2/4385"
          >https://doc.crmeb.com/mer/mer2/4385</a
        >
      </p> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
