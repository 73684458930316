<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-212014-15-57-346.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          店铺配置是在商户后台对店铺运营所需功能进行设置的地方，发票功能开启、警戒库存、PC端商户首页顶部图、退货联系信息、联系客服的方式。
        </p>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <p>
          <strong>位置：</strong
          >商户后台&gt;设置&gt;商户信息&gt;店铺信息<br /><strong>说明：</strong
          >一个商户对应一个店铺，店铺基本信息在此处设置<br />
          包括店铺背景图、店铺头像、店铺街背景图、店铺资质、店铺配送方式、提货点电话、地址、营业时间、商户简介和地址等信息设置。
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141141378.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141141495.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
