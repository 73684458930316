<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/3cf843ea19d4396dc8cf0a83c897fa5f.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--">
        <a name="一、功能介绍" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h2>
      <p>
        活动边框图是在商品列表的商品图上添加的一种渲染活动气氛与效果的边框图，增加商城节日活动气氛，在平台后台设置，移动端和PC端商品列表页展示效果。
      </p>
      <h2 id="h2--">
        <a name="二、操作流程" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>二、操作流程
      </h2>
      <p>第一步：添加活动边框图</p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915120049.png"
          alt=""
        />
      </p>
      <p>第二步：设置活动名称、活动边框图展示时间、添加活动边框图</p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915120049_1.png"
          alt=""
        />
      </p>
      <p>
        第三步：选择需要展示活动边框图的商品<br />说明：可选择全部商品、可指定选择某个商品、可按商品分类选择、可选择指定商户参与
      </p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915115548.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
