var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video',{staticClass:"aspect-video",attrs:{"poster":require("@/assets/downloads/202309210927719.png"),"src":require("@/assets/downloads/202309211002780.mp4"),"controls":""}}),_c('div',{attrs:{"id":"editormd"}},[_c('div',{staticClass:"right-body markdown-body editormd-html-preview"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('p',[_c('strong',[_vm._v("位置：移动端>店铺首页")]),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309140950315.png"),"alt":""}})]),_vm._m(5),_vm._m(6),_vm._m(7),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309140953745.png"),"alt":""}}),_c('em',[_vm._v("**")]),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309140954296.png"),"alt":""}})]),_c('p',[_c('strong',[_vm._v("PC后台客服接待")]),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309140954312.png"),"alt":""}}),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309140954712.png"),"alt":""}})]),_vm._m(8),_vm._m(9),_c('p',[_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309140956385.png"),"alt":""}}),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],staticClass:"w-1/3",attrs:{"src":require("@/assets/downloads/202309140956097.png"),"alt":""}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--strong-strong-"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"添加客服"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_c('strong',[_vm._v("添加客服")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 添加商家客服前请先确保"),_c('strong',[_vm._v("用户已关注商城公众号")]),_vm._v("(公众号是您或您的用户将要运营的已认证公众号<服务号> )，"),_c('strong',[_vm._v("并关注对应店铺")]),_vm._v("，之后按如下流程添加客服即可。"),_c('br'),_c('strong',[_vm._v("说明")]),_vm._v("：小程序客服使用的是万千实训系统自主开发的客服系统，绑定开放平台可以通过公众号推送消息，不绑定开放平台客服消息不提醒，客服可从移动端或PC管理后台查看消息并回复。"),_c('br'),_c('strong',[_vm._v("移动端客服入口位置")]),_vm._v("：移动端>个人中心>商家管理>客服记录"),_c('br'),_c('strong',[_vm._v(" PC管理后台位置")]),_vm._v("：商户后台>客服管理>进入工作台> ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("添加客服流程")]),_vm._v("：比如 添加用户A 为 1号店铺的 客服，请让A 用户先关注你已认证的公众号<服务号> ，再关注商城里的1号店铺，之后在1号店铺的商户后台 添加A用户为客服。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第一步：用户关注商城公众号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第二步：用户在移动端关注店铺")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第三步：管理员在商户后台设置添加客服")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("位置：商户后台>客服>客服列表>添加客服"),_c('br'),_vm._v(" 说明：")]),_vm._v("可添加客服、点击“编辑”设置客服权限、点击”进入工作台”登录进入客服后台聊天界面。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("账号状态")]),_vm._v("：开启：客服账号可用，关闭：客服账号不可用；"),_c('br'),_c('strong',[_vm._v("客服状态")]),_vm._v("：开启：客服在线，关闭：客服不在线；"),_c('br'),_c('strong',[_vm._v("订单通知")]),_vm._v("：开启：客服可以收到公众号订单模版消息通知；填写手机号后，配置短息提醒后，会收到订单短信提醒；关闭：则无消息通知； "),_c('br'),_c('strong',[_vm._v("订单管理")]),_vm._v("：开启：客服可查看订单统计数据，可进行订单改价、发货处理； 关闭：则无法在移动端查看统计数据及处理订单； "),_c('br'),_c('strong',[_vm._v("订单核销")]),_vm._v("：开启：客服可以在移动端 进行订单核销；关闭：则无法在移动端核销订单；"),_c('br'),_c('strong',[_vm._v("商品管理")]),_vm._v("：开启：客服可在移动端添加商品、编辑商品信息；关闭：则无法在移动端添加、编辑商品信息。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("客服接待分配规则")]),_vm._v("：当用户多时，随机分配给在线客服；如果之前已有交流记录的，优先分配给之前交流过的在线客服。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("一个客服可同时与多家店铺绑定")]),_c('br'),_vm._v("可先选择店铺 - 分别在各店铺处理客服消息、移动端订单管理、移动端订单核销、移动端商品管理"),_c('br')])
}]

export { render, staticRenderFns }