<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-212013-55-16-811.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          管理商户信息的列表，可手动添加商户、编辑商户信息、设置商户手续费、开启商户功能。
        </p>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <h3 id="h3-u624Bu52A8u6DFBu52A0u5546u6237">
          <a name="手动添加商户" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>手动添加商户
        </h3>
        <p>
          <strong>位置：</strong
          >平台后台&gt;商户&gt;商户管理&gt;商户列表<br /><strong> 说明：</strong
          >添加商户时，填写商户基本信息
        </p>
        <p>
          <strong>是否开启</strong>：可设置该商户是否开启；<br />
          <strong>产品审核</strong
          >：可设置商户下的商品是否手动审核，开启为手动审核商品，关闭为免审核；<br />
          <strong>是否推荐</strong>：可设置是否推荐到首页展示；<br /><strong
            >直播间审核</strong
          >：可设置直播间是否手动审核，开启为手动审核，关闭为免审核；<br /><strong
            >直播商品审核</strong
          >：可设置直播商品是否手动审核，开启为手动审核，关闭为免审核；<br /><strong
            >是否自营</strong
          >：可设置该商户是否为自营店，开启为自营，移动端及平台后台与该商户相关的商品、优惠券、订单都会显示自营标识，关闭则为非自营店，其店铺相关信息均不展示自营标识。<br /><strong>
            商户手续费：</strong
          >
          此处如果设置商户手续费，优先以此处设置的商户手续费为准；此处如果没有设置商户手续费，会默认取商户分类中设置的商户手续费。
        </p>
        <p>
          位置：平台后台&gt;商户&gt;商户管理&gt;商户列表<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141040229.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141040260.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
