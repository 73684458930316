<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309210954869.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="移动订单处理" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>移动订单处理</strong>
      </h2>
      <h3 id="h3--strong-strong-">
        <a name="设置权限" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>设置权限</strong>
      </h3>
      <p>
        <strong
          >位置：商户后台&gt;客服 &gt; 客服管理 &gt; 添加客服 &gt;
          用户&gt;搜索微信用户名称</strong
        ><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309140924243.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309140924338.png"
          alt=""
        /><br />设置客服及订单管理权限<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309140924682.png"
          alt=""
        />
      </p>
      <h3 id="h3-u8BA2u5355u7BA1u7406">
        <a name="订单管理" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>订单管理
      </h3>
      <p>
        <strong>位置：手机端&gt;我的&gt;个人中心</strong>
        (有订单管理权限的客服人员)<br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309140925634.png"
          alt=""
        /><br /><strong>处理订单</strong><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309140925874.png"
          alt=""
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309140925738.png"
          alt=""
        />
      </p>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309140925546.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
