<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309061619216.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          商品规格作为商品的基本要素，也是商品添加很重要的环节，可提前在此设置商品规格模板，在添加商品时直接选择使用商品规格模板。
        </p>
        <h3 id="h3--">
          <a name="二、操作步骤" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>二、操作步骤
        </h3>
        <p>
          <strong>第一步：添加商品规格</strong
          ><br />位置：商户后台&gt;商品&gt;商品规格&gt;添加商品规格
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309061615975.png"
            alt=""
          />
        </p>
        <p><strong>第二步：编辑商品规格模板名称和规格内容</strong></p>
        <p>可自定义字段名称：例如型号、颜色、尺寸等属性</p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309061615037.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061615805.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061615841.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309061615131.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
