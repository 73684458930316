<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/c9e1eed572921366f362038a8d39f5de.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="开启定金预售" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>开启定金预售</strong>
        </h3>
        <p>
          <strong>定金预售</strong
          >：商户添加预售活动商品，平台后台审核，活动发布，用户先在预售活动时间内支付小于等于20的预售定金，同时勾选预售协议，再在规定时间支付尾款，商家按规定时间发货。
        </p>
        <p>
          <strong>位置：商户后台&gt;营销&gt;预售&gt;添加定金预售商品</strong>
        </p>
        <ol>
          <li>
            当该商品被设置为预售商品时，普通商品自动在移动端不显示，后台普通商品管理中也不显示，仅在预售商品列表中显示，当该预售商品预售活动结束或预售商品删除后，自动恢复为普通商品，商品默认显示在普通商品的仓库中，状态处于下架状态，可手动修改为上架；
          </li>
          <li>
            在普通商品设置为预售商品时，已经购买该普通商品的订单不受影响；
          </li>
          <li>
            定金预售商品，支付的定金不退款，如需退款，需付完尾款后走正常退货退款流程；
          </li>
          <li>商品预售价格设置： 商品预售价格大于0，小于等于商品销售价格；</li>
          <li>
            商品预售定金设置： 定金设置范围大于0，小于等于商品预售价格的20；
          </li>
          <li>
            尾款显示：商品预售价格、定金范围设置好后，尾款金额会自动生成；
          </li>
          <li>
            商品预售定金支付时间设置：定金支付开始时间=预售活动开始时间，定金支付结束时间=活动结束时间；
          </li>
          <li>
            商品尾款支付时间设置：尾款支付开始时间大于定金支付结束时间；采用时间选择插件，具体到年月日时分秒；
          </li>
          <li>
            发货时间设置：可选择设置
            尾款支付后多少天，单位：天，最小值1，最大值不限，大于等于1；尾款支付后**天：指依据尾款支付完成后设置发货时间；<br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915145743.png"
              alt=""
            /><br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915145751.png"
              alt=""
            /><br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915145757.png"
              alt=""
            />
          </li>
          <!-- <li>
            <p>尾款支付时间开始时，短信自动提醒所有预付过定金的用户，预售商品可以支付尾款了，请前往支付。<br><strong>尾款支付短信提醒开启</strong>
              
              <br><strong>位置：平台后台&gt;设置&gt;短信设置&gt;短信配置</strong><br>预售尾款支付通知请选择开启<br> <img
          v-preview-image
                src="@/assets/downloads/20230915145820.png"
                alt=""
              ></p>
          </li> -->
          <li>
            <p>
              一个商品多种规格可分别设置预售价格；可设置单个规格预售，也可设置多个规格预售；
            </p>
          </li>
          <li>
            一个商品有多种规格，但只选1个规格或部分规格预售时，其他未选的规格也会在移动端界面显示，但不能购买；
          </li>
          <li>
            定金预售商品可参与分销返佣，
            与普通商品一样，尾款支付完成后才可进行分佣，仅支付定金时不参与分佣。
          </li>
          <li>预售一次可购买多个数量的预售商品，退款时全部退款；</li>
          <li>
            添加定金预售商品时可设置限量库存、价格等，预售未开始的可以进行修改，修改后需重新提交平台审核，预售进行中的商品不可以修改；
          </li>
          <li>定金预售，在支付尾款时可使用优惠券；</li>
          <li>
            未支付定金的，在订单关闭时间未到时，活动结束或商品下架，可以继续支付定金；
          </li>
          <li>定金预售可到店自提，定金预售的订单核销码，在尾款付完后显示。</li>
        </ol>
        <h3 id="h3--strong-strong-em-em-">
          <a name=" 审核/编辑定金预售**" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong> </strong>审核/编辑定金预售
        </h3>
        <p><strong>位置：平台后台&gt;营销&gt;预售&gt;定金预售</strong></p>
        <ol>
          <li>
            平台后台审核定金预售商品<br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915145820.png"
              alt=""
            /><br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915145831.png"
              alt=""
            />
          </li>
          <li>
            点击“编辑” - 设置定金预售活动在移动端预售列表优先展示顺序<br />优先展示顺序：星级&gt;排序&gt;销量<br />规则：<br />（1）
            5星&gt;4星&gt;3星&gt;2星&gt;1星<br />（2） 排序是星级级别内的排序<br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915145837.png"
              alt=""
            /><br />
            <img
              v-preview-image
              src="@/assets/downloads/20230915145843.png"
              alt=""
            />
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
