<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309071500520.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--">
        <a name="一、功能介绍" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h2>
      <p>
        商品口令：开启此功能，用户在分享商品时可选择生成口令并复制，并发送给好友，好友复制口令后，打开商城时会自动解析看到该商品信息，是分享商品的一种方式。
      </p>
      <h2 id="h2--">
        <a name="二、使用方法" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>二、使用方法
      </h2>
      <h3 id="h3--gt-gt-gt-">
        <a
          name="第一步：平台后台>设置>商城设置>商品设置"
          class="reference-link"
          one-link-mark="yes"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >第一步：平台后台&gt;设置&gt;商城设置&gt;商品设置
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309071453700.png"
          alt=""
        />
      </p>
      <h3 id="h3--gt-gt-gt-">
        <a
          name="第二步：移动端商城>商品详情>分享>生成口令"
          class="reference-link"
          one-link-mark="yes"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >第二步：移动端商城&gt;商品详情&gt;分享&gt;生成口令
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309071454081.png"
          alt=""
        />
      </p>
      <h3 id="h3--">
        <a
          name="第三步：复制口令"
          class="reference-link"
          one-link-mark="yes"
        ></a
        ><span class="header-link octicon octicon-link"></span>第三步：复制口令
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309191751930.png"
          alt=""
        />
      </p>
      <h3 id="h3--">
        <a
          name="第四步：复制成功"
          class="reference-link"
          one-link-mark="yes"
        ></a
        ><span class="header-link octicon octicon-link"></span>第四步：复制成功
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309071454056.png"
          alt=""
        />
      </p>
      <h3 id="h3--">
        <a
          name="第五步：发送给好友，好友打开商城自动解析商品"
          class="reference-link"
          one-link-mark="yes"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >第五步：发送给好友，好友打开商城自动解析商品
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309071454788.png"
          alt=""
        />
      </p>
      <h2 id="h2--">
        <a name="三、功能说明" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>三、功能说明
      </h2>
      <p>该功能开启，适用于所有普通商品和活动商品。</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
