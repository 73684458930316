<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h3 id="h3--strong-strong-">
        <a name="商城主题风格" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>商城主题风格</strong>
      </h3>
      <p>
        万千实训系统多商户商城v2.0新增主题风格切换功能，共有六种颜色风格可切换，切换之后除商城首页，商城整体的风格样式即可改变。<br />位置：平台后台&gt;商城装修&gt;主题风格<br />
        <img
          v-preview-image
          src="/uploads/mer/mer/20220422/d49ee731f0603863b5c0cee879e59ce4.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
