<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-212014-04-01-969.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="开启发票" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>开启发票</strong>
        </h3>
        <p>
          <strong>位置：商户后台&gt;设置&gt;店铺配置&gt;开启发票</strong><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141129681.png"
            alt=""
          />
        </p>
        <h2 id="h2--strong-strong-">
          <a name="提交开票申请" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>提交开票申请</strong>
        </h2>
        <p>
          <strong>位置：移动端&gt;提交订单</strong
          ><br />默认显示不开发票，点击可选择发票类型，发票抬头信息，初次使用需填写提交发票信息；之后使用只需选择编辑好的发票信息；点击问号标识可看到发票相关说明。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141130211.png"
            alt=""
            class="w-1/3"
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141130505.png"
            alt=""
            class="w-1/3"
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141130210.png"
            alt=""
            class="w-1/3"
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="后台开票管理" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>后台开票管理</strong>
        </h3>
        <p>
          <strong>位置：商户后台&gt;财务&gt;发票管理</strong><br />第一步：
          用户提交开票申请后，信息展示在发票管理列表，没有发票金额和发票单号；<br />第二步：
          财务人员 点击“开票”，生成发票金额和发票单号；开票状态: 未开；<br />第三步：发票开好后，点击“编辑”：填写发票号，
          开票状态：自动从未开变为已开<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141131305.png"
            alt=""
          /><br />不同订单但是同一个发票类型、发票抬头信息的开票申请，在用户同意的情况下，可合并开一张发票<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141131417.png"
            alt=""
          />
        </p>
        <h2 id="h2--strong-strong-">
          <a name="移动端发票管理" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>移动端发票管理</strong>
        </h2>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141131753.png"
            alt=""
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141131643.png"
            alt=""
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141131665.png"
            alt=""
          />
        </p>
        <h2 id="h2--strong-strong-">
          <a name="平台后台发票管理" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>平台后台发票管理</strong>
        </h2>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141132738.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141132802.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
