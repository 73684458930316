<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2-u7BA1u7406u540Eu53F0u6D88u606Fu5F39u8DF3u63D0u9192">
        <a name="管理后台消息弹跳提醒" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >管理后台消息弹跳提醒
      </h2>
      <h3 id="h3--">
        <a name="一、功能介绍" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>一、功能介绍
      </h3>
      <p>
        该功能主要是为方便平台商城管理人员与商家管理人员进行后台事务处理，比如当有商户入驻，提醒给平台管理者；当有商品审核通过，提醒给商家管理者，且都是在管理后台弹跳消息方式提醒。
      </p>
      <h3 id="h3--">
        <a name="二、功能说明" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>二、功能说明
      </h3>
      <p>
        <strong>平台后台弹跳提醒场景及点击消息后跳转位置</strong><br />
        <img
          v-preview-image
          src="@/assets/downloads/b28748090f746ca9d0dea1031054426a.png"
          alt=""
        />
      </p>
      <p><strong>商户后台弹跳提醒场景及点击消息后跳转位置</strong></p>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/42b9a82f7de23355b7688ab9f247edf0.webp"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
