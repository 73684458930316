<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309210932307.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-">
          <a name="用户反馈" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>用户反馈</strong>
        </h2>
        <p>位置：平台后台>用户>用户反馈>反馈列表</p>
        <h3 id="h3-u7528u6237u5728u79FBu52A8u7AEFu53CDu9988u95EEu9898">
          <a name="用户在移动端反馈问题" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          >用户在移动端反馈问题
        </h3>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309131545123.png"
            alt=""
          />
        </p>
        <h3 id="h3--">
          <a
            name="平台可以在后台进行回复，以及联系方式回访等"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          >平台可以在后台进行回复，以及联系方式回访等
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309131546184.png"
            alt=""
          />
        </p>
        <p>在移动端可以查看自己的反馈记录，及平台的回复结果</p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309131546496.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
