<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="账户资金" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>账户资金</strong>
      </h2>
      <h3 id="h3-1-">
        <a name="1、资金说明" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>1、资金说明
      </h3>
      <p>账户余额 = 用户充值金额 + 后台充值金额<br />佣金 = 推广员推广收入</p>
      <h3 id="h3-2-">
        <a name="2、规则说明" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>2、规则说明
      </h3>
      <p>
        （1）余额不可提现，佣金可申请提现<br />（2）佣金可通过 “余额充值”
        的方式转入余额<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309191022572.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
