<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309210932778.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="商户用户列表" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>商户用户列表</strong>
        </h3>
        <p>
          <strong>位置：商户后台&gt;用户&gt;用户管理</strong
          ><br />用户关注店铺或在商户店铺下单后，用户信息会展示在商户后台<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131547895.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="设置用户标签" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>设置用户标签</strong>
        </h3>
        <p>
          <strong>位置：商户后台>用户>标签管理>手动标签</strong
          ><br />手动标签：先添加标签分类，再为用户手动打标签<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131548305.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131548808.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131548095.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-gt-gt-strong-">
          <a
            name="位置：商户后台>用户>标签管理>自动标签"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>位置：商户后台&gt;用户&gt;标签管理&gt;自动标签</strong>
        </h3>
        <p>
          自动标签：<br />1.先设置自动标签条件规则，设置好后，用户会根据标签条件自动带标签，自动标签类别包括消费订单次数和订单金额；<br />2.查看用户自动标签时，请先点击更新，再查看，以保证每个标签规则下的用户数为最新数据。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131555527.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131555318.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131555471.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131555999.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
