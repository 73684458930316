<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-252015-30-05-141.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          1.付费会员是指用户通过付费购买到的商城会员资格，付费会员权益都一样，只是会员期限不一样付费金额不一样。<br />
          2.万千实训系统多商户系统付费会员功能核心：平台设置付费会员类型、权益，商户可选择是否开启付费会员并设置商<br />
          品的付费会员价，商户承担付费会员价的成本，平台承担付费会员优惠券的成本。<br />
          3.会员类型根据业务可设置，包括免费试用（后台可设置天数）、周卡、月卡、季卡、年卡、永久。<br />
          4.会员权益包括会员专属价、签到返利、消费返利、会员专属客服、经验翻倍、会员优惠券。
        </p>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <p><strong>第一步：商户开启付费会员</strong></p>
        <p>
          位置：商户后台&gt;设置&gt;付费会员设置<br />说明：<br />
          （1）商户可通过开启或关闭，自主决定是否以付费会员价为平台付费会员提供消费商品；<br />
          （2）商户设置付费会员价折扣，承担付费会员价成本；<br />
          （3）商户可设置付费会员价是否与店铺优惠券叠加，并承担相关成本；<br />
          （4）商户后台在此处设置付费会员折扣价后，所有商品默认不设置会员价；<br />
          （5）部分商品需参与或不参与，可通过批量设置付费会员价进行设置；<br />
          （6）个别商品需参与或不参与，可在商品详情编辑
          自定义会员价；自定义设置后，改商户后台总折扣不受影响。
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/20230915145359.png"
            alt=""
          />
        </p>
        <p><strong>第二步：批量设置付费会员价</strong></p>
        <p>
          位置：商户后台>商品>商品列表<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309251538091.png"
            alt=""
          />
        </p>
        <p><strong> 第三步：单独设置付费会员价</strong></p>
        <p>
          位置：商户后台&gt;商品&gt;编辑<br />
          <img
            v-preview-image
            src="@/assets/downloads/20230915145242.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
