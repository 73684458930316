import Vue from 'vue';
import VueRouter from 'vue-router';
import index from '@/views/index/index';
Vue.use(VueRouter);


let children = []
const requireComponent = require.context('../views/page', true, /\.vue$/);
requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName);

  // 获取组件的名称（去除文件名的扩展名）
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');
  children.push({
    path: `/${componentName}`,
    name: componentName,
    component: componentConfig.default || componentConfig,
  })

});


const router = new VueRouter({
  routes:
    [
      {
        path: '/',
        component: index,
        redirect: 'index',
        children
      },
    ]
  ,
});


export default router;
