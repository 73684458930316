<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309210937745.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <!-- <h4 id="h4--strong-v1-7-3-strong-"><a
            name="v1.7.3及以前版本"
            class="reference-link"
          ></a><span class="header-link octicon octicon-link"></span><strong>v1.7.3及以前版本</strong></h4> -->
        <h3 id="h3-1-">
          <a name="1、微信初次登录" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>1、微信初次登录
        </h3>
        <p>
          获取授权信息后，可绑定手机号<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309131620239.png"
            alt=""
          />
        </p>
        <h4 id="h4--1-h5-">
          <a name="（1）该手机号未注册过H5商城" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          >（1）该手机号未注册过H5商城
        </h4>
        <p>
          若公众号、小程序绑定开放平台，则小程序、公众号、H5账户数据同步；<br />若公众号、小程序未绑定开放平台，则公众号、H5账户同步/小程序、H5账户同步。（未绑定开放平台时一个手机号不能同时绑定公众号和小程序）
        </p>
        <h4 id="h4--2-h5-">
          <a name="（2）该手机号已注册过H5商城" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          >（2）该手机号已注册过H5商城
        </h4>
        <p>
          若公众号、小程序绑定开放平台，则小程序、公众号数据同步，H5数据独立存在；可切换账号。<br />若公众号、小程序未绑定开放平台，则三者数据独立；可切换账号。<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309131621989.png"
            alt=""
          />
        </p>
        <h3 id="h3-2-h5-">
          <a name="2、H5初次登录" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>2、H5初次登录
        </h3>
        <p>
          使用账号/短信登录<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309131621791.png"
            alt=""
          />
        </p>
        <h4 id="h4--1-">
          <a name="（1）手机号未绑定微信" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          >（1）手机号未绑定微信
        </h4>
        <p>H5账户数据独立</p>
        <h4 id="h4--2-">
          <a name="（2）手机号已绑定微信" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          >（2）手机号已绑定微信
        </h4>
        <p>
          手机号登录需要修改密码，密码不能为“123456”<br />若公众号、小程序绑定开放平台，则小程序、公众号、H5账户数据同步；<br />若公众号、小程序未绑定开放平台，则公众号、H5账户同步/小程序、H5账户同步。
        </p>

        <!-- <h4 id="h4--strong-v2-0-strong-"><a
            name="v2.0版本"
            class="reference-link"
          ></a><span class="header-link octicon octicon-link"></span><strong>v2.0版本</strong>
        </h4> -->
        <p>
          <!-- <p>v2.0 在原版本基础上 -->
          新增强制绑定手机号登录功能，此功能在平台后台可开启或关闭，开启则启用强制绑定手机号模式，

          <!-- 关闭则启用以上v1.7.3及以前版本的方式。 -->
          <br />位置：平台后台&gt;设置&gt;系统设置&gt;移动端设置<br />说明：<br />
          （1）
          强制绑定手机号登录，指用户登录时从微信进入商城时，自动弹跳微信授权登录，
          获取个人微信手机号确认弹窗；用户登录时从小程序进入商城，自动弹跳小程序授权允许弹窗
          ，并强制手机号登录；<br />（2）
          必须强制绑定手机号才可登录成功，否则不能登录成功；<br />
          （3）
          一个手机号绑定一个用户，一个手机号不可绑定多次；一个用户不能绑定多个手机号。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309131621390.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
