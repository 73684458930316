<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/20230918161837.mp4"
      controls
    ></video>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="编辑佣金说明" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>编辑佣金说明</strong>
      </h2>
      <p>
        设置位置：平台后台&gt;分销&gt;佣金说明<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309121738884.png"
          alt=""
        /><br />移动端展示位置：移动端&gt;个人中心&gt;分销推广<br />
        <img
          v-preview-image
          class="w-1/3"
          v-preview-image
          src="@/assets/downloads/202309121738853.png"
          alt=""
        /><br />
        <img
          v-preview-image
          class="w-1/3"
          v-preview-image
          src="@/assets/downloads/202309121738291.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
