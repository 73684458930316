<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam6.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-pc-strong-">
          <a name=" PC端平台页面配置" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong> PC端平台页面配置</strong>
        </h2>
        <h3 id="h3--strong-1-pc-strong-">
          <a name="1. PC端首页导航" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>1. PC端首页导航</strong>
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141740428.png"
            alt=""
          /><br /><strong
            >配置位置：平台后台&gt;设置&gt;PC端配置&gt;页面配置&gt;首页导航</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141740476.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-2-pc-strong-">
          <a name="2. PC首页轮播图" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>2. PC首页轮播图</strong>
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141741427.png"
            alt=""
          /><br /><strong
            >配置位置：平台后台&gt;设置&gt;PC端配置&gt;页面配置&gt;首页轮播图</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141741654.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-3-pc-strong-">
          <a name="3. PC首页分类广场广告图" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>3. PC首页分类广场广告图</strong>
        </h3>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141741634.png"
            alt=""
          /><br /><strong
            >配置位置：平台后台&gt;设置&gt;PC端配置&gt;页面配置&gt;分类广场</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141741892.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-4-pc-strong-">
          <a name="4. PC端-新品推荐" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>4. PC端-新品推荐</strong>
        </h3>
        <p>
          首页展示-首发新品：
          <img
            v-preview-image
            src="@/assets/downloads/202309141808364.png"
            alt=""
          /><br />首发新品页面：
          <img
            v-preview-image
            src="@/assets/downloads/202309141808611.png"
            alt=""
          /><br /><strong
            >配置位置：平台后台&gt;设置&gt;PC端配置&gt;页面配置&gt;新品推荐轮播图</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141808857.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-5-pc-strong-">
          <a name="5. PC端-热门榜单" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>5. PC端-热门榜单</strong>
        </h3>
        <p>
          首页展示-热门榜单：
          <img
            v-preview-image
            src="@/assets/downloads/202309141810721.png"
            alt=""
          /><br />热门榜单页面：
          <img
            v-preview-image
            src="@/assets/downloads/202309141810154.png"
            alt=""
          /><br /><strong
            >配置位置：平台后台&gt;设置&gt;PC端配置&gt;页面配置&gt;热门榜单轮播图</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141811809.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-6-pc-strong-">
          <a name="6. PC端-精品推荐" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>6. PC端-精品推荐</strong>
        </h3>
        <p>
          首页展示-精品推荐：
          <img
            v-preview-image
            src="@/assets/downloads/202309141811897.png"
            alt=""
          /><br />精品推荐页面：
          <img
            v-preview-image
            src="@/assets/downloads/202309141812184.png"
            alt=""
          /><br /><strong
            >配置位置：平台后台&gt;设置&gt;PC端配置&gt;页面配置&gt;精品推荐轮播图</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141812049.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-7-pc-strong-">
          <a name="7. PC端-推荐单品" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>7. PC端-推荐单品</strong>
        </h3>
        <p>
          首页展示-推荐单品：
          <img
            v-preview-image
            src="@/assets/downloads/202309141813291.png"
            alt=""
          /><br />推荐单品页面：
          <img
            v-preview-image
            src="@/assets/downloads/202309141813386.png"
            alt=""
          /><br /><strong
            >配置位置：平台后台&gt;设置&gt;PC端配置&gt;页面配置&gt;推荐单品轮播图</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141813737.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-8-pc-strong-">
          <a name="8. PC端-首页底部版权信息" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>8. PC端-首页底部版权信息</strong>
        </h3>
        <p>
          首页展示：
          <img
            v-preview-image
            src="@/assets/downloads/20230914182249.png"
            alt=""
          /><br /><strong
            >配置位置：平台后台&gt;设置&gt;PC端配置&gt;页面配置&gt;首页底部版权信息</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/20230914182253.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
