<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-212009-37-02-2411.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-">
          <a name="后台订单核销" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>后台订单核销</strong>
        </h3>
        <p>
          <strong>位置：订单 &gt; 订单管理 </strong><br /><strong
            >1.后台核销码核销&gt;去核销</strong
          ><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141650593.png"
            alt=""
          />
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141650385.png"
            alt=""
          />
        </p>

        <h3 id="h3--v2-0-">
          <a name="手机端订单核销" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>手机端订单核销
        </h3>
        <p>位置：手机端&gt;客服个人中心&gt;商家管理中心&gt;订单核销</p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141650864.png"
            alt=""
          />
        </p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141650862.png"
            alt=""
          />
        </p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141650871.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
