<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-212014-37-54-686.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h2>
        <p>
          店铺街是多商户商城集中展示各店铺的功能，万千实训系统移动端商城首页品牌好店下方
          点击 “更多店铺” 可进入店铺街浏览。
        </p>
        <!-- <h3 id="h3-u8BF7u6C42u5730u5740u53C2u6570u914Du7F6E"><a
            name="请求地址参数配置"
            class="reference-link"
          ></a><span class="header-link octicon octicon-link"></span>
          请求地址参数配置</h3>
        <p> 多商户V2.1.1版本，移动端店铺街请求增加了参数，<code>pages/store/shopStreet/index?cate_id=&amp;type_id=</code>,<br><code>cate_id</code>传的是商户分类ID， <code>type_id</code>传的是店铺类型ID，默认不传。</p>
        <p>如果需要diy跳转到指定的类型或分类，即可在链接中增加这两个参数；如：pages/store/shopStreet/index?cate_id=1&amp;type_id=2，就是会跳转到商户分类为1店铺类型为2的商户列表。

        
        </p> -->

        <h2 id="h2--">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h2>
        <h3 id="h3-2-1-">
          <a name="2.1 店铺街设置" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>2.1 店铺街设置
        </h3>
        <p>
          <strong>位置</strong
          >：平台后台&gt;装修&gt;页面装修&gt;店铺街<br /><strong>说明</strong
          >：<br />
          （1）店铺街共有3种不同样式可选择，同时可根据运营业务选择是否开启店铺街距离展示；<br />
          （2）店铺街背景图，需要在商户后台&gt;设置&gt;商户信息&gt;店铺信息
          页面中上传<br />
          （3）选择样式1或2时，店铺街背景图格式为：710 x
          134px；选择样式3时，店铺街背景图格式为：710 x 460px；<br />
          建议平台或商家提前准备图片素材，以保证商城展示效果。
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141359454.png"
            alt=""
          />
        </p>
        <h3 id="h3-2-2-">
          <a name="2.2 移动端展示样式" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>2.2
          移动端展示样式
        </h3>
        <p>
          <strong>位置</strong>：移动端&gt;首页&gt;更多店铺<br /><strong
            >说明</strong
          >：关闭：当关闭时，在店铺街、搜索店铺列表不展示店铺距离、店铺地图定位、用户定位；<br />
          开启：当开启时，在店铺街、搜索店铺列表展示店铺距离、店铺地图定位、用户定位。
        </p>
        <p>
          <strong>店铺街排序说明</strong><br />
          默认：按平台在后台给店铺的排序，默认排序最大的店铺在最前，默认菜单下店铺列表排序优先级：推荐&gt;排序&gt;销量；<br />
          销量：指店铺所有商品销量之和，销量最高的店铺在前，销量菜单下店铺列表排序优先级：推荐&gt;销量&gt;排序；<br />
          好评：指店铺好评分，好评分越高，排序在前，好评菜单下店铺列表排序优先级：
          推荐&gt;评分&gt;排序<br />
          距离：指店铺距用户当前定位距离越近，店铺展示位置越靠前。
        </p>
        <p>
          备注：推荐是指平台在后台对店铺是否开启推荐，开启推荐就会最优先展示。
        </p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141635599.png"
            alt=""
          />
        </p>
        <p><strong>店铺街-距离关闭</strong></p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141636896.png"
            alt=""
          />
        </p>
        <p><strong>店铺街-距离开启</strong></p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141636089.png"
            alt=""
          />
        </p>
        <p><strong>店铺搜索-距离关闭</strong></p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141637336.png"
            alt=""
          />
        </p>
        <p><strong>店铺搜索-距离开启</strong></p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141637073.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
