<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/78ea0001a7dfe32668fbe6d44257dc32.mp4"
      controls
    ></video>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-strong-">
        <a name="等级会员管理" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>等级会员管理</strong>
      </h2>
      <p><strong>位置：平台后台&gt;用户&gt;用户等级&gt;等级管理</strong></p>
      <h3 id="h3-u5E73u53F0u5148u6DFBu52A0u4F1Au5458u7684u7B49u7EA7u4FE1u606F">
        <a name="平台先添加会员的等级信息" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >平台先添加会员的等级信息
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915143859.png"
          alt=""
        />
      </p>
      <h3 id="h3--0-1-2-">
        <a
          name="添加页面（会员等级一定要是连续的数字：0，1，2）"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >添加页面（会员等级一定要是连续的数字：0，1，2）
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915143907.png"
          alt=""
        /><br />添加完成可以在列表中编辑
      </p>
      <h3 id="h3-u5728u79FBu52A8u5C55u793A">
        <a name="在移动展示" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>在移动展示
      </h3>
      <p>
        <img
          v-preview-image
          class="w-1/3"
          src="@/assets/downloads/202309210941632.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
