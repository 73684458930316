<template>
  <div>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <p>
          店铺活动页面仅展示该店铺创建的所有营销活动，包括秒杀活动、拼团活动、助力活动、预售活动等
        </p>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <p><strong>商户后台设置活动</strong></p>
        <p>
          <strong
            >注：商户后台添加各营销活动，平台后台审核通过后会展示在商城及店铺活动页。</strong
          >
        </p>
        <p></p>
        <div class="flex flex-col gap-3">
          <router-link to="/76" class="text-blue-600"
            >秒杀活动设置详情</router-link
          >
          <router-link to="/88" class="text-blue-600"
            >拼团活动设置详情</router-link
          >
          <router-link to="/87" class="text-blue-600"
            >助力活动设置详情</router-link
          >
          预售活动设置详情：
          <router-link to="/81" class="text-blue-600">全款预售</router-link>

          <router-link to="/82" class="text-blue-600">定金预售</router-link>
          <router-link to="/79" class="text-blue-600"
            >直播设置详情：</router-link
          >
          <router-link to="/85" class="text-blue-600"
            >专题活动设置详情：</router-link
          >
        </div>

        <p><strong>移动端活动展示位置</strong></p>
        <p>
          <strong>位置</strong>：移动端&gt;店铺首页&gt;活动<br /><strong
            >说明</strong
          >：<br />
          1 . 店铺页底部 “专题”菜单，<br />
          2 . “活动”页面会展示：秒杀、拼团、助力、预售等营销活动商品；<br />
          3 . 活动商品展示顺序为：商户后台设置排序&gt;创建时间<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141640801.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
