<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam202023-09-212014-35-38-822666.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--">
          <a name="一、功能介绍" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>一、功能介绍
        </h3>
        <!-- 配置好短信通知， -->
        <p>
          商户入驻是多商户商城系统招商运营的第一重要功能，需在后台先设置好商户分类、店铺类型、入驻协议、才可在移动端、PC
          端顺利完成商户入驻。
        </p>
        <h3 id="h3--">
          <a name="二、操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>二、操作流程
        </h3>
        <h3 id="h3-2-1-">
          <a name="2.1 商户入驻后台配置" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>2.1
          商户入驻后台配置
        </h3>
        <p><strong>第一步：添加商户分类</strong></p>
        <p>
          <strong>位置：</strong
          >平台后台&gt;商户&gt;商户管理&gt;商户分类<br /><strong>说明</strong
          >：商户分类处可设置商户手续费，如果单个商户设置了商户手续费，以单个商户设置的为准，如果单个商户未设置商户手续费，则默认取商户分类这里设置的商户手续费。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141029277.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141029845.png"
            alt=""
          />
        </p>
        <p><strong>第二步：编辑入驻协议，并提交。</strong></p>
        <p>
          <strong>位置</strong
          >平台后台&gt;设置&gt;协议与规则&gt;商户入驻申请协议<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141031846.png"
            alt=""
          />
        </p>
        <p>
          <strong>第三步：添加店铺类型</strong><br /><strong>位置</strong
          >：平台后台&gt;商户&gt;店铺管理&gt;店铺类型<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141031821.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141031597.png"
            alt=""
          />
        </p>
        <p>
          <strong>第四步：添加店铺类型说明</strong><br /><strong>位置</strong
          >：平台后台&gt;商户&gt;店铺管理&gt;店铺类型说明<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141031326.png"
            alt=""
          />
        </p>
        <h3 id="h3-2-2-">
          <a name="2.2 移动端商户申请入驻" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>2.2
          移动端商户申请入驻
        </h3>
        <p><strong>提交商户入驻信息</strong></p>
        <p>
          <strong>位置：</strong
          >手机端&gt;我的&gt;个人中心&gt;商家入驻<br /><strong>说明：</strong
          >同一个人可多次提交，每次提交建议使用不同手机号<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141034326.png"
            alt=""
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141034342.png"
            alt=""
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141034158.png"
            alt=""
          />
        </p>
        <h3 id="h3-2-3-">
          <a name="2.3 平台后台审核并创建店铺" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>2.3
          平台后台审核并创建店铺
        </h3>
        <p>
          <strong>平台查看、审核商户提交的入驻申请</strong><br /><strong
            >位置：</strong
          >平台后台&gt;商户&gt;商户管理&gt;商户入驻申请<br /><strong
            >说明：</strong
          ><br />审核同意：有两种选择<br />第一：可自动创建商户，自动创建商户即在商户列表可看到该商户信息，并编辑商户信息；自动创建商户，商户账号默认为手机号；<br />第二：不自动创建商户，之后在商户列表手动添加商户，并编辑商户信息；手动添加商户，商户帐号可由平台自行编辑。
        </p>
        <p>审核拒绝：请填写拒绝原因</p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309141035269.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141035309.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141035132.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309141035653.png"
            alt=""
          />
        </p>
        <h3 id="h3-2-4-">
          <a name="2.4 入驻商户查看入驻审核状态" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span>2.4
          入驻商户查看入驻审核状态
        </h3>
        <p>
          <strong>位置：</strong
          >手机端&gt;我的&gt;个人中心&gt;商家入驻<br /><strong>说明：</strong>
          <br />待审核状态时，可以重新编辑所提交信息。<br />说明：审核失败后，重新修改的提交记录将覆盖之前的提交记录。
        </p>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141038541.png"
            alt=""
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141039429.png"
            alt=""
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309141039836.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
