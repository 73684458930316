<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309211006459.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-">
          <a name="订单退款操作流程" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>订单退款</strong>操作流程
        </h2>
        <h4 id="h4--">
          <a name="用户申请退款，后台审核处理退款" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          >用户申请退款，后台审核处理退款
        </h4>
        <p>
          <strong
            >1. 订单未发货或发货后用户均可主动发起退款或退货退款申请；</strong
          ><br />同一商户同一商品一次购买多个，发货前用户端申请部分退货或全部退款，可点击“查看退款单”处理订单<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309140927682.png"
            alt=""
          /><br />同一商户同一商品一次购买多个，发货后用户端申请部分退货或全部退货退款，可点击“查看退款单”处理退款<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309140927308.png"
            alt=""
          />
        </p>
        <p>
          <strong>2. 后台退款</strong><br />位置：商户后台&gt;订单&gt;退款单
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309140928053.png"
            alt=""
          />
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309140928643.png"
            alt=""
          />
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309140928322.png"
            alt=""
          />
        </p>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309140929003.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
