<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam 2023-09-21 10-09-39-038.mp4"
      controls
    ></video>
    <!-- bandicam 2023-09-21 10-09-39-038 -->

    <div id="editormd" class="markdown-body editormd-html-preview">
      <p>
        <strong>自动回复</strong
        ><br />位置：商户后台&gt;客服&gt;自动回复<br />说明：自动回复为精准匹配，设置后，用户咨询信息如与设置信息完全匹配时，会自动触发回复信息。<br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141000289.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/202309141000087.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
