<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/202309061506676.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--">
        <a
          name="后台点击“商品”—“商品分类”添加商品分类"
          class="reference-link"
          one-link-mark="yes"
        ></a
        ><span class="header-link octicon octicon-link"></span
        >后台点击“商品”—“商品分类”添加商品分类
      </h2>
      <ol>
        <li>添加一级分类：</li>
      </ol>
      <ul>
        <li>打开“商品分类”页，点击左上角“添加商品分类”</li>
        <li>在弹框中，父级选择<strong>顶级菜单</strong></li>
        <li>输入分类名称、图片、排序、状态（前端显示或隐藏）</li>
        <li>提交保存</li>
      </ul>
      <ol>
        <li>添加二级子分类：</li>
      </ol>
      <ul>
        <li>点击左上角“添加商品分类”</li>
        <li>在弹框中，父级选择<strong>对应的一级分类</strong></li>
        <li>输入分类名称、图片、排序、状态（前端显示或隐藏）</li>
        <li>提交保存</li>
      </ul>
      <ol>
        <li>添加三级子分类：</li>
      </ol>
      <ul>
        <li>点击左上角“添加商品分类”</li>
        <li>在弹框中，父级选择<strong>对应的二级分类</strong></li>
        <li>输入分类名称、图片、排序、状态（前端显示或隐藏）</li>
        <li>提交保存</li>
      </ul>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/202309061444003.png"
          alt=""
        />
      </p>
      <h2 id="h2--">
        <a name="注：" class="reference-link" one-link-mark="yes"></a
        ><span class="header-link octicon octicon-link"></span>注：
      </h2>
      <ul>
        <li>
          <p>
            删除一级分类，需要先删除该一级下的所有二级分类；删除二级分类，需要先删除该二级下的所有三级分类；删除三级分类需要先删除该分类下的所有产品。
          </p>
        </li>
        <li>
          <p>
            目前系统只支持三级分类，只添加一级或者添加超过三级，都无法正常使用。
          </p>
        </li>
        <li>
          <p>分类图标建议上传180*180，不宜过大，否则造成系统加载速度过慢。</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
