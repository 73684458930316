<template>
  <div>
    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2--strong-oss-strong-">
        <a name="阿里云OSS创建及使用" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        ><strong>阿里云OSS创建及使用</strong>
      </h2>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915155015.png"
          alt=""
        />
      </p>
      <hr />
      <h3 id="h3-1-oss">
        <a name="1.阿里云后台打开 对象存储 OSS" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>1.阿里云后台打开
        对象存储 OSS
      </h3>
      <p>
        <img
          v-preview-image
          src="https://img.kancloud.cn/22/9a/229aeba08de1d7a477e3855127cc24f7_1892x740.png"
          alt=""
        />
      </p>
      <h3 id="h3-2-oss-">
        <a name="2.对象存储服务 OSS开通" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>2.对象存储服务
        OSS开通
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/1e2e35eeb7ad7a29a56a8d66b9941a83_1426x394.png"
          alt=""
        />
      </p>
      <h3 id="h3-3-accesskey-secretkey">
        <a name="3.获取accessKey和secretKey" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >3.获取accessKey和secretKey
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/0aba69386aba61839b0acd7eca12931a_1892x452.png"
          alt=""
        />
      </p>
      <h3 id="h3-4-bucket-crmeb-">
        <a
          name="4.新建Bucket (万千实训系统 存储空间名称)"
          class="reference-link"
        ></a
        ><span class="header-link octicon octicon-link"></span>4.新建Bucket
        (万千实训系统 存储空间名称)
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/e8264bbdc4211a6ab53a78607046142f_1888x596.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/5fe174f05de4ad93355c5f1c6c6e44db_1416x1948.png"
          alt=""
        />
      </p>
      <h3 id="h3-5-">
        <a name="5.创建好存储空间后添加对应的域名" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span
        >5.创建好存储空间后添加对应的域名
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/b2b48020cef421f041da36cf12638dea_1904x1902.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/c38ec6c169d75fc3be3e6c9e9d9a3d83_1932x798.png"
          alt=""
        />
      </p>
      <h3 id="h3-6-js-">
        <a name="6.配置JS上传" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>6.配置JS上传
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/c8fd29012171770cde6c43f3345788e0_791x476.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/8772ec88179901c45250085c96dc318a_1422x834.png"
          alt=""
        />
      </p>
      <h3 id="h3-7-">
        <a name="7.所属地域设置" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>7.所属地域设置
      </h3>
      <p>
        <img
          v-preview-image
          src="@/assets/downloads/20230915155036.png"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/screenshot_1595038495843.webp"
          alt=""
        /><br />
        <img
          v-preview-image
          src="@/assets/downloads/screenshot_1609316745142.webp"
          alt=""
        /><br />按照上图配置点击确定
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
