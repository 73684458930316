<template>
  <div>
    <div id="editormd">
      <video
        class="aspect-video"
        poster="@/assets/downloads/202309210927719.png"
        src="@/assets/downloads/202309210931877.mp4"
        controls
      ></video>

      <div class="right-body markdown-body editormd-html-preview">
        <h2 id="h2--strong-strong-">
          <a name="佣金提现步骤" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>佣金提现步骤</strong>
        </h2>
        <h3 id="h3--strong-strong-">
          <a
            name="第一步：平台后台设置佣金提现方式：线下手动转账 、自动到微信零钱"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>第一步</strong>：平台后台设置佣金提现方式：线下手动转账
          、自动到微信零钱
        </h3>
        <p>
          位置：平台后台&gt;分销&gt;分销配置<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121739780.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="第二步： 用户申请提现" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>第二步</strong>： 用户申请提现
        </h3>
        <p>
          位置：移动端&gt;个人中心&gt;分销推广&gt;立即提现<br />
          可选择银行卡、微信、支付宝三种方式，如果平台设置了自动到微信零钱，用户可直接选择微信方式。<br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309121740939.png"
            alt=""
          /><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309121740805.png"
            alt=""
          /><br />
          <strong>提现到银行卡</strong><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309121740982.png"
            alt=""
          /><br />
          <strong>提现到微信</strong><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309121741315.png"
            alt=""
          /><br />
          <strong>提现到支付宝</strong><br />
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309121741139.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a
            name="第三步：平台通过后台的申请记录，审核，线下人工转账 或佣金自动到零钱"
            class="reference-link"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>第三步</strong>：平台通过后台的申请记录，审核，线下人工转账
          或佣金自动到零钱
        </h3>
        <p>
          位置：平台后台&gt;财务&gt;提现管理<br />
          如果平台设置的是线下人工转账，审核通过后，需手动付款到用户相应的收款信息；<br />
          如果平台设置的佣金自动到零钱，审核通过后，佣金自动到用户微信零钱。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121741763.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="   佣金自动到零钱" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong> 佣金自动到零钱</strong>
        </h3>
        <p>
          <img
            v-preview-image
            class="w-1/3"
            src="@/assets/downloads/202309121741676.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309121741403.png"
            alt=""
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
