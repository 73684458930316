<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/76d6d4f77aaa2014bd1b62ba484121b3.mp4"
      controls
    ></video>

    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h3 id="h3--strong-strong-strong-strong-">
          <a name="开启拼团活动" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong><strong>开启拼团活动</strong></strong>
        </h3>
        <p>
          商户添加拼团活动商品，平台后台审核，活动发布，用户发起拼团活动，参团人数达到设定人数，即拼团成功，未达到拼团人数，拼团失败，系统自动退款。
        </p>
        <p><strong>位置：商户后台&gt;营销&gt;拼团</strong></p>
        <p>
          商户后台添加拼团商品，设置拼团活动规则，选择拼团商品规格，提交平台审核。<br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201753115.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201753464.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201753421.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201753758.png"
            alt=""
          /><br />
          <img
            v-preview-image
            src="@/assets/downloads/202309201753357.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name="审核/编辑拼团活动" class="reference-link"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>审核/编辑拼团活动</strong>
        </h3>
        <p><strong>位置：平台后台&gt;营销&gt;拼团</strong></p>
        <ol>
          <li>
            <p>
              平台后台审核拼团商品<br />
              <img
                v-if="0"
                v-preview-image
                src="@/assets/downloads/202309201753357.png"
                alt=""
              /><br />
              <img
                v-preview-image
                src="@/assets/downloads/202309201755890.png"
                alt=""
              />
            </p>
          </li>
          <li>
            <p>
              点击“编辑” - 设置拼团活动在移动端拼团列表优先展示顺序<br />优先展示顺序：星级&gt;排序&gt;销量<br />规则：<br />（1）
              5星&gt;4星&gt;3星&gt;2星&gt;1星<br />（2）
              排序是星级级别内的排序<br />
              <img
                v-preview-image
                src="@/assets/downloads/202309201755518.png"
                alt=""
              /><br />
              <img
                v-preview-image
                src="@/assets/downloads/202309201755525.png"
                alt=""
              /><img
                v-preview-image
                src="@/assets/downloads/202309201756633.png"
                alt=""
              />
            </p>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
