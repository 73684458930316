<template>
  <div>
    <video
      class="aspect-video"
      poster="@/assets/downloads/202309210927719.png"
      src="@/assets/downloads/bandicam 2023-09-25 14-35-57-364.mp4"
      controls
    ></video>

    <div id="editormd" class="markdown-body editormd-html-preview">
      <h2 id="h2-u6DFBu52A0u9884u552Eu534Fu8BAE">
        <a name="添加预售协议" class="reference-link"></a
        ><span class="header-link octicon octicon-link"></span>添加预售协议
      </h2>
      <p>
        <strong>位置：平台后台&gt;营销&gt;预售&gt;预售协议</strong><br />
        <img
          v-preview-image
          src="@/assets/downloads/20230915152042.png"
          alt=""
        />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
