
<template>
  <div>
    <video
          class="aspect-video"
          poster="@/assets/downloads/202309210927719.png"
          src="@/assets/downloads/202309210949558.mp4"
          controls
        ></video>
        

    <div
      id="editormd"
      class="markdown-body editormd-html-preview"
    >

      <h3 id="h3--"><a
          name="一、功能介绍"
          class="reference-link"
        ></a><span class="header-link octicon octicon-link"></span>一、功能介绍</h3>
      <p>订单发货： 此处订单发货主要介绍在商家管理后台订单管理模块中的订单发货，包括单个订单发货、导入批量订单发货、分单发货、虚拟发货、同城配送发货、订单核销。</p>
      <h3 id="h3--"><a
          name="二、操作流程"
          class="reference-link"
        ></a><span class="header-link octicon octicon-link"></span>二、操作流程</h3>
      <p>位置：商户后台&gt;订单&gt;订单管理<br>2.1 <strong>单个订单发货</strong><br> <img
          v-preview-image
          src="@/assets/downloads/202309140914489.png"
          alt=""
        ><br> <img
          v-preview-image
          src="@/assets/downloads/202309140914582.png"
          alt=""
        ><br> <img
          v-preview-image
          src="@/assets/downloads/202309140914262.png"
          alt=""
        ></p>
      <p>2.2 <strong>批量订单发货（导入批量发货）</strong><br>第一步：请先导出待发货订单并下载<br> <img
          v-preview-image
          src="@/assets/downloads/202309140915477.png"
          alt=""
        ><br>
        <!--  <img
          v-preview-image
          src="@/assets/downloads/202309140915932.png"
          alt=""
        > -->
        <br>第二步：在导出的发货单表格中填写物流信息<br>物流编码信息可提前下载<br> <img
          v-preview-image
          src="@/assets/downloads/202309140915932.png"
          alt=""
        ><br>第三步：导入发货单<br> <img
          v-preview-image
          src="@/assets/downloads/202309140918748.png"
          alt=""
        ><br> <img
          v-preview-image
          src="@/assets/downloads/202309140918116.png"
          alt=""
        ><br>第四步：查看发货记录<br> <img
          v-preview-image
          src="@/assets/downloads/202309140918012.png"
          alt=""
        ></p>
      <!-- <p>
        <strong>2.3 电子面单发货（单个或批量）</strong><br><strong>说明</strong>：电子面单功能需先开启, 具体设置详情请点击：<a href="https://doc.crmeb.com/mer/mer2/4400">https://doc.crmeb.com/mer/mer2/4400</a><br>按如上操作设置好后，在订单列表选择订单-点击发送货或选择订单-点击批量发货<br> <img
          v-preview-image
          src="/uploads/mer/mer2/20220524/6f5ef21ce54fd0217fd253bfeb9cc2b6.png"
          alt=""
        ><br> <img
          v-preview-image
          src="/uploads/mer/mer2/20220524/f72e139ce9650643d020530b617f6979.png"
          alt=""
        ></p>
      <p> -->
        <strong>2.3 分单发货</strong></p>
      <p><strong>位置</strong>：商户后台&gt; 订单&gt; 订单管理&gt; 发送货<br><strong>说明</strong>：<br> 分单发货是指将一个订单中的多个商品发多个快递。<br> （1）订单中有多件商品，且需要在不同的地方发货时，可以选择分单发货；<br> （2）订单中的多个不同商品可单个发货或组合发货；<br> （3）分单发货后，分出的订单会生成全新的订单，订单号在原订单号基础上会加“-1”、“-2”……；</p>
      <p> <img
          v-preview-image
          src="@/assets/downloads/202309140920420.png"
          alt=""
        ></p>
      <!-- <p><strong> 2.4同城配送发货</strong></p>
      <p>  -->
        <!-- <strong>说明</strong>：同城配送功能需平台后台和商户后台分别配置，具体详见：
         <a href="https://doc.crmeb.com/mer/mer2/4537">https://doc.crmeb.com/mer/mer2/4537</a> -->
        
        <!-- </p> -->
      <!-- <p> <img
          v-preview-image
          src="@/assets/downloads/202309140921372.png"
          alt=""
        ></p>
       -->
        <!-- <strong> 2.5 虚拟订单发货</strong><br> 位置：商户后台&gt;订单&gt;订单管理&gt;虚拟商品订单<br> <img
          v-preview-image
          src="/uploads/mer/mer2/20220524/d2148c25bf87a752dc2611698ca2bbe2.png"
          alt=""
        ><br> <img
          v-preview-image
          src="/uploads/mer/mer2/20220524/5f68e4598cd7f1e348ed2edf455a3398.png"
          alt=""
        ></p>
      <p>
        <strong> -->
          <p>
            <strong>2.4 订单核销</strong><br>位置：商户后台&gt;订单&gt;订单管理&gt;订单核销<br>
            <!-- 说明：v2.1版本开始可多件商品多次核销，每核销1次独立拆分了订单。 -->
          </p>
      <p> <img
          v-preview-image
          src="@/assets/downloads/202309140922818.png"
          alt=""
        ><br> <img
          v-preview-image
          src="@/assets/downloads/202309140922681.png"
          alt=""
        ></p>
      <p> <img
          v-preview-image
          src="@/assets/downloads/202309201522865.png"
          alt=""
        ></p>
    </div>
  </div>
</template>
                    
              <script>
export default {};
</script>
              <style lang="scss" scoped></style>
                    