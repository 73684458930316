var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('video',{staticClass:"aspect-video",attrs:{"poster":require("@/assets/downloads/202309210927719.png"),"src":require("@/assets/downloads/bandicam202023-09-252015-30-05-141.mp4"),"controls":""}}),_c('div',{attrs:{"id":"editormd"}},[_c('div',{staticClass:"right-body markdown-body editormd-html-preview"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('p',[_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/20230915145359.png"),"alt":""}})]),_vm._m(5),_c('p',[_vm._v(" 位置：商户后台>商品>商品列表"),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/202309251538091.png"),"alt":""}})]),_vm._m(6),_c('p',[_vm._v(" 位置：商户后台>商品>编辑"),_c('br'),_c('img',{directives:[{name:"preview-image",rawName:"v-preview-image"}],attrs:{"src":require("@/assets/downloads/20230915145242.png"),"alt":""}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"一、功能介绍"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("一、功能介绍 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 1.付费会员是指用户通过付费购买到的商城会员资格，付费会员权益都一样，只是会员期限不一样付费金额不一样。"),_c('br'),_vm._v(" 2.万千实训系统多商户系统付费会员功能核心：平台设置付费会员类型、权益，商户可选择是否开启付费会员并设置商"),_c('br'),_vm._v(" 品的付费会员价，商户承担付费会员价的成本，平台承担付费会员优惠券的成本。"),_c('br'),_vm._v(" 3.会员类型根据业务可设置，包括免费试用（后台可设置天数）、周卡、月卡、季卡、年卡、永久。"),_c('br'),_vm._v(" 4.会员权益包括会员专属价、签到返利、消费返利、会员专属客服、经验翻倍、会员优惠券。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"h3--"}},[_c('a',{staticClass:"reference-link",attrs:{"name":"二、操作流程"}}),_c('span',{staticClass:"header-link octicon octicon-link"}),_vm._v("二、操作流程 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第一步：商户开启付费会员")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 位置：商户后台>设置>付费会员设置"),_c('br'),_vm._v("说明："),_c('br'),_vm._v(" （1）商户可通过开启或关闭，自主决定是否以付费会员价为平台付费会员提供消费商品；"),_c('br'),_vm._v(" （2）商户设置付费会员价折扣，承担付费会员价成本；"),_c('br'),_vm._v(" （3）商户可设置付费会员价是否与店铺优惠券叠加，并承担相关成本；"),_c('br'),_vm._v(" （4）商户后台在此处设置付费会员折扣价后，所有商品默认不设置会员价；"),_c('br'),_vm._v(" （5）部分商品需参与或不参与，可通过批量设置付费会员价进行设置；"),_c('br'),_vm._v(" （6）个别商品需参与或不参与，可在商品详情编辑 自定义会员价；自定义设置后，改商户后台总折扣不受影响。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("第二步：批量设置付费会员价")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v(" 第三步：单独设置付费会员价")])])
}]

export { render, staticRenderFns }