<template>
  <div>
    <div id="editormd">
      <div class="right-body markdown-body editormd-html-preview">
        <h1 class="paragraph-center" id="h1--strong--strong-">
          <a name="万千电商实训" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>万千电商实训</strong>
        </h1>
        <p>
          <img
            v-preview-image
            src="@/assets/downloads/202309210907649.png"
            alt=""
          />
        </p>
        <h3 id="h3--strong-strong-">
          <a name=" 项目介绍 " class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong> 项目介绍 </strong>
        </h3>
        <hr />
        <p>
          这个基于ThinkPHP 6、Swoole
          4和UniApp开发的新零售多商户商城生产实训系统是一个集客户关系管理和营销电商系统的系统。
        </p>
        <p>
          新零售多商户商城生产实训系统能够帮助企业在微信公众号、小程序、WAP、PC等平台上实现会员管理、数据分析和精准营销，从而提升电子商务管理的效率。该系统可以满足企业在新零售、批发、分销、预售、O2O、商铺入驻等各种业务需求。
        </p>
        <p>
          新零售多商户商城生产实训系统的优势包括快速积累客户和会员数据、智能转化客户、有效提高销售和会员维护。通过系统提供的功能和分析工具，企业可以更好地了解客户和会员的需求，进行精准营销和销售促进，从而提高业务的收益和效益。
        </p>
        <hr />
        <h3 id="h3--strong-strong-">
          <a name=" 系统亮点" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong> 系统亮点</strong>
        </h3>
        <h5 id="h5--strong-1-b2b2c-strong-">
          <a
            name="1、B2B2C商城系统自营+招商多模式运营"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>1、B2B2C商城系统自营+招商多模式运营</strong>
        </h5>
        <p>多商户的核心是B2B2C，支持企业自营模式，联营模式及商家入驻模式等；</p>
        <p>
          商家可整合行业资源，联系商户入驻商城，实现商城产品和服务类目的多样性，打造综合购物商圈，轻松拥有专属自己的“京东商城”
        </p>
        <h5 id="h5--strong-2-strong-">
          <a
            name="2、打通分销系统，构建移动社交电商"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>2、打通分销系统，构建移动社交电商</strong>
        </h5>
        <p>
          用户购买礼包成为推广员后，可通过微信邀请好友下单赚取佣金，降低人工营销宣传成本，实现人人分享赚钱的乐趣，促进商城社交化推广
        </p>
        <h5 id="h5--strong-3-strong-">
          <a
            name="3、多种盈利模式"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>3、多种盈利模式</strong>
        </h5>
        <p>
          B2B2C商城平台手续费、自营收入、资金沉淀、广告资源等多种平台盈利模式
        </p>
        <h5 id="h5--strong-4-ui-strong-">
          <a
            name="4、界面美观的商城UI"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>4、界面美观的商城UI</strong>
        </h5>
        <p>
          优秀的UI设计让商城系统变得有个性有品味，舒适、简单的体验感提高用户对商城平台的认可
        </p>
        <h5 id="h5--strong-5-strong-">
          <a
            name="5、强大稳定的后台管理系统"
            class="reference-link"
            one-link-mark="yes"
          ></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>5、强大稳定的后台管理系统</strong>
        </h5>
        <p>
          后台采用最新的TP框架，系统高效稳定；后台的功能操作简单快捷，企业及个人运营可快速上手使用
        </p>
        <hr />
        <h3 id="h3-u6280u672Fu7279u70B9">
          <a name="技术特点" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>技术特点
        </h3>
        <hr />
        <ul>
          <li>thinkphp6 + swoole4</li>
          <li>elementUI</li>
          <li>表单生成、</li>
          <li>长链接、</li>
          <li>异步任务、</li>
          <li>任务队列、</li>
          <li>定时任务、</li>
          <li>前后端分离、</li>
          <li>uniapp一套代码多端适配</li>
        </ul>
        <hr />
        <h3 id="h3-u529Fu80FDu4ECBu7ECD">
          <a name="功能介绍" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>功能介绍
        </h3>
        <hr />
        <p><strong>产品功能：</strong></p>
        <p>1、商户管理</p>
        <p>
          总后台可添加及管理商户，设置相关信息，设置商户服务费、商品是否审核等
        </p>
        <p>2、商品管理</p>
        <p>商品单规格、多规格管理，品牌管理、商品分类管理、商品评价</p>
        <p>3、订单管理</p>
        <p>
          能够完成用户的订单管理(发货、订单详情、修改订单、订单备注、订单记录、订单退款)
          、售后服务 (评论的回复与删除)
        </p>
        <p>4、用户管理</p>
        <p>
          对公众号、小程序、H5的会员进行管理，可通过筛选给用户发送优惠券、消息通知等
        </p>
        <p>5、内容管理</p>
        <p>文章管理、用户反馈、素材管理</p>
        <p>6、营销管理</p>
        <p>优惠券管理，店铺券和商品券，满足商家运营模式</p>
        <p>7、分销管理</p>
        <p>购买礼包开通分销推广员，分销员管理、分销礼包管理、分销配置</p>
        <p>8、财务管理</p>
        <p>提现管理、充值管理、商户对账</p>
        <p>9、应用管理</p>
        <p>公众号配置、自动回复、图文管理，模板消息、小程序订阅消息</p>
        <p>10、设置</p>
        <p>
          可对商城的基础性功能进行设置，如移动端界面、支付、文件上传等；可设置充值方案、运费模板等；可对商城的后台菜单、管理员身份及权限进行设置
        </p>
        <p>11、维护</p>
        <p>可灵活设置组合数据，配置商城数据；可随时对商城数据库进行备份</p>
        <p>
          12.权限管理<br />总后台权限独立分配，商户后台权限独立分配，灵活应用，权限想怎么配就怎么配
        </p>
        <p>
          13.系统组件开源项目<br />form-builder<br />tp5
          PHP表单生成器，快速生成现代化的form表单。包含复选框、单选框、输入框、下拉选择框等元素以及,省市区三级联动,时间选择,日期选择,颜色选择,文件/图片上传等功能。
          form-builder :
          <a href="https://github.com/xaboy/form-builder" one-link-mark="yes"
            >https://github.com/xaboy/form-builder</a
          >
        </p>
        <hr />
        <!-- <h3 id="h3-u6F14u793Au5730u5740">
          <a name="演示地址" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>演示地址
        </h3>
        <hr />
        <h3 id="h3--strong-strong-">
          <a name="前端演示：" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span
          ><strong>前端演示</strong>：
        </h3>
        <p>
           <img
          v-preview-image
            src="/uploads/mer/images/uploads/mer//uploads/mer/images/扫码关注.jpg"
            alt=""
          />
        </p>
        <p>
          <strong>多商户演示总管理后台</strong> ：<br /><a
            href="http://mer..net/admin/"
            one-link-mark="yes"
            >http://mer..net/admin/</a
          ><br />账号：demo<br />密码：.com
        </p> -->
        <!-- <hr /> -->
        <!-- <p>
          <strong>子商户独立后台</strong>：<br /><a
            href="http://mer..net/merchant/"
            one-link-mark="yes"
            >http://mer..net/merchant/</a
          ><br />账号：demo<br />密码：.net
        </p>
        <p>
          有更多疑问？您也可以咨询我们的售前客服，微信扫码添加客服好友： <img
          v-preview-image
            src="/uploads/mer/images/uploads/mer//uploads/mer/images/微信图片_20201215171007.png"
            alt=""
          />
        </p>
        <hr /> -->
        <!-- <h2 id="h2-u53C2u4E0Eu5F00u53D1u4EBAu5458">
          <a name="参与开发人员" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>参与开发人员
        </h2>
        <p>
          产品：Amy、木子刀客<br />设计：LXT、 xy-yyds<br />技术：Xaboy、Qinii、郭小萌、张三丰<br />测试：绵绵羊、夏天
        </p> -->
        <h3 id="h3-u7279u522Bu9E23u8C22">
          <a name="特别鸣谢" class="reference-link" one-link-mark="yes"></a
          ><span class="header-link octicon octicon-link"></span>特别鸣谢
        </h3>
        <hr />
        <p>感谢以下的项目,排名不分先后</p>
        <p>
          ThinkPHP：<a href="http://www.thinkphp.cn" one-link-mark="yes"
            >http://www.thinkphp.cn</a
          >
        </p>
        <p>
          Bootstrap：<a href="http://getbootstrap.com" one-link-mark="yes"
            >http://getbootstrap.com</a
          >
        </p>
        <p>
          jQuery：<a href="http://jquery.com" one-link-mark="yes"
            >http://jquery.com</a
          >
        </p>
        <p>
          iView：<a href="https://www.iviewui.com" one-link-mark="yes"
            >https://www.iviewui.com</a
          >
        </p>
        <p>
          Vue：<a href="https://cn.vuejs.org/" one-link-mark="yes"
            >https://cn.vuejs.org/</a
          >
        </p>
        <p>
          font-awesome：
          <a href="https://fontawesome.com/?from=io" one-link-mark="yes"
            >https://fontawesome.com/?from=io</a
          >
        </p>
        <p>
          requirejs：
          <a href="http://requirejs.org/" one-link-mark="yes"
            >http://requirejs.org/</a
          >
        </p>
        <p>
          umeditor：<a
            href="http://ueditor.baidu.com/website/umeditor.html"
            one-link-mark="yes"
            >http://ueditor.baidu.com/website/umeditor.html</a
          >
        </p>
        <hr />
        <hr />
        <p>版权信息</p>
        <p>版权所有Copyright © 2020-2023 by WQ</p>
        <p>All rights reserved。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
